import styled from 'styled-components';

export const EntOwnersSelect = styled.div`
    min-width: 200px;
    background-color: #F5FBFF;
    border-radius: 6px;
    margin-right: 1rem;
    position: relative;
`

export const AddOwner = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 1rem;
    margin-right: 1rem;
    cursor: pointer;

    & span {
        font-size: 0.7rem;
        color: ${({theme}) => theme.colors.primary};
    }
`

export const SearchOwner = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 1rem;
    margin-right: 1rem;
    cursor: pointer;

`

export const SearchInput = styled.input`
    flex: 1;
    border: none;
    outline: none;
    background-color: #F5FBFF;
    font-size: 0.7rem;

    &:focus {
        border: none;
        outline: none;
    }

    &::placeholder {
        color: ${({theme}) => theme.colors.primary};
    }
`

export const SelectOwnersList = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    position: absolute;
    width: 100%;
    z-index: 100;
    padding: 0 0.5rem 0.5rem 0.5rem;
    background-color: #F5FBFF;
`

export const SelectOwnersHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    color: ${({theme}) => theme.colors.secondary};
`

export const SelectOwnersItems = styled.ul`
    width: 100%;
    height: 100px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
`

export const SelectOwnersItem = styled.li`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 0.5rem;
    cursor: pointer;

    &:hover {
        background-color: ${({theme}) => theme.colors.btnHover};
    }
`

export const ItemLabel = styled.span`
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: black;
`

export const ItemCode = styled.span`
    color: ${({theme}) => theme.colors.primary};
`