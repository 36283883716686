import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { ACHContainer, ACHIcon, ACHValue } from './Attachment.styled';

function Attachment({attachment, deleteAttachement}) {
    return (
        <ACHContainer>
            <ACHValue> {attachment.name} </ACHValue>
            <ACHIcon>
                <CloseIcon 
                    style={{fontSize: '0.9rem'}}
                    onClick={() => deleteAttachement(attachment.name)} 
                />
            </ACHIcon>
        </ACHContainer>
    )
}

export default Attachment
