import React from 'react'
import InvoiceDetail from '../InvoiceDetail/InvoiceDetail'
import { ITBody, ITContainer } from './InvoiceToken.styled'

function InvoiceToken() {
  return (
    <ITContainer>
        <ITBody>
            <InvoiceDetail />
        </ITBody>
    </ITContainer>
  )
}

export default InvoiceToken