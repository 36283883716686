import React, { useCallback, useEffect, useState } from 'react'

import Axios from '../../axios-proas';
import { connect } from 'react-redux';
import {Spinner} from 'react-bootstrap';
import * as actions from '../../store/actions/index';
import { useTranslation } from 'react-i18next';
import TablePagination from '../../components/UI/TablePagination/TablePagination';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { checkValidity, getNotyfObject } from '../../shared/utility';
import { useLocation } from 'react-router-dom';
import {EXContainer, EXBody, EXListWrapper,PaginationDiv}from './Extraction.Styled'
import { NoContent, SpinnerContainer, Table, TBody,TableAction, TableActionLink  } from '../../styles/Table.styled';
import { BtnIcon, BtnOutlineAction, BtnOutlineLink, Header, HeaderActions, HeaderInfos, HeaderLocation, HeaderTitle, SearchInput, SearchInputContainer } from '../../styles/Common';
import ExtractionItem from '../../components/Extraction/ExtractionItem/ExtractionItem';
import DensiteAndColonnes from "../../components/UI/TablePagination/DensiteAndColonnes";
import { useTheme } from 'styled-components';
import ReplayIcon from '@mui/icons-material/Replay';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

function Extraction(props) {
    let {
        loading, 
        extraxtionsData, 
        count, 
        currentPage, 
        pageSize,
        setExtractionPageSize,
        setExtractionPage, 
        getExtractionPage, 
        setExtractionFilters, 
        filtersQuery, 
        filtering, 
        sortQuery, 
        reverse, 
        setSortQuery, 
        setReverse, 
        updateShowFilters, 
        showFilters,
        selectedExtractions,
        selectExtractionRow,
        unselectExtractionRow,
        selectedAllRows,
        selectAllExtractionRows,
        emptySelectedExtractions
    } = props;
    const {t} = useTranslation();
    let notyf = getNotyfObject();
    let location = useLocation();
    const theme =  useTheme();

    const columns = [
        {title: 'Date d’extraction', field: 'creationDate'},
        {title: 'Type de factures ', field: 'invoiceType'},
        {title: 'Date de facture', field: 'issuingDate'},
        {title: 'Date de constitution', field: 'invoiceCreationDate'},
        {title: 'Montant HT', field: 'exclusiveTaxAmount'},
        {title: 'Montant TTC', field: 'inclusiveTaxAmount'},
        {title: 'Statut', field: 'status', width: "100px"},
        {title: 'Fichiers', field: 'invoicesCount', width: "100px"},
        //{title: 'Litige', field: 'litigation'} 
    ]

    const deleteExtraction = (item) => {
        console.log(item);
        Axios.delete("extraction/" + item + "/deleteExtractionByUid")
          .then((response) => {
            getExtractionPage(1, {}, sortQuery, reverse, pageSize);
          })
          .catch((response) => {
            notyf.error("Une erreur s'est produite");
          });
      };

    useEffect(() => {
        updateShowFilters(false)
        setExtractionFilters({})
        setExtractionPage(1)
        getExtractionPage(1, {}, sortQuery, reverse, pageSize);
    }, [getExtractionPage, pageSize])

    const handleShowFilters = () => {
        updateShowFilters(!showFilters)
    }

    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0){
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
        return day + '/' + month + '/' + newDate.getFullYear()
        }else return null;    
    }, [])

    const handleChangePage = useCallback((newPage) => {
        //e.preventDefault();
        setExtractionPage(newPage);
        getExtractionPage(newPage, filtersQuery, sortQuery, reverse, pageSize);
    }, [filtersQuery, reverse, sortQuery, pageSize]);

    const handleSort = useCallback((sort,newReverse) => {
        getExtractionPage(currentPage, filtersQuery, sort, newReverse, pageSize)
    }, [currentPage,filtersQuery, sortQuery, reverse, pageSize])

    const handleApplyFiltering = useCallback((filters) => {
        getExtractionPage(1,filters, sortQuery, reverse, pageSize);
    }, [currentPage, filtersQuery, reverse, sortQuery, pageSize]);

    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...filtersQuery,
            [key]: e.target.value
        }
        setExtractionFilters(newFilters);
        handleApplyFiltering(newFilters);
    }


    const handleReset = useCallback(() =>{
        let filtersTemp = {}
        // if(entityUid)
        //     filtersTemp = {
        //         entityUid
        //     }
        // else
        //     filtersTemp = {
        //         entityFilter: userType,
        //     }
        setSortQuery("creationDate")
        setReverse({creationDate: false})
        setExtractionFilters(filtersTemp);
        setExtractionPage(1);
        emptySelectedExtractions()
        getExtractionPage(1, filtersTemp, "creationDate", {creationDate: false}, pageSize);
    }, [])

    let tBody = null;
    let tSpinner = loading  ? (
        <SpinnerContainer>
                <Spinner />
        </SpinnerContainer>
       
        ) : 
        <NoContent>
            <span style={{fontSize:"1rem",color:"#888888",padding:"0px 0px 0px 48px"}}>Aucune demande d'extraction à afficher</span>;
        </NoContent>

    if(!loading && extraxtionsData?.length > 0){
        tSpinner = null;
        tBody = ( 
            <EXListWrapper>
                {
                    extraxtionsData?.length > 0 ? extraxtionsData.map((data, index) => (
                        <ExtractionItem
                            deleteExtraction={deleteExtraction}
                            columns={columns} 
                            data={data} 
                            formatDate={formatDate} 
                            selectedExtractions={selectedExtractions}
                            selectExtractionRow={selectExtractionRow}
                            unselectExtractionRow={unselectExtractionRow}
                        />
                    )) : null
                }
            </EXListWrapper>
        )
    }


    return (
        <EXContainer>
            <TitleHelmet
                title={
                "Streamline for Invoices | Extraction" 
                }
            />
            <Header>
                <HeaderInfos>
                    <HeaderLocation>{t("menu:extraction", "Extraction")}</HeaderLocation>
                </HeaderInfos>
                <HeaderActions>
                    <SearchInputContainer>
                        <SearchInput
                            type="text"
                            placeholder={t("tableAction:Rechercher")}
                            autoComplete={"off"}
                            name="findByTitelOrType"
                            value={filtersQuery?.findByTitelOrType || ''} 
                            onChange={(e) => inputChangeHandler(e)}
                            
                        />
                         <CloseOutlinedIcon onClick={() => handleReset()}/> 
                    </SearchInputContainer>
                    {/* <TableAction onClick={() => handleReset()}>
                        <span>{t("global:resetFilters")}</span>
                   
                    </TableAction> */}
                    <TableActionLink
                        to="/extractionForm"
                    >
                        <span>{t('tableAction:newExtraction',"Nouvelle extraction" )}</span>
                        {/* <BtnIcon>
                            <AddIcon />
                        </BtnIcon> */}
                    </TableActionLink>
                </HeaderActions>
            </Header>       
            <EXBody>
            {extraxtionsData?.length > 0 &&
                <DensiteAndColonnes 
                    currentPage={currentPage} 
                    pageChange={handleChangePage} 
                    totalElement={count} 
                    perPage={pageSize}
                    perPageChange={setExtractionPageSize}
                />
            }
                {tBody}
                {tSpinner}
                {extraxtionsData?.length > 0 &&
                    <TablePagination 
                        currentPage={currentPage} 
                        pageChange={handleChangePage} 
                        totalElement={count} 
                        perPage={pageSize}
                        perPageChange={setExtractionPageSize}
                    />
                }
            </EXBody>
            </EXContainer>
    )
}
const mapStateToProps = (state) => ({
    loading: state.extraction.loading,
    extraxtionsData : state.extraction.data,
    count: state.extraction.count,
    currentPage: state.extraction.currentPage,
    pageSize: state.extraction.pageSize,
    filtersQuery: state.extraction.filtersQuery,
    filtering: state.extraction.filtering,
    sortQuery: state.extraction.sortQuery,
    reverse: state.extraction.reverse,
    selectedExtractions: state.extraction.selectedExtractions,
    showFilters: state.extraction.showFilters,
    selectedAllRows: state.extraction.selectedAllRows
})
const mapDispatchToProps = dispatch => ({
    getExtractionPage: (page, filters, sort, reverse, pageSize) => dispatch(actions.extractionGetPage(page, filters, sort, reverse, pageSize)),
    setExtractionPage: (page) => dispatch(actions.extractionSetPage(page)),
    setExtractionPageSize: (pageSize) => dispatch(actions.extractionSetPageSize(pageSize)),
    setExtractionFilters: (filters) => dispatch(actions.extractionSetFilterQuery(filters)),
    setSortQuery: (sortQ) => dispatch(actions.extractionSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.extractionSetReverseSort(reverseS)),
    updateShowFilters: (show) => dispatch(actions.extractionUpdateShowFilters(show)),
    selectAllExtractionRows: (selectAll) => dispatch(actions.setSelectedAllExtractionRows(selectAll)),
    selectExtractionRow: (uid) => dispatch(actions.selectExtraction(uid)),
    unselectExtractionRow: (uid) => dispatch(actions.unselectExtraction(uid)),
    emptySelectedExtractions : () => dispatch(actions.emptySelectedextractions())
})
export default connect(mapStateToProps, mapDispatchToProps) (Extraction)
