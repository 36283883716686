import React, { useEffect, useMemo, useState } from 'react'
import Axios from '../../../axios-proas';
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { convertFileToBase64, getNotyfObject } from '../../../shared/utility';
import { BtnAction, BtnOutlineAction, FormGroup, FormInput, FormLabel, FormRadio, FormRadioWrapper, FormTextarea, Header, HeaderActions, HeaderInfos, HeaderLocation, RadioButtonLabel } from '../../../styles/Common'
import FileUploaderBtn from '../../UI/FileUploaderBtn/FileUploaderBtn';
import EmailEditor from '../EmailEditor/EmailEditor';
import { ETBody, ETContainer, ETContent, ETImgContainer, ETRadiosContainer, ETSection, ETSectionForm, ETSectionTitle, FileFormWrapper, FileName, FormInputError, FormTextEditorWrapper } from './EmailTemplate.styled'
import { SpinnerContainer } from '../../../styles/Table.styled';

function EmailTemplate() {
    const { t } = useTranslation();
    const theme = useTheme();
    const notyf = getNotyfObject();
    const navigate = useNavigate();
    const templateInit = useMemo(() => ({
        headerImage: "",
        headerImagePosition: "left",
        headerImageWidth: "auto",
        headerImageHeight: "auto",
        footerText: "",
        footerImage: "",
        footerImageHeight: "auto",
        footerImageWidth: "auto",
        footerImagePosition: "left"
    }), []);
    const [errors, setErrors] = useState({
        headerImage: null,
        headerImagePosition: null,
        headerImageWidth: null,
        headerImageHeight: null,
        footerText: null,
        footerImage: null,
        footerImageHeight: null,
        footerImageWidth: null,
        footerImagePosition: null
    });
    const [isLoading, setIsLoading] = useState(false);
    const [headerImage, setHeaderImage] = useState('');
    const [headerImageName, setHeaderImageName] = useState('');
    const [footerImage, setFooterImage] = useState('');
    const [footerImageName, setFooterImageName] = useState('');
    const [parametres, setParametres] = useState(templateInit);


    function _base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }
    const getTemplate = () => {
        Axios.get('emailtemplate//template')
            .then(res => {
                const template = res?.data?.template ? {...res.data.template} : {...templateInit};
                let headerImageName = template?.headerImage ? template.headerImage.split('/').pop() : null;
                let footerImageName = template?.footerImage ? template.footerImage.split('/').pop() : null;
                setParametres({...template,headerImage: null,footerImage: null});
                setHeaderImage(template.headerImage);
                setHeaderImageName(headerImageName);
                setFooterImage(template.footerImage);
                setFooterImageName(footerImageName);
               // _base64ToArrayBuffer()
            })
            .catch(err => {
                notyf.error(t('error.getTemplate', 'Erreur lors de la récupération du template'));
            });
    }

    useEffect(() => {
        getTemplate();
    }, []);

    const headerImageChange = (e) => {
        let errorsTemp = {...errors};
        let file = e.target.files[0];
        var isImagen = /^image\//;
        let headerImageTemp = '';
        let headerImageUrlTemp = '';
        let headerImageNameTemp = '';

        // if it's not an image continu
        if (!isImagen.test(file.type)) {
            errorsTemp.headerImage = t('error.headerImage', 'Le fichier n\'est pas une image');
        } else {
            // const imgB64 = await convertFileToBase64(file)
            errorsTemp.headerImage = null;
            headerImageTemp = file;
            headerImageUrlTemp = URL.createObjectURL(file);
            headerImageNameTemp = file.name;
        }
        setErrors(errorsTemp);
        setParametres({ ...parametres, headerImage: headerImageTemp });
        setHeaderImage(headerImageUrlTemp);
        setHeaderImageName(headerImageNameTemp);
    }


    const footerImageChange = (e) => {
        let errorsTemp = {...errors};
        let file = e.target.files[0];
        var isImagen = /^image\//;
        let footerImageTemp = '';
        let footerImageUrlTemp = '';
        let footerImageNameTemp = '';

        // if it's not an image continu
        if (!isImagen.test(file.type)) {
            errorsTemp.footerImage = t('error.footerImage', 'Le fichier n\'est pas une image');
        } else {
            // const imgB64 = await convertFileToBase64(file)
            errorsTemp.footerImage = null;
            footerImageTemp = file;
            footerImageUrlTemp = URL.createObjectURL(file);
            footerImageNameTemp = file.name;
        }
        setErrors(errorsTemp);
        setParametres({ ...parametres, footerImage: footerImageTemp});
        setFooterImage(footerImageUrlTemp);
        setFooterImageName(footerImageNameTemp);
    }

    const headerImageWidthChange = (val) => {
        let value = parseInt(val);
        let errorsTemp = {...errors};
        if(value < 10 || value > 400) {
            errorsTemp.headerImageWidth = t('error.headerImageWidth', 'La largeur de l\'image doit être comprise entre 10 et 400');
        } else {
            errorsTemp.headerImageWidth = null;
        }
        setParametres({ ...parametres, headerImageWidth: value });
        setErrors(errorsTemp);
    }

    const headerImageHeightChange = (val) => {
        let value = parseInt(val);
        let errorsTemp = {...errors};
        if(value < 10 || value > 200) {
            errorsTemp.headerImageHeight = t('error.headerImageHeight', 'La hauteur de l\'image doit être comprise entre 10 et 200');
        } else {
            errorsTemp.headerImageHeight = null;
        }
        setParametres({ ...parametres, headerImageHeight: value });
        setErrors(errorsTemp);
    }

    const footerImageWidthChange = (val) => {
        let value = parseInt(val);
        let errorsTemp = {...errors};
        if(value < 10 || value > 400) {
            errorsTemp.footerImageWidth = t('error.footerImageWidth', 'La largeur de l\'image doit être comprise entre 10 et 400');
        } else {
            errorsTemp.footerImageWidth = null;
        }
        setParametres({ ...parametres, footerImageWidth: value });
        setErrors(errorsTemp);
    }

    const footerImageHeightChange = (val) => {
        let value = parseInt(val);
        let errorsTemp = {...errors};
        if(value < 10 || value > 200) {
            errorsTemp.footerImageHeight = t('error.footerImageHeight', 'La hauteur de l\'image doit être comprise entre 10 et 200');
        } else {
            errorsTemp.footerImageHeight = null;
        }
        setParametres({ ...parametres, footerImageHeight: value });
        setErrors(errorsTemp);
    }

    const parametersChangeHandler = (e) => {
        const { name, value } = e.target;
    
        setParametres((prevState) => ({
          ...prevState,
          [name]: value,
        }));
    };

    const footerTextChangeHandler = (content) => {
        setParametres((prevState) => ({
            ...prevState,
            footerText: content
        }));
    };

    const isSaveDisabled = () => {
        for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                if (errors[key] !== null) {
                    return true;
                }
            }
        }
        return false;
    }


    const saveEmailTemplate = () => {
        setIsLoading(true);
        const formData = new FormData();
        for(let key in parametres) {
            formData.append(key, parametres[key]);
        }
        for(var pair of formData.entries()) {
            console.log(pair[0]+ ', '+ pair[1]);
         }
        Axios.post('/emailtemplate//template', formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then(res => {
                setIsLoading(false);
                notyf.success(t('success.saveEmailTemplate', 'Email template sauvegardé avec succès'));
            })
            .catch(err => {
                setIsLoading(false);
                notyf.error(t('error.saveEmailTemplate', 'Erreur lors de la sauvegarde de l\'email template'));
            });
    };


  return (
    <ETContainer>
        <Header>
          <HeaderInfos>
            <HeaderLocation>
              {t("menu:emailsTemplate", "Template")}
            </HeaderLocation>
          </HeaderInfos>
          <HeaderActions>
            <BtnOutlineAction onClick={() => navigate(-1)} >
              <span>{t("btn:Retour")}</span>
            </BtnOutlineAction>
            {
                isLoading ? (
                    <SpinnerContainer>
                        <Spinner />
                    </SpinnerContainer>
                ) : (
                <BtnAction 
                    disabled={isSaveDisabled()}
                    onClick={() => saveEmailTemplate()} 
                    >
                    <span>{t("btn:Save")}</span>
                </BtnAction>
                )
            }
          </HeaderActions>
        </Header>
        <ETBody>
            {errors && errors.length > 0 ? (
                <div
                    className="alert alert-danger"
                    style={{ fontSize: "0.9rem" }}
                    role="alert"
                >
                    <ul>
                    {errors.map((error) => (
                        <li key={error}>{error}</li>
                    ))}
                    </ul>
                </div>
            ) : null}
            <ETContent>
                <ETSection>
                    <ETSectionTitle>{t("template:Entête")}</ETSectionTitle>
                    <ETSectionForm>
                        <FormGroup>
                            <FormLabel htmlFor="headerImage">{t("template:Image")} </FormLabel>
                            <FileFormWrapper>
                                <FileName title={headerImageName ||  ""} >{headerImageName ||  ""}</FileName>
                                <FileUploaderBtn
                                    btnLabel={t("template:ChoisirUnFichier")}
                                    handleChange={headerImageChange}
                                    name="headerImage"
                                />
                            </FileFormWrapper>
                        </FormGroup>
                        {
                            errors?.headerImage && (
                                <FormInputError>{errors.headerImage}</FormInputError>
                            )
                        }
                        {
                            headerImage && (
                                <ETImgContainer>
                                    <img src={headerImage} alt="headerImage" />
                                </ETImgContainer>
                            )
                        }
                        <FormGroup> 
                            <FormLabel htmlFor="headerImagePosition">{t("template:Position")}</FormLabel>
                            <ETRadiosContainer>
                                <FormRadioWrapper>
                                    <FormRadio
                                        type="radio"
                                        style={{ marginRight: "10px" }}
                                        id="left"
                                        value="left"
                                        name="headerImagePosition"
                                        onChange={parametersChangeHandler}
                                        checked={parametres?.headerImagePosition?.toLowerCase() === "left"}
                                    />
                                    <RadioButtonLabel />
                                    <FormLabel htmlFor="left">{t("template:Gauche")}</FormLabel>
                                </FormRadioWrapper>
                                <FormRadioWrapper>
                                    <FormRadio
                                        type="radio"     
                                        style={{ marginRight: "10px" }}
                                        id="center"
                                        value="center"
                                        name="headerImagePosition"
                                        onChange={parametersChangeHandler}
                                        checked={parametres?.headerImagePosition.toLowerCase() === "center"}
                                    />
                                    <RadioButtonLabel />
                                    <FormLabel htmlFor="center"> {t("template:Centre")}</FormLabel>
                                </FormRadioWrapper>
                                <FormRadioWrapper>
                                    <FormRadio
                                        type="radio"
                                        style={{ marginRight: "10px" }}
                                        id="right"
                                        value="right"
                                        name="headerImagePosition"
                                        onChange={parametersChangeHandler}
                                        checked={parametres?.headerImagePosition.toLowerCase() === "right"}
                                    />
                                    <RadioButtonLabel />
                                    <FormLabel htmlFor="right">{t("template:Droite")}</FormLabel>
                                </FormRadioWrapper>
                            </ETRadiosContainer>
                        </FormGroup>
                        <FormGroup>
                            <FormLabel htmlFor="headerImageWidth">{t("template:Largeur")}</FormLabel>
                            <FormInput
                                type="number"
                                name="headerImageWidth"
                                id="headerImageWidth"
                                value={parametres?.headerImageWidth}
                                onChange={(e) => headerImageWidthChange(e.target.value)}
                                autoComplete="off"
                                min="10"
                                max="200"
                            />
                        </FormGroup>
                        {
                            errors?.headerImageWidth && (
                                <FormInputError>{errors.headerImageWidth}</FormInputError>
                            )
                        }
                        <FormGroup>
                            <FormLabel htmlFor="headerImageHeight">{t("template:Hauteur")}</FormLabel>
                            <FormInput
                                type="number"
                                name="headerImageHeight"
                                id="headerImageHeight"
                                value={parametres?.headerImageHeight}
                                onChange={(e) => headerImageHeightChange(e.target.value)}
                                autoComplete="off"
                                min="10"
                                max="200"
                            />
                        </FormGroup>
                        {
                            errors?.headerImageHeight && (
                                <FormInputError>{errors.headerImageHeight}</FormInputError>
                            )
                        }
                    </ETSectionForm>
                </ETSection>
                <ETSection>
                    <ETSectionTitle>{t("template:BasDePage")}</ETSectionTitle>
                    <ETSectionForm>
                    
                    <FormGroup>
                        <FormLabel htmlFor="footerImage">{t("template:Image")}</FormLabel>
                        <FileFormWrapper>
                            <FileName title={footerImageName ||  ""} >{footerImageName ||  ""}</FileName>
                            <FileUploaderBtn
                                btnLabel={t("template:ChoisirUnFichier")}
                                handleChange={footerImageChange}
                                name="footerImage"
                            />
                        </FileFormWrapper> 
                    </FormGroup>
                    {
                        errors?.footerImage && (
                            <FormInputError>{errors.footerImage}</FormInputError>
                        )
                    }
                    {
                        footerImage && (
                            <ETImgContainer>
                                <img src={footerImage} alt="footerImage" />
                            </ETImgContainer>
                        )
                    }
                    <FormGroup>
                        <FormLabel htmlFor="footerImagePosition">{t("template:Position")}</FormLabel>
                        <ETRadiosContainer>
                            <FormRadioWrapper>
                                <FormRadio
                                    type="radio"
                                    style={{ marginRight: "10px" }}
                                    id="left"
                                    value="left"
                                    name="footerImagePosition"
                                    onChange={parametersChangeHandler}
                                    checked={parametres?.footerImagePosition.toLowerCase() === "left"}
                                />
                                <RadioButtonLabel />
                                <FormLabel htmlFor="left">{t("template:Gauche")}</FormLabel>
                            </FormRadioWrapper>
                            <FormRadioWrapper>
                                <FormRadio
                                    type="radio"
                                    style={{ marginRight: "10px" }}
                                    id="top"
                                    value="top"
                                    name="footerImagePosition"
                                    onChange={parametersChangeHandler}
                                    checked={parametres?.footerImagePosition.toLowerCase() === "top"}
                                />
                                <RadioButtonLabel />
                                <FormLabel htmlFor="top">{t("template:Haut")}</FormLabel>
                            </FormRadioWrapper>
                            <FormRadioWrapper>
                                <FormRadio
                                    type="radio"
                                    style={{ marginRight: "10px" }}
                                    id="bottom"
                                    value="bottom"
                                    name="footerImagePosition"
                                    onChange={parametersChangeHandler}
                                    checked={parametres?.footerImagePosition.toLowerCase() === "bottom"}
                                />
                                <RadioButtonLabel />
                                <FormLabel htmlFor="bottom">{t("template:Bas")}</FormLabel>
                            </FormRadioWrapper>
                            <FormRadioWrapper>
                                <FormRadio
                                    type="radio"
                                    style={{ marginRight: "10px" }}
                                    id="right"
                                    value="right"
                                    name="footerImagePosition"
                                    onChange={parametersChangeHandler}
                                    checked={parametres?.footerImagePosition.toLowerCase() === "right"}
                                />
                                <RadioButtonLabel />
                                <FormLabel htmlFor="right">{t("template:Droite")}</FormLabel>
                            </FormRadioWrapper>
                        </ETRadiosContainer>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="footerImageWidth">{t("template:Largeur")}</FormLabel>
                        <FormInput
                            type="number"
                            name="footerImageWidth"
                            id="footerImageWidth"
                            value={parametres?.footerImageWidth}
                            onChange={(e) => footerImageWidthChange(e.target.value)}
                            autoComplete="off"
                            min="10"
                            max="200"
                        />
                    </FormGroup>
                    {
                        errors?.footerImageWidth && (
                            <FormInputError>{errors.footerImageWidth}</FormInputError>
                        )
                    }
                    <FormGroup>
                        <FormLabel htmlFor="footerImageHeight">{t("template:Hauteur")}</FormLabel>
                        <FormInput
                            type="number"
                            name="footerImageHeight"
                            id="footerImageHeight"
                            value={parametres?.footerImageHeight}
                            onChange={(e) => footerImageHeightChange(e.target.value)}
                            autoComplete="off"
                            min="10"
                            max="200"
                        />
                    </FormGroup>
                    {
                        errors?.footerImageHeight && (
                            <FormInputError>{errors.footerImageHeight}</FormInputError>
                        )
                    } 
                    <FormGroup>
                        <FormLabel htmlFor="footerText">{t("template:Texte")}</FormLabel>
                        {/* <FormTextarea
                            style={{ textAlign: "left" }}
                            type="text"
                            name="footerText"
                            id="footerText"
                            value={parametres?.footerText}
                            onChange={(e) => parametersChangeHandler(e)}
                            autoComplete="off"
                            cols="2"
                        /> */}
                        <FormTextEditorWrapper>
                            <EmailEditor
                                content={parametres?.footerText || ""}
                                addTextHandler={footerTextChangeHandler}
                                editorStyle={{height: '65%'}}
                            />
                        </FormTextEditorWrapper>
                    </FormGroup>
                    </ETSectionForm>
                </ETSection>
            </ETContent>
        </ETBody>
    </ETContainer>
  )
}

export default EmailTemplate