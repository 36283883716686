import styled from 'styled-components/macro';

export const DProgressLine = styled.div`
    display: flex;
   
    justify-content: flex-start;
    /* border-bottom: 4px solid #F5FBFF; */
    margin-right: 4rem;
`

export const DProgressLineItem = styled.div`
    display: flex;
    justify-content: flex-start;
`

export const DProgressLineItemTitle = styled.div`
    font-size: 0.9rem;
    color: ${({color, theme}) => color || theme.colors.secondary};

    & span {
        padding-bottom: 0.5rem;
    }
`
export const DProgressLineStatus = styled.div`
display:flex;
width: ${({status, theme}) => (status=="DONE"||status=="ERROR"||status=="WAITING") ? "415px": "207px"};
  border-bottom: 4px solid  ${({status, theme}) => status=="ERROR" ? 'red' :status=="WAITING"? "#f4f4f9" :theme.colors.secondary};
`

export const DProgressLineItemValue = styled.div`
    font-size: 0.9rem;
    color: ${({color, theme}) => color || theme.colors.secondary};

    & span {
        padding: 0 1rem 0.5rem 0.25rem;
    }
`

export const DProgressLineBar = styled.div`
    height: 10px;
    position: relative;
    bottom: -6px;
    background-color: ${({color, theme}) => color || theme.colors.secondary};
    /* box-shadow: 0px 3px 6px #00000029; */
    border-radius: ${({isFirst}) => isFirst ? '18px' : '0px'} ${({isCurrent, isLast}) => (isCurrent || isLast) ? '18px' : '0px'} ${({isCurrent, isLast}) => (isCurrent || isLast) ? '18px' : '0px'} ${({isFirst}) => isFirst ? '18px' : '0px'};
`