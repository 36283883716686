import React from 'react'
import './TablePagination.css';
import Pagination from "react-js-pagination";
import { useTranslation } from 'react-i18next';
import { PerPageGroup, PerPageLabel, PerPageSelect, TPContainer } from './TablePagination.styled';
import { FormGroup, FormLabel, FormSelect } from '../../../styles/Common';
import  ListeMenu from '../DensityAndColumns/ListeMenu'
import ShowColumns from '../DensityAndColumns/ShowColumns';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
function DensiteAndColonnes(props) {
    const {t} = useTranslation();
    const {currentPage, pageChange, totalElement, perPage, perPageChange,setDensity,columns,setColumns} = props;
    const pageNumbers = [];
    const pageElement = perPage || 10;
    for (let i = 1; i <= Math.ceil(totalElement / pageElement); i++) {
        pageNumbers.push(i);
    }

 let arrow = ( <ArrowBackIcon style={{fontSize:"15px"}} color="primary" />)
 let arrowForward = ( <ArrowForwardIcon style={{fontSize:"15px"}} color="primary"/>)

  return (
      <TPContainer>
  {
              perPageChange && (
                <PerPageGroup>
                    <PerPageLabel htmlFor="perPage">{t("global:rows", 'Lignes')}</PerPageLabel>
                    <PerPageSelect 
                        id="perPage" 
                        value={perPage}
                        onChange={(e) => perPageChange(e.target.value)}
                    >
                        <option value="2">2</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </PerPageSelect>
                    { setDensity?
                    <ListeMenu setDensity={setDensity}/>:null}
                    {columns?
                    <ShowColumns columns={columns} setColumns={setColumns}/>:null}
                </PerPageGroup>
              )
          }
        










        {/* <nav className="usersMAnag__paginationContainer">
            <div className="usersMAnag__paginationList">
                <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={pageElement}
                    totalItemsCount={totalElement}
                    pageRangeDisplayed={5}
                    onChange={(page) => pageChange(page)}
                    innerClass={"usersMAnag__pagination"}
                    itemClass={"usersMAnag__page_item"}
                    linkClass={"usersMAnag__page_link"}
                    activeClass={"usersMAnag__active"}
                    disabledClass={"usersMAnag__disabled"}
                   
                    linkClassLast={"class__Last"}
                    linkClassFirst={"class__First"}
                    
                    linkClassPrev={"class__prev"}
                    linkClassNext={"class__next"}
                    nextPageText={arrowForward}
                    prevPageText={arrow}
                    hideFirstLastPages={false}
                />
                        <span className="usersMAnag__pagination__total">{currentPage*pageElement-(pageElement-1)}-{totalElement>pageElement*currentPage?pageElement*currentPage:totalElement} sur {totalElement} {t('tableAction:result', {defaultValue: "résultat(s)"})} </span>

            </div>
            
        </nav> */}
       
    </TPContainer>
  )
}

export default DensiteAndColonnes
