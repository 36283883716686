import styled from 'styled-components';

export const SGContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const SGBody = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 3rem;
`

export const SGSection = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`

export const SGCol = styled.div`
    /* width: 46%; */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 0.9rem;
    padding: 1.5rem 2rem;
    border: 1px solid #D9E1E7CC; 
    border-radius: 5px;
`

export const SGColTitle = styled.h5`
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
    margin-bottom: 2rem;
`

export const SGFormGroup = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 0.25rem;
    margin-bottom: 2rem;
    border-bottom: 2px solid #F5FBFF;
`;