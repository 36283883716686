import styled from 'styled-components/macro';

export const ISContainer = styled.div`
    width: 35%;
    border: 1px solid ${({theme}) => theme.colors.secondary};
    border-radius: 5px;
    /* padding: 2rem 1.5rem; */
    height: 700px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`

export const ISNavContainer = styled.div`
    width: 100%;
    margin-bottom: 42px;
    background-color: #E1E1E2;
    padding: 17px 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ISNavContent = styled.div`
    width: 100%;
    /* height: 80%; */
    flex: 1;
    padding: 0 25px;
    overflow-y: hidden;
`