import React, { useState,useEffect } from 'react'
import NavBar from '../../components/NavBar/NavBar';
import SmallNavBar from '../../components/SmallNavBar/SmallNavBar';
import { useNavigate, useLocation } from 'react-router-dom';
function Menu({items}) {
    const [activeNav, setActiveNav] = useState(localStorage.getItem('activeNavBar') || 'navBar');
    const [activeNavLink, setActiveNavLink] = useState(localStorage.getItem('activeNavLink') || '');

    let location = useLocation();

    useEffect(() => {
       localStorage.setItem('activeNavLink', location.pathname);
       setActiveNavLink(location.pathname)
    }, [location.pathname])


    const toggleNav = () => {
        switch (activeNav) {
            case 'navBar':
                localStorage.setItem('activeNavBar', 'smallNavBar');
                setActiveNav('smallNavBar');
                break;
            case 'smallNavBar':
                localStorage.setItem('activeNavBar', 'navBar');
                setActiveNav('navBar');
                break;
            default:
                break;
        }
    }

  return (
      <>
        {
            activeNav === 'navBar' ? <NavBar activeLink={activeNavLink}toggleNav={toggleNav} items={items} /> : <SmallNavBar activeLink={activeNavLink} toggleNav={toggleNav} items={items}/>
        }
      </>
  )
}

export default Menu