import React, {useEffect, useState} from 'react';
import Axios from '../../../axios-proas';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { EventsContainer, EventsHeader, EventsTitle, EventsList, EventsItem, EventColor, EventValue, EventTitle, EventDate, EventAction, EventVal, EventMessage, EventFilterItem } from './Events.styled';
import {formatDate, getTimeDate} from '../../../shared/utility'
import { useNavigate, useLocation , useParams} from 'react-router-dom';
import { useTheme } from 'styled-components';
import Spinner from 'react-bootstrap/Spinner'
import DropDown from '../../UI/DropDown/DropDown';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import EventsFilter from './EventsFilter/EntityFilter';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
// const eventsList = [
//     {color: theme.colors.success , value: 'Fournisseur 1', date: '5 minuites'},
//     {color: theme.colors.warning, value: 'Fournisseur 2', date: '5 minuites'},
//     {color: theme.colors.success, value: 'Fournisseur 3', date: '5 minuites'},
//     {color: theme.colors.warning, value: 'Fournisseur 4', date: '5 minuites'},
//     {color: theme.colors.success, value: 'Campagne 1', date: '5 minuites'},
//     {color: theme.colors.danger, value: 'Transdev', date: '5 minuites'},
//     {color: theme.colors.success, value: 'Transdev', date: '5 minuites'},
// ]
function Events({userType}) {
    const {t} = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const [eventsList, setEventsList] = useState([]);
    const [loading , setLoading] = useState(false)
    const [filter, setFilter] = useState(null)
    const [filteredEvents, setFilteredEvents] = useState()

    const getEventEntity = () => {
        setLoading(true)
        Axios.get("/user//userEvents").then(res => {
            const eventsData = res.data?.events ? res.data.events : [];
            setEventsList(eventsData);
            setFilteredEvents(eventsData);
        }).catch(res => {
            notyf.error("Une erreur s'est produite!");
        }).finally(() => {
            setLoading(false)
        })
    }

    const getuserTypeEvents = () => {
        setLoading(true)
        Axios.get("/user//userTypeEvents").then(res => {
            const eventsData = res.data?.events ? res.data.events : [];
            setEventsList(eventsData);
            setFilteredEvents(eventsData);
        }).catch(res => {
            notyf.error("Une erreur s'est produite!");
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        if(userType === 'client' || userType === 'supplier')
            getuserTypeEvents()
        else
            getEventEntity();
    }, [])

    const getInvoiceEventColor = (event) => {
        switch (event?.snapshot) {
            case "IMPORTED":
                return theme.colors.warning;
            case "INTEGRATED":
                return theme.colors.success;
            case "LITIGATION":
            case "ERROR":
                return theme.colors.danger;
            default:
                return theme.colors.success;
        }
    }

    const getEntityEventColor = (event) => {
        switch (event?.type) {
            case "STEP":
                switch (event?.message) {
                    case "INPROGRESS":
                        return theme.colors.warning;
                    case "ACTIVE":
                        return theme.colors.success;
                    default:
                        return theme.colors.warning;
                }
            case "DOCS":
                return theme.colors.warning;
            case "ERROR_FIELD":
                return theme.colors.danger;
            default:
                return theme.colors.warning;
        }
    }

    const getInvoiceEventTitle = (eventTmp) => {
        if(userType === 'client')
            return eventTmp?.data?.clientName ? eventTmp.data.clientName : eventTmp?.user?.entityName;
        return eventTmp?.data?.clientName ? eventTmp.data.clientName : eventTmp?.user?.entityName;
        
    }

    const getInvoiceEventMessage = (event) => {
        switch (event?.snapshot) {
            case "IMPORTED":
                return event?.data?.number ? t("mandat:remindContributor", `Facture ${event?.data?.number} importée`, {number : event?.data?.number}): t("mandat:remindContributorMSG", `Facture ${event?.message} importée`, {number : event?.message});
            case "INTEGRATED":
                return event?.data?.number ? t("dashboard:factureIntégrée",`Facture ${event?.data?.number} intégrée`, {number : event?.data?.number})  : t("dashboard:factureIntégréeMSG",`Facture ${event?.message} intégrée`, {number : event?.message}) ;
            case "ERROR":
                return event?.data?.number ? t("dashboard:factureErronée",`Facture ${event?.data?.number} erronée`, {number : event?.data?.number}) : t("dashboard:factureErronéeMSG",`Facture ${event?.message} erronée`, {number : event?.message}) ;
            case "PAID":
                return event?.data?.number ? t("dashboard:facturePayée",`Facture ${event?.data?.number} payée`, {number : event?.data?.number}) : t("dashboard:facturePayéeMSG",`Facture ${event?.message} payée`, {number : event?.message}) ;
            case "TO_BE_PAID":
                return event?.data?.number ? t("dashboard:factureAPayée",`Facture ${event?.data?.number} à payée`, {number : event?.data?.number}) : t("dashboard:factureAPayéeMSG",`Facture ${event?.message} à payée`, {number : event?.message}) ;
            case "LITIGATION":
                return event?.data?.number ? t("dashboard:factureEnlitige",`Facture ${event?.data?.number} en litige`, {number : event?.data?.number}) : t("dashboard:factureEnlitigeMSG",`Facture ${event?.message} en litige`, {number : event?.message}) ;
            default:
                return "";
        }
    }

    const getEntityEventMessage = (event) => {
        switch (event?.message) {
            case "email":
                return `Champs email erroné`;
            case "INPROGRESS":
                return `Demande de validation`;
            case "ACTIVE":
                return  `Fournisseur activé`;
            case "DOCUMENT":
                return  `Nouveau document ${event.snapshot}`;
            default:
                return "";
        }
    }

    const updateFilterHandler = (newFitler) => {
        let filterTemp = null;
        switch (newFitler) {
            case "invoice":
                if (filter === "entity" ) {
                    filterTemp = null;
                } else if (filter === "invoice" || filter === null) {
                    filterTemp = "entity";
                } 
                break;
            case "entity":
                if (filter === "invoice" ) {
                    filterTemp = null;
                } else if (filter === "entity" || filter === null) {
                    filterTemp = "invoice";
                } 
                break;
            default:
                filterTemp = null;
                break;
        }
        let filteredEventsTemp = filterTemp ? eventsList.filter(event => event?.eventType === filterTemp) : eventsList;
        setFilter(filterTemp)
        setFilteredEvents(filteredEventsTemp)
    }
const navigateToEntity=(uid,type)=>{
    if( type === "DOCS"){
        localStorage.setItem("show", "document");
    }
    navigate("/entity/"+uid)
}

    let eventsJsx = null;

    if(loading) 
        eventsJsx = (<Spinner />)
    else if (filteredEvents && filteredEvents?.length > 0){
        eventsJsx = filteredEvents?.map((eventTmp, index) => {
                var eventJsx = null;
                let isLast = index === 0
                let color = theme.colors.warning;
                let title = "";
                let message = "";
                let date = "";
                let action = null;

                switch (eventTmp?.eventType) {
                    case "invoice":
                        color = getInvoiceEventColor(eventTmp);
                        // title = eventTmp?.data?.supplierName ? eventTmp?.data?.supplierName : eventTmp?.user?.entityName;
                        title = getInvoiceEventTitle(eventTmp);
                        message = getInvoiceEventMessage(eventTmp);
                        date = formatDate(+eventTmp?.creationDate) + " " + getTimeDate(+eventTmp?.creationDate);
                        action = (eventTmp.hasOwnProperty("data") && eventTmp?.data ) ? (
                            <EventAction   onClick={() => navigate("/invoiceDetail/"+eventTmp?.data?.invoiceUid)}>
                                <span>{t('btn:voir')}</span>
                                <ArrowForwardIosIcon />
                            </EventAction>
                        ) : null;
                        break;
                    case "entity":
                        color = getEntityEventColor(eventTmp);
                        title = eventTmp?.name;
                        message = getEntityEventMessage(eventTmp);
                        date = formatDate(+eventTmp?.creationDate) + " " + getTimeDate(+eventTmp?.creationDate);
                        
                        action = (
                            <EventAction   onClick={() => navigateToEntity(eventTmp.entityUid,eventTmp.type)}>
                                <span>{t('btn:voir')}</span>
                                <ArrowForwardIosIcon />
                            </EventAction>
                        );
                        break;
                    default:
                        break;
                }
               return (
                    <EventsItem key={index}>
                        <EventColor color={color} />
                        <EventValue>
                            <EventVal>
                                {title}
                            </EventVal>
                            <EventMessage title={message} >
                                {message}
                            </EventMessage>
                            <EventDate>
                                {date}
                            </EventDate>
                        </EventValue>
                        {action}
                    </EventsItem>
               )
            })
    }
  return (
      <>
        <EventsContainer>
            <EventsHeader>
                <EventsTitle>
                    {t("dashboard:Evenements")}
                </EventsTitle>
                {/*  pou le btn filtre evenment
                <EventsFilter
                    filter={filter}
                    updateFilter={updateFilterHandler}
                /> */}
            </EventsHeader>
            <EventsList>
                {
                    eventsJsx
                }
            </EventsList>
        </EventsContainer>
      </>
  );
}

const mapStateToProps = (state) => ({
    userType : state.auth.userType,
    login : state.auth.login
  })
const mapDispatchToProps = dispatch => ({
})
export default connect(mapStateToProps, mapDispatchToProps) (Events)
