import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    * {
        box-sizing: border-box;
    }

    body {
        background: ${({ theme }) => theme.colors.body};
        /* font-family: 'Roboto Medium', 'Roboto Regular', 'Roboto', sans-serif; */
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        margin: 0;
    }

    p {
        /* opacity: 0.6; */
        line-height: 1.5;
    }

    img {
        max-width: 100%;
    }

    .modal_w_mc{
        max-width: fit-content;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1; 
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: rgb(214, 209, 209); 
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: rgb(204, 199, 199); 
    }

    .notyf__toast {
        padding: 0.3rem 0.75rem;
        border-radius: 5px;
        width: 400px;
        max-width: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
    }

    .notyf__toast .notyf__wrapper {
        padding: 0;
        width: 100%;
        justify-content: space-between;
    }

    .notyf__toast .notyf__icon {
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.25rem;
    }

    .notyf__toast .notyf__message {
        color: ${({ theme }) => theme.colors.text};
        font-size: 0.9rem;
        display: flex;
        flex: 1;
    }
    
    .notyf__toast .notyf__dismiss {
        position: relative;
        margin-right: -7px;
    }

    .notyf__toast .notyf__dismiss .notyf__dismiss-btn {
        background-color: #FFFFFF;
    }

    .notyf__toast .notyf__dismiss .notyf__dismiss-btn::before {
        height: 20px;
        background-color: ${({ theme }) => theme.colors.text};
        top: calc(50% - 10px);
    }

    .notyf__toast .notyf__dismiss .notyf__dismiss-btn::after {
        height: 20px;
        background-color: ${({ theme }) => theme.colors.text};
        top: calc(50% - 10px);
    }

    .toast-warning-wrapper {
        box-shadow: 0 0px 3px 0 ${({ theme }) => theme.colors.orangeLight};
        border : 1px solid orange;
    }
    
    .toast-warning-wrapper .notyf__icon {
        background-color: ${({ theme }) => theme.colors.orangeLight};
    }

    .toast-error-wrapper {
        box-shadow: 0 0px 3px 0 ${({ theme }) => theme.colors.redLight};
        border : 1px solid ${({ theme }) => theme.colors.danger};
    }
    
    .toast-error-wrapper .notyf__icon {
        background-color: ${({ theme }) => theme.colors.redLight};
    }

    .toast-success-wrapper {
        box-shadow: 0 0px 3px 0 ${({ theme }) => theme.colors.greenLight};
        border : 1px solid ${({ theme }) => theme.colors.success};
    }
    
    .toast-success-wrapper .notyf__icon {
        background-color: ${({ theme }) => theme.colors.greenLight};
    }

    
`

export default GlobalStyles;