import React, { useEffect, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check';
import {formatDate, getStatusWithKey, getTimeDate} from '../../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import { MessageContent, MessageFrom, MessageWrapper, TEContainer, TEContent, TEHeader, TEIcon, TEPreview, TETime, TETitle } from '../../Timeline.styled';
import { useTheme } from 'styled-components';

function StatusEvent({isCreator, eventData, isLast, userType}) {
    const [showContent, setShowContent] = useState(false)
    const {t} = useTranslation();
    const theme = useTheme()

    useEffect(() => {
        if(isLast)
            setShowContent(true)
        else
            setShowContent(false)
    }, [isLast])

    const getStatusColor = (value) => {
        switch(value) {
            case 'FILED':
            case 'NEW':
            case 'TO_BE_PAID':
            case 'PAID':
            case 'IMPORTED':
            case 'INTEGRATED':
                return theme.colors.primary;
            case 'PENDING':
                return theme.colors.warning;
            case 'ERROR':
            case 'LITIGATION':
                return theme.colors.danger;
            default:
                return theme.colors.primary;
        }
    }

    return (
        <>
            <TEContainer >
                <TEHeader onClick={() => setShowContent(!showContent)}>
                    <TEIcon borderBold={true} bdColor={getStatusColor(eventData?.snapshot)} color={getStatusColor(eventData?.snapshot)} >
                        <CheckIcon />
                    </TEIcon>
                    <TEPreview>
                        <TETitle color={getStatusColor(eventData?.snapshot)}>
                             {/* {t(getStatusWithKey(eventData?.snapshot, userType))} */}
                             {eventData?.snapshot==="CONTROLLED" ?
                                 t("invoiceStatut:Checked")
                            : t(`invoiceStatut:${getStatusWithKey(eventData?.snapshot, userType)}`)
                            }
                        </TETitle>
                        <TETime>
                            le {formatDate(+eventData?.creationDate)} à {getTimeDate(+eventData?.creationDate)}
                        </TETime>
                    </TEPreview>
                </TEHeader>
                {
                    showContent && (
                        <TEContent>
                            {
                                eventData?.snapshot === 'LITIGATION' && (
                                    <MessageWrapper>
                                        <MessageFrom>
                                            {t("default:reason", "Motif : ")}
                                        </MessageFrom>
                                        <MessageContent>
                                            {eventData?.message?.slice(0, 128)}
                                            {eventData?.message?.length > 128 ? '...' : null}
                                        </MessageContent>
                                    </MessageWrapper>
                                )
                            }
                            {
                                eventData?.comment && (
                                    <MessageWrapper>
                                        <MessageFrom>
                                            {t("default:comment", "Commentaire : ")}
                                        </MessageFrom>
                                        <MessageContent>
                                            {eventData?.comment?.slice(0, 128)}
                                            {eventData?.comment?.length > 128 ? '...' : null}
                                        </MessageContent>
                                    </MessageWrapper>
                                )
                            }
                        </TEContent>
                    )
                }
            </TEContainer>
        </>
    )
}

export default StatusEvent