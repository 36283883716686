
import "./AccountRequest.css";
import React, { useEffect, useState, useRef, useMemo } from 'react'
import { BtnAction, BtnOutlineAction, FormECILabel, FormECItem, FormECIValue, FormECLabel, FormECListContainer, FormEntCanal, SendButton } from "../../styles/Common";
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png'
import { useTranslation } from 'react-i18next';
import LanguageList from '../LanguageList/LanguageList';

import EntitiesDropDown from "./EntitiesDropDown/EntitiesDropDown";
import CloseIcon from '@mui/icons-material/Close';
import Axios from '../../axios-proas';
import { useLocation, useNavigate } from 'react-router-dom';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import InformationModal from '../UI/ConfirmationModal/InformationModal';

import { useTheme } from 'styled-components';
import { getNotyfObject } from "../../shared/utility";
function AccountRequest() {
    const { t } = useTranslation();
    const notyf = getNotyfObject()
    const supplierCanalList = useMemo(() => ([
        {value: 'PORTAL', label: t("signin:accessPortal", "Portail")},
        {value: 'EMAIL', label: t("signin:canalEmail", "Email")},
        {value: 'PAPER', label: t("signin:edi", "Papier")},
        {value: 'EDI', label: t("signin:edi", "EDI")},
        {value: 'PDP', label: t("signin:pdp", "PDP")},
        {value: 'PPF', label: t("signin:pdp", "PPF")}
    ]), [t]);
    const [entity, setEntity] = useState({
        countryCode : "fr",
        family : "ADMINISTRATION",
        lang: "fr"
    });
    const [confirmationModalShow, setConfirmationModalShow] = useState(false);
    const theme = useTheme();
    const navigate = useNavigate();
    const [selectedEntities, setSelectedEntities] = useState([]);

    const handleInputChange = (e) => {
      
        const key = e.target.name;
        var value = e.target.value;
       
        if( key == "actif"){
            e.target.checked ? value = 1 : value = 0;
        }

            const newSupplier = {...entity, [key]: value};
        setEntity(newSupplier);
       
       
    }

    const canalChangeHandler = (value) => {
        let entityTmp = {
            ...entity,
            channel : value
        };
        setEntity(entityTmp);
    }

    const supplierCanalChangeHandler = (field, value) => {
        let entityTmp = {...entity};
        supplierCanalList.forEach(canal => {
            entityTmp[canal.field] = canal.field === field ? value : 0;
        });
        setEntity(entityTmp);
    }

    const ownersChangeHandler = (ownersList) => {
        let entityTemp = {
            ...entity,
            owners : ownersList
        }
        setEntity(entityTemp)
    }
    const retour=()=>{
        setConfirmationModalShow(false)
        navigate("/");
    }


    const requestSupplierclickHandler = () => {
        
        const entityToAdd = {
            ...entity,
            type: "supplier"
        }
        Axios.post('entity//request', entityToAdd).then(response => {
            // if (response.data.hasOwnProperty('success')) {
                setConfirmationModalShow(true)
                notyf.success(t("default:userRequested", "La création de l'entité a bien été demandée"))
                setEntity({
                    countryCode : "fr",
                    family : "ADMINISTRATION",
                    lang: "fr"
                });
                navigate("/");
            //}
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
       })
    }
    const deleteOwnerclickHandler = (owner) => {

        let entityTemp = {...entity}
        if(entity?.id && !owner?.new ){
            entityTemp = {
                ...entityTemp,
                ownersToDelete : entityTemp?.ownersToDelete ? [...entityTemp?.ownersToDelete, owner.uid] : [owner.uid]
            }
        }
        let entityOwners = [...entity?.owners]
        let ownerIndex = entityOwners.findIndex(ent => ent.uid === owner.uid);
        if(ownerIndex >=0 ){
            entityOwners.splice(ownerIndex, 1)
        }
        setEntity({
            ...entityTemp,
            owners: entityOwners
        })
    }
    const disableCanal=(e)=>{
        setEntity ({...entity, [e] : false});
    }
    const enableCanal=(e)=>{
        setEntity ({...entity, [e] : true});
    } 

  return(
    <div className="accountRequest__container">
        <div className="accountRequest__FromWrap">
            <div className="accountRequest__fromContent">
                {/* <div className="signin_language_container">
                    <LanguageList
                    // iconColor="#1e98d7"
                    iconColor="#6C5CE7"
                    />
                </div> */}
                <div className="accountRequest__header">
                    <Link to="/" className="accountRequest__Icon">
                        <img src={logo} alt="logo" />
                    </Link>
                </div>
                <form className="accountRequest__form" action="#">
                    <div className="accountRequest__formGroup">
                        <input
                        className="accountRequest__formInput"
                        type="text"
                        name="name"
                        value={entity?.name||"" }
                        placeholder={t("signin:name", "Nom")}
                        onChange={handleInputChange}
                        
                        />
                    </div>
                    <div className="accountRequest__formGroup">
                        <input
                        className="accountRequest__formInput"
                        type="text"
                        value={entity?.siren || ""}
                        name="siren"
                        placeholder={t("signin:login", "Identifiant  1:N° Siren")}
                        onChange={handleInputChange}
                        
                        />
                    </div>
                    <div className="accountRequest__formGroup">
                        <input
                        className="accountRequest__formInput"
                        type="text"
                        value={entity?.siret || ""}
                        name="siret"
                        placeholder={t("signin:login", "Identifiant 2:N° Siret")}
                        onChange={handleInputChange}
                        
                        />
                    </div>
                    <div className="accountRequest__formGroup">
                        <input
                        className="accountRequest__formInput"
                        type="text"
                        value={entity?.vat || ""}
                        name="vat"
                        placeholder={t("signin:login", "N° TVA")}
                        onChange={handleInputChange}
                        
                        />
                    </div>
                    <div className="accountRequest__formGroup">
                        <input
                        className="accountRequest__formInput"
                        type="text" 
                        name="address"
                        value={entity?.address || ""}
                        placeholder={t("signin:login", "Adresse")}
                        onChange={handleInputChange}
                       
                        />
                    </div>
                    <div className="accountRequest__formGroup">
                        <input
                        className="accountRequest__formInput"
                        type="text"
                        value={entity?.addressComplement || ""}
                        name="addressComplement"
                        placeholder={t("signin:addressComplement", "Adresse complément")}
                        onChange={handleInputChange}
                        
                        />
                    </div>
                    <div className="accountRequest__formGroup">
                        <input
                        className="accountRequest__formInput"
                        type="text"
                        name="zipCode"
                        value={entity?.zipCode || ""}
                        placeholder={t("signin:zipCode", "Code postal")}
                        onChange={handleInputChange}
                    
                        />
                    </div>
                    <div className="accountRequest__formGroup">
                        <input
                        className="accountRequest__formInput"
                        type="text"
                        name="city"
                        value={entity?.city || ""}
                        placeholder={t("signin:city", "Ville")}
                        onChange={handleInputChange}
                       
                        />
                    </div>
                    <div className="accountRequest__formGroup">
                        <select 
                            id="countryCode" 
                            className="accountRequest__formInput" 
                            value={entity?.countryCode || ""} 
                            name="countryCode" 
                            onChange={handleInputChange}
                        >
                            <option value="fr">FRANCE</option>
                        </select>
                    </div>
                    <div className="accountRequest__formGroup">
                        <input
                            className="accountRequest__formInput"
                            type="text"
                            name="entityAdmin"
                            value={entity?.entityAdmin || ""}
                            placeholder={t("signin:adminContact", "Contact administrateur")}
                            onChange={handleInputChange}
                            
                        />
                    </div>
                    <FormEntCanal>
                        <FormECLabel >{t("signin:canal", "Canal d'échange")}</FormECLabel>
                        <FormECListContainer>
                            {
                                supplierCanalList.map((canal, index) => (
                                    <FormECItem isActive={entity?.channel === canal.value}>
                                        <FormECILabel>{canal?.label} </FormECILabel>
                                        <FormECIValue>
                                            {
                                                entity?.channel === canal.value ? (
                                                        <ToggleOnIcon 
                                                            style={{marginLeft: '0.25rem', cursor: 'pointer'}} 
                                                            onClick={() => canalChangeHandler(null)}
                                                            
                                                        />
                                                
                                                ) : 
                                                        <ToggleOffIcon 
                                                            style={{marginLeft: '0.25rem', cursor: 'pointer'}}
                                                            onClick={() => canalChangeHandler(canal?.value)}
                                                        />
                                                
                                            }
                                        </FormECIValue>
                                    </FormECItem>
                                ))
                            }
                        </FormECListContainer>
                    </FormEntCanal>
                    
                   
                   <div>
                      
                        <EntitiesDropDown deleteOwnerclickHandler={deleteOwnerclickHandler} selectedEntities={selectedEntities} setSelectedEntities={setSelectedEntities}  addEntity={ownersChangeHandler} />

                      
                  
                    </div>       

                    <div className="accountRequest__formButtons">
                        <BtnOutlineAction  
                            color={theme.colors.danger}
                            hover={theme.colors.redLight}
                            onClick={() => retour()}>
                            Annuler
                        </BtnOutlineAction>
                        
                        <BtnAction onClick={() => requestSupplierclickHandler()}>
                            Envoyer la demande
                        </BtnAction>
                    </div>

                </form>
                <div className="accountRequest__footer">
                    {/* {t("default:copyright", `© ${new Date().getFullYear()} ICD International`)} */}
                </div>
            </div>
        </div>

        <InformationModal 
                show={confirmationModalShow}
                modalClosed={() => retour(false)}
                confirm={() => retour(document)}
                cancel={() => retour(false)}
                title={"Confirmation de création"}
                message={`Vous recevrez un message électronique vous invitant à réinitialiser votre mot de passe.`}
            />
    </div>
  )
}

export default AccountRequest;
