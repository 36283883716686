import styled from 'styled-components';

export const SIProgressLine = styled.div`
    display: flex;
    justify-content: flex-start;
    border-bottom: 4px solid #F5FBFF;
`

export const SIProgressLineItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`

export const SIProgressLineItemTitle = styled.div`
    /* font-size: 0.9rem; */
    /* padding-bottom: 0.5rem; */
    display: flex;
    padding-right: 1.5rem;
    color: ${({color, theme}) => color || theme.colors.secondary};

    & span {
        margin-right: 0.25rem;
        white-space: nowrap;
    }
`

export const SIProgressLineBar = styled.div`
    height: 10px;
    position: relative;
    bottom: -6px;
    background-color: ${({color, theme}) => color || theme.colors.secondary};
    /* box-shadow: 0px 3px 6px #00000029; */
    border-radius: ${({isFirst}) => isFirst ? '18px' : '0px'} ${({isCurrent, isLast}) => (isCurrent || isLast) ? '18px' : '0px'} ${({isCurrent, isLast}) => (isCurrent || isLast) ? '18px' : '0px'} ${({isFirst}) => isFirst ? '18px' : '0px'};
`