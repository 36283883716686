import React, { useMemo } from 'react';
import { formatDate } from '../../../shared/utility';
import { DProgressLine, DProgressLineBar, DProgressLineItem, DProgressLineItemTitle, DProgressLineItemValue ,DProgressLineStatus} from './DateProgressLine.styled';
import { t } from "i18next";
function DateProgressLine({creationDate, campaignDate, endDate,statut}) {
   
    const PLItems = useMemo(() => [
        {title: t("global:Création"), value: new Date(+creationDate).setHours(0,0,0,0)},
        // {title: t("global:Début"), value: +campaignDate},
        // {title: t("global:Fin"), value: +endDate},
    ], [creationDate, campaignDate, endDate]);
    const currentDate = useMemo(() => new Date().setHours(23, 59, 59, 0), []);

  return (
      <div>
    <DProgressLine>
        {
            PLItems.map((item, index) => (
                <DProgressLineItem key={index}>
                    <DProgressLineItemTitle>
                        <span>{item.title} : </span>
                        
                        {/* {
                            currentDate >= item.value && (
                                <DProgressLineBar 
                                    isFirst={index === 0}
                                    isCurrent={currentDate < PLItems[index + 1]?.value } 
                                ></DProgressLineBar>
                            )
                        } */}
                    </DProgressLineItemTitle>
                    <DProgressLineItemValue>
                        <span>{formatDate(item.value)}</span>
                        {/* {
                            (
                                currentDate >= PLItems[index + 1]?.value
                                ||   currentDate >= endDate
                            ) && (
                                <DProgressLineBar
                                    isLast={index === PLItems.length - 1}
                                ></DProgressLineBar>
                            )
                        } */}
                    </DProgressLineItemValue>
                  
                </DProgressLineItem>
            ))
        }
    
    </DProgressLine>
            <DProgressLineStatus status={statut} >         
            </DProgressLineStatus>
    </div>
  );
}

export default DateProgressLine;
