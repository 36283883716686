import React, { useMemo } from 'react'
import Header from '../../../components/Header/Header'
import { AppBody, AppContainer, AppContent, AppWrapper } from '../../../styles/App.styled';
import { Navigate, Route, useLocation, Routes, useNavigate } from 'react-router-dom';
import Menu from '../../Menu/Menu'
import EditPassword from '../../EditPassword/EditPassword';
import ReferentielFournisseur from '../../ReferentielFournisseur/ReferentielFournisseur';
import Entity from '../../Entity/Entity';
import UserForm from '../../UserForm/UserForm';
import ReferentielClient from '../../ReferentielClient/ReferentielClient';
import SuiviInvoices from '../../SuiviInvoices/SuiviInvoices';
import Documentation from '../../Documentation/Documentation';
import NewDocumentation from '../../NewDocumentation/NewDocumentation';
import UploadInvoice from '../../UploadInvoice/UploadInvoice';
import InvoiceDetail from '../../InvoiceDetail/InvoiceDetail';
import Extraction from '../../Extraction/Extraction';
import ExtractionForm from '../../ExtractionForm/ExtractionForm';
import Campaigns from '../../Campaigns/Campaigns';
import CampaignForm from '../../CampaignForm/CampaignForm';
import EntitiesManager from '../../EntitiesManager/EntitiesManager';
import EmailsSettings from '../../EmailsSettings/EmailsSettings';
import Settings from '../../Settings/Settings';
import KPISupplier from '../../KPISupplier/KPISupplier';
import Home from '../../Home/Home';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import BadgeIcon from '@mui/icons-material/Badge';
import ArticleIcon from '@mui/icons-material/Article';
import SaveIcon from '@mui/icons-material/Save';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useTranslation } from 'react-i18next';
import ClientHome from '../../ClientHome/ClientHome'
import HomeSupplier from '../../HomeSupplier/HomeSupplier';
import UploadDoc from '../../UploadDocument/UploadDoc';
import Reporting from '../../Ereporting/Ereporting'
import EreportingInvoices from '../../EreportingInvoices/EreportingInvoices';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MoveToInboxOutlinedIcon from '@mui/icons-material/MoveToInboxOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import {ReactComponent as HomeIcon}  from '../../../assets/icons/home.svg';
import {ReactComponent as ArrowDownToSquare}  from '../../../assets/icons/arrowDownToSquare.svg';
import {ReactComponent as ChartSimple}  from '../../../assets/icons/chartSimple.svg';
import {ReactComponent as Comment}  from '../../../assets/icons/comment.svg';
import {ReactComponent as FloppyDisk}  from '../../../assets/icons/floppyDisk.svg';
import {ReactComponent as IdCardClip}  from '../../../assets/icons/idCardClip.svg';
import {ReactComponent as Memo}  from '../../../assets/icons/memo.svg';
import {ReactComponent as Shield}  from '../../../assets/icons/shield.svg';
import EmailTemplate from '../../../components/EmailsSettings/EmailTemplate/EmailTemplate';

import ConfigurationMail from '../../ConfigMail/ConfigurationMail';
import LocalPostOfficeOutlinedIcon from '@mui/icons-material/LocalPostOfficeOutlined';

import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';


function OwnerRoot({}) {
    const {t} = useTranslation();
    const menuItems = useMemo(() => ([
        {isLink: true, label: t('navbar:Accueil'), pathname: '/', icon: <HomeIcon />},
        // {isLink: false, label: 'Fournisseur', key: 'supplier', icon: <ArrowDownToSquare />, subMenu: [
        //     {path: '/referentielFournisseur', label: t('navbar:referentielFournisseur', 'Référentiel')},
        //     {path: '/fournisseurInvoices', label: t('navbar:invoiceTracking', 'Suivi des factures')},
        //     {path: '/kpiSupplier', label: t('navbar:kpi', 'KPI')},
        //     {path: '/embarquementSupplier', label: t('navbar:embarquement', 'Embarquement')}
        // ]},
        

        {isLink: false, label: t('navbar:FacturesClient'), key: 'client', icon: <IdCardClip />, subMenu: [
            {path: '/referentielClient', label: t('navbar:referentielClient', 'Référentiel')},
            {path: '/clientInvoices', label: t('navbar:invoiceTracking', 'Suivi des factures')},
            {path: '/kpiClient', label: t('navbar:Pilotage', 'Pilotage')},
            // {path: '/embarquementClient', label: t('navbar:embarquement', 'Embarquement')}
        ]},
        {isLink: true, label:t('navbar:Documentation', 'Documentation') , pathname: '/documentation', icon: <Memo />},
        {isLink: false, label: t('navbar:Message', 'Message') , key: 'communication', icon: <EmailOutlinedIcon style={{width:"30px",height:"31px",margin:"-1px"}} />, subMenu: [
            {path: '/suiviCampagnes', label: t('navbar:suiviCampagnes', 'Suivi des campagnes')}
        ]},
        {isLink: true, label: t('navbar:Extraction', 'Extraction') , pathname: '/extraction', icon: <FloppyDisk />},
        {isLink: false, label:t('navbar:Administration', 'Administration') , key: 'administration', icon: <Shield />, subMenu: [
            {path: '/entitiesManager', label: t('navbar:entitiesManager', 'Gestion des entités')},
            {path: '/emailsContentSettings', label: t("navbar:PersonnalisationDeMail", "Personnalisation de l'émail")},
            {path: '/emailsSettings', label: t("navbar:ConfigurationEnvoiMail", "Configuration envoi mail")},
            {path: '/configurationMail', label: t("navbar:ConfigurationMail", "Configuration mail")}
        ]},
        

        // {isLink: true, label: 'E-Reporting', pathname: '/reporting', icon: <ChartSimple />},
    ]), [t])

  return (
    <>
        <AppContainer>
       
            
            <Menu items={menuItems} />
            <AppBody style={{flexDirection:"column",margin:""}} >
            <Header /> 
            <AppWrapper>
                <AppContent>
                        <Routes>
                            <Route path="/editPassword" element={<EditPassword />}/>
                            <Route path="/referentielFournisseur" element={<ReferentielFournisseur entityFilter={"supplier"} />} />
                            <Route path="/reporting" element={<Reporting entityFilter={"supplier"} />} />
                            <Route path="/entity" element={<Entity newAccountRequest={false} />} />
                            <Route path="/entity/:uid" element={<Entity newAccountRequest={false} />} />
                            <Route path="/userForm/:uid" element={<UserForm />} />
                            <Route path="/userForm" element={<UserForm />} />
                            <Route path="/referentielClient" element={ <ReferentielClient entityFilter={"client"} />}/>
                            <Route path="/clientInvoices" element={ <SuiviInvoices entityFilter={"client"} />}/>
                            <Route path="/fournisseurInvoices" element={<SuiviInvoices entityFilter={"supplier"} />}/>
                            <Route path="/eReportingInvoices/:uid" element={<EreportingInvoices />}/>
                            <Route path="/documentation" element={<Documentation/>} />
                            <Route path="/addDocumentation" element={<NewDocumentation/>} />
                            <Route path="/upload" element={<UploadInvoice />} />
                            <Route path="/UploadDoc/:uid" element={<UploadDoc />} />
                            <Route path="/invoiceDetail/:uid" element={<InvoiceDetail />} />
                            <Route path="/invoiceDetail/:uid/comments" element={<InvoiceDetail />} />
                            <Route path="/extraction" element={<Extraction/>} />
                            <Route path="/extractionForm" element={<ExtractionForm/>} />
                            <Route path="/extractionForm/:uid" element={<ExtractionForm/>} />
                            <Route path="/suiviCampagnes" element={<Campaigns />} />
                            <Route path="/newCampaign" element={<CampaignForm />} />
                            <Route path="/newCampaign/:uid" element={<CampaignForm />} />
                            <Route path="/entitiesManager" element={<EntitiesManager />} />
                            <Route path="/emailsContentSettings" element={<EmailsSettings />}/>
                            <Route path="/emailsSettings" element={<Settings />}/>
                            <Route path="/kpiSupplier" element={<HomeSupplier />} />
                            <Route path="/embarquementSupplier" element={<Campaigns />} />
                            <Route path="/kpiClient" element={<ClientHome />} />
                            <Route path="/embarquementClient" element={<Campaigns />} />
                            <Route path="/emailsTemplate" element={<EmailTemplate />} />
                            <Route path="/configurationMail" element={<ConfigurationMail />} />
                            
                            <Route path="/" element={<Home />} />
                        </Routes>
                    </AppContent>
            </AppWrapper>
            
        </AppBody>
            
        </AppContainer>
    </>
  )
}

export default OwnerRoot