import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

export const HeaderContainer = styled.div`
    position: sticky;
    top: 0px;
    z-index: 200;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 45px;
    font-size: 14px;
    padding: 0.25rem 0;
    //ajouter par mùarzouk 
    background: ${({theme}) => theme.colors.body} 0% 0% no-repeat padding-box;
`;

export const HeaderLinks = styled.div`
    height: 100%;
    width: 500px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-right: 4rem;
`;

export const Hlink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    color: ${({lcolor, theme}) => lcolor || theme.colors.darkText} ;
    padding: 0.5rem;
    
    & span {
        margin-left: 0.25rem;
        white-space: nowrap;
    }
`;

export const HLang = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
`;

export const HTheme = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    color: ${({theme}) => theme.colors.darkText};
    /* color: ${({theme, isActive}) => isActive ? theme.colors.primary : theme.colors.secondary}; */
    cursor: pointer;

    & span {
        color: ${({lcolor, theme}) => lcolor || theme.colors.darkText} ;
        margin-left: 0.25rem;
    }
`;

