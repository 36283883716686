import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png'
import logoB from '../../assets/images/login_background.jpg'
import './Signin.css';
import { Trans, useTranslation } from 'react-i18next';
import LanguageList from '../LanguageList/LanguageList';
import { BtnAction, LogoContainer, TextLink } from '../../styles/Common';
import {Container} from './signin.styled';
const Signin = props => {
    const { t } = useTranslation();

    return (
        <Container img={logoB}>
            <div className="signin__FromWrap">
                <div className="signin__fromContent">
                    <form className="signin__form" action="#">
                        <div className="signin_language_container">
                            <LanguageList />
                        </div>
                        <div className="signin__header">
                            <LogoContainer >
                                <Link to="/" className="signin__Icon">
                                    <img src={logo} alt="logo" />
                                </Link>
                            </LogoContainer>
                        </div>
                        {
                            props.signinError ? 
                                <span className="signin__error"> 
                                    {t("authentication:signin_error", "Login ou mot de passe incorrect")} 
                                </span>
                            : null
                        } 
                        <div className="signin__formGroup">
                            {/* <label className="signin__formLabel" htmlFor='username'>{t("signin:login", "Login")}</label> */}
                            <input 
                                className="signin__formInput" 
                                type="email" 
                                name="email" 
                                placeholder={t("authentication:login", "Login")}
                                onChange={props.changed} 
                                required 
                                autoFocus 
                            />
                        </div>
                        <div className="signin__formGroup">
                            {/* <label className="signin__formLabel" htmlFor='password'>{t("signin:password", "Mot de passe")}</label> */}
                            <input 
                                className="signin__formInput" 
                                type="password" 
                                name="password" 
                                placeholder={t("authentication:password", "Mot de passe")}
                                onChange={props.changed} 
                                required 
                            />
                        </div>
                        <div className="signin__formBtnGroup">
                            <BtnAction
                                noMargin
                                onClick={props.submited}
                            >
                                {t("authentication:signin", "Se connecter")}
                            </BtnAction>
                        </div>
                        <TextLink to="/forgotPassword" >{t("authentication:forgottenPsw")}</TextLink>

                        {/* <TextLink to="/newAccountRequest" >{t("authentication:newAccountRequest")}</TextLink> */}

                        <div className="signin__footer">
                            <p>
                                {/* {t("default:copyright", `© ${new Date().getFullYear()} ICD International`)} */}
                            </p>
                        </div>
                    </form>
                    
                </div>
            </div>
        </Container>
    )
}

export default Signin
