import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FilterContainer, InputFilter, Option, SelectFilter, TD } from '../../../styles/Table.styled';

function TableFilters(props) {
    const {t} = useTranslation();
    const {filters, filtersChange, applyFilters, userType} = props;

    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...filters,
            [key]: e.target.value
        }
        filtersChange(newFilters);
        applyFilters(newFilters);
    }
    
    return (
        <>
            <tr style={{display: props.show ? 'table-row' : 'none'}}>
                <TD ></TD>
                <TD >
                    <FilterContainer>
                        <InputFilter 
                            placeholder={t("invoiceList:first_name", "Prénom")}
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.first_name || ''} 
                            name="first_name" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD >
                    <FilterContainer>
                        <InputFilter 
                            placeholder={t("invoiceList:last_name", "Nom")}
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.last_name || ''} 
                            name="last_name" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD >
                    <FilterContainer>
                        <InputFilter 
                            placeholder={t("invoiceList:login", "Email")}
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.login || ''} 
                            name="login" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD >
                    <FilterContainer>
                        <InputFilter 
                            placeholder={t("invoiceList:telephone_number", "Télephone")}
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.telephone_number || ''} 
                            name="telephone_number" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD>
                    <FilterContainer>
                    <SelectFilter 
                        onChange={(e) => inputChangeHandler(e)} 
                        value={filters?.active || ''} 
                        name="active" 
                    >
                        <Option value=""></Option>
                        <Option value="1">{t('tableHead:yes', 'Oui').toUpperCase()}</Option>
                        <Option value="0">{t('tableHead:no', 'Non').toUpperCase()}</Option>
                    </SelectFilter>
                    </FilterContainer>
                </TD>
                <TD>
                    <FilterContainer>
                    <SelectFilter 
                        onChange={(e) => inputChangeHandler(e)} 
                        value={filters?.type || ''} 
                        name="type" 
                    >
                        <Option value=""></Option>
                        {
                            userType === "owner"  &&
                            <Option value="owner">{t('internal', 'Interne').toUpperCase()}</Option>
                        }
                        {
                            (userType === "client") &&
                                <Option value="client">{t('tableFilters:client', "Client").toUpperCase()} </Option>
                        }
                        {
                            (userType === "supplier") &&
                                <Option value="supplier">{t('tableFilters:supplier', "Fournisseur").toUpperCase()} </Option>
                        }
                    </SelectFilter>
                    </FilterContainer>
                </TD>
                <TD>
                    <FilterContainer>
                    <SelectFilter 
                        onChange={(e) => inputChangeHandler(e)} 
                        value={filters?.level || ''} 
                        name="level" 
                    >
                        <Option value=""></Option>
                        <Option value="admin">{t('admin', "Admin").toUpperCase()}</Option>
                        <Option value="standard">{t('standard', "Standard").toUpperCase()}</Option>
                    </SelectFilter>
                    </FilterContainer>
                </TD>
                <TD >
                    <FilterContainer>
                        <InputFilter 
                            placeholder={t("invoiceList:entities", "Entitiés")}
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.entities || ''} 
                            name="entities" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD></TD>
                
            </tr>
            <tr></tr>
        </>
    )
}

export default TableFilters
