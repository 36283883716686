import React, { Fragment, useState } from 'react'
import DraftsIcon from '@mui/icons-material/Drafts';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import { useTranslation } from 'react-i18next';
import * as actions from '../../../../store/actions/index';
import { connect } from 'react-redux';
import pdfIcon from '../../../../assets/images/pdf.png';
import xmlIcon from '../../../../assets/images/xml.png';
import Axios from '../../../../axios-proas'
import { Link} from 'react-router-dom';
import { CTooltip } from '../../../UI/CTooltip/CTooltip'
import StatusReasonModal from './StatusReasonModal/StatusReasonModal';
import { getNotyfObject, getStatusWithKey, getChannelWithKey,showColumn } from '../../../../shared/utility';
import TextsmsIcon from '@mui/icons-material/Textsms';
import {TD, InvoiceLink, SelectStatus, StatusOption} from '../../../../styles/Table.styled'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

function TableBody(props) {
    const {data, formatDate, setViewed, selectedRows, setSelectedRows, userType, userLevel, editStatus, disableStatus, entityFilter,columns} = props;

    const {t} = useTranslation();
    let notyf = getNotyfObject();
    const [showStatusReasonModal, setShowStatusReasonModal] = useState(false)
    const [invoiceToEdit, setInvoiceToEdit] = useState(null);

    const formatNumber = (number) => {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number).replaceAll('.',' ')
    }

    const getSelectedRowValue = (id) => {
        if (selectedRows) {
            return selectedRows[id] || false
        } else return false
    }

    const selectRowHandler = (e) => {
        let id = e.target.name;
        let val = e.target.checked;
        setSelectedRows(id, val)
    }

    const getTimeDate = (date) => {
        let newDate = new Date(date);
        let hour = newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours()
        let minutes = newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes()
        return hour + ":" + minutes
    }

    const clickedHandler = (e, uid) => {
        e.preventDefault()
        props.getDocumentFile(uid)
    }

    const clickAttachmentHandler = (e, uid) => {
        e.preventDefault()
        Axios.get('/invoice/'+uid+'/attachmentFile', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(response => {
            if(response.status === 200){
                const blob = new Blob([response.data], { type: response.request.getResponseHeader("Content-Type") })
                let filename = "";
                const disposition = response.request.getResponseHeader('Content-Disposition')
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(disposition);
                    if (matches !== null && matches[1])
                    filename = matches[1].replace(/['"]/g, '');
                }
                const downloadUrl = URL.createObjectURL(blob)
                const a = document.createElement("a"); 
                a.href = downloadUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
            } else
                notyf.error("Pièce jointe non trouvée");
        }).catch(err => {
            notyf.error('Une erreur s\'est produite !')
        })
    }

    const inputChangeStatus=(status, invoiceUid)=>{
        if(status && !disableStatus){
            if(status === "LITIGATION"){
                setInvoiceToEdit(invoiceUid)
                setShowStatusReasonModal(true);
            } else 
                editStatus(invoiceUid, status)
         }
    }
   
    const markAsRead = ( invoiceUid, firstAccessDate = null) => {
        if(!firstAccessDate){
            Axios.put('/invoice/'+invoiceUid+'/setInvoiceAsViewed', 
                  {
            }).then(response => {
                notyf.success("Facture marquée comme lue")
            }).catch(err => {
                notyf.error("Une erreur s'est produite")
            })
        }
    }

    return (
        <>           
            {
                data.map(row => (
                    <tr key={row.id}>
                        <TD style={{textAlign:'center'}} >
                            <input type="checkbox" name={row.uid} onChange={(e) => selectRowHandler(e)} checked={getSelectedRowValue(row.uid)}  className="form-check-input" style={{margin: 0}} id="exampleCheck1" />
                        </TD>
                        <TD style={{display: !showColumn(columns ,"type") && 'none'}} >
                            {t("invoiceList:" + (row.documentType === "INV" ? "invoice" : (row.documentType === "CRN" ? "creditNote" : "")))}
                        </TD>
                        <TD style={{display: !showColumn(columns,"number") && 'none'}}>
                            <InvoiceLink 
                                to={{
                                    pathname: "/invoiceDetail/"+row.uid,
                                    state: {
                                        entityFilter
                                    }
                                }}
                                status={row?.status}
                            >
                                <span>{row.number}</span>
                            </InvoiceLink> 
                        </TD>
                        {entityFilter === "client" ?
                         <TD style={{display: !showColumn(columns ,"firstAccessDate") && 'none'}}>
                            {+row.firstAccessDate > 0 ? 
                                <CTooltip title={formatDate(+row.firstAccessDate)+" "+ getTimeDate(+row.firstAccessDate)} className="tableActions__icon" style={{color: '#022f67', fontSize: "20px"}}>
                                    <DraftsIcon />
                                </CTooltip> : 
                                <MarkunreadIcon className="tableActions__icon" style={{color: "#ffa618", fontSize: "20px"}} />} 
                        </TD> :
                        (entityFilter === "supplier" &&
                         <TD style={{display: !showColumn(columns,"attachmentFile") && 'none'}}>
                             {row.attachmentFile && <a download={row.attachmentFile} href="#" onClick={(e) => clickAttachmentHandler(e, row.uid)} style={{color: "#ea5e0b"}}>{row.attachmentFile}</a>}
                        </TD>)}
                        <TD style={{display: !showColumn(columns ,"creationDate")&& 'none'}}>{formatDate(+row.creationDate)}</TD>
                        <TD style={{display: !showColumn(columns ,"dueDate") && 'none'}}>{formatDate(+row.dueDate)}</TD>
                        {userType==='client' ? (
                            <TD style={{display: !showColumn(columns ,"supplierName") && 'none'}} title={row.supplierName}>{row.supplierName}</TD>
                            ):null}
                        {userType==='supplier' ? (
                            <TD style={{display: !showColumn(columns ,"clientName") && 'none'}}title={row.clientName}>{row.clientName}</TD>
                            ):null}
                        {userType==='owner' ? (
                            <Fragment>
                                <TD style={{display: !showColumn(columns ,"supplierName") && 'none'}}title={row.supplierName}>{row.supplierName}</TD>
                                <TD style={{display: !showColumn(columns ,"clientName") && 'none'}} title={row.clientName}>{row.clientName}</TD>
                            </Fragment>
                            ):null}
                        <TD style={{display: !showColumn(columns ,"status") && 'none'}} >
                            {userType === "supplier" ? getStatusWithKey(row?.status) :
                                <SelectStatus 
                                    value={row?.status} 
                                    status={row?.status}
                                    onChange={(e) => inputChangeStatus(e.target.value,row?.uid)}  
                                    name="status"
                                    disabled={disableStatus}
                                >
                                    <StatusOption value={row?.status} >{getStatusWithKey(row?.status)}</StatusOption>
                                    {row?.status!=="NEW"&&       <StatusOption   value="NEW">En cours</StatusOption>}
                                    {row?.status!=="TO_BE_PAID"&&<StatusOption   value="TO_BE_PAID">A payer</StatusOption>}
                                    {row?.status!=="LITIGATION"&&<StatusOption   value="LITIGATION">Litige</StatusOption>}
                                    {row?.status!=="PAID"&&      <StatusOption  value="PAID">Payée</StatusOption>}                       
                                </SelectStatus>
                            }
                        </TD>
                        {entityFilter === "supplier" && <>
                            <TD style={{display: !showColumn(columns ,"channel")&& 'none'}} >{getChannelWithKey(row?.channel)}</TD>
                        </>}
                        
                        <TD style={{display: !showColumn(columns ,"exclusiveTaxAmount")&& 'none'}}>{formatNumber(row.exclusiveTaxAmount)}</TD>
                        <TD style={{display: !showColumn(columns ,"inclusiveTaxAmount") && 'none'}}>{formatNumber(row.inclusiveTaxAmount)}</TD>
                        <TD style={{display: !showColumn(columns ,"currency") && 'none'}}>{row.currency}</TD>
                        <TD>
                            {
                                row.mimeType === "application/pdf" ?
                                    <PictureAsPdfIcon onClick={(e) => clickedHandler(e, row.uid)} style={{fontSize: "1.2rem", cursor: "pointer", color: "#2174B9"}} /> 
                                    :
                                    <img 
                                        src={xmlIcon} 
                                        style={{cursor: "pointer", height:"36px"}} 
                                        onClick={(e) => clickedHandler(e, row.uid)}
                                    />
                            }
                            
                        </TD>
                    </tr>
                ))
                
            }
            {
                showStatusReasonModal && 
                    <StatusReasonModal
                        show={showStatusReasonModal}
                        handleClose={() => setShowStatusReasonModal(false)}
                        invoiceToEdit={invoiceToEdit}
                        editStatus={editStatus}
                    />
            }
        </>
    )
}
const mapStateToProps = (state) => ({
})
const mapDispatchToProps = dispatch => ({
    getDocumentFile: (uid) => dispatch(actions.invoiceGetDocumentFile(uid))
})
export default connect(mapStateToProps, mapDispatchToProps) (TableBody)
