import styled from 'styled-components';

export const ExportWrap = styled.div`
    /* width: 100%; */
    /* height: 50px; */
    position: relative;
    margin-left: 0.5rem;
`;

export const ExportContainer = styled.div`
    display: flex;
    align-items: center;
    /* height: 100%; */
    width: 150px;
    justify-content: space-between;
    padding: 10px 30px;
    border: 2px solid ${({ theme }) => theme.colors.primary};
    border-radius: 30px;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
`

export const ExportValue = styled.span`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.primary};
    margin: 0;
`

export const ExportIcon = styled.div`
    display: flex;
    /* margin-left: 0.5rem; */
`;

export const ExportOptions = styled.ul`
    display: flex;
    width: 100%;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
    border: 2px solid ${({ theme }) => theme.colors.primary};
    border-radius: 16px;
    font-size: 00.9rem;
    background-color: white;
    position: absolute;
    z-index: 100;
`

export const ExportOption = styled.li`
    padding: 0.25rem 0.5rem;
    border-radius: 16px;
    margin: 0;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};

    &:hover {
        background-color: ${({ theme }) => theme.buttons.outlinePrimaryHover};
    }
`