import styled from 'styled-components/macro';

export const SelectWrap = styled.div`
    /* width: 100%; */
    height: 100%;
`;

export const SelectContainer = styled.div`
    display: flex;
    align-items: center;
    /* height: 100%; */
    width: 150px;
    justify-content: space-between;
    padding: 10px 30px;
    font-size: 14px;
    border: 2px solid ${({ theme }) => theme.colors.primary};
    border-radius: 30px;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
`

export const SelectValue = styled.h4`
    font-size: 0.9rem;
    /* color: ${({theme}) => theme.colors.text}; */
    white-space: nowrap;
    margin: 0;
`

export const SelectIcon = styled.div`
    display: flex;
    /* margin-left: 0.5rem; */
`;

export const SelectOptions = styled.ul`
    display: flex;
    width: 100%;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
    border: 2px solid ${({ theme }) => theme.colors.primary};
    border-radius: 16px;
    font-size: 00.9rem;
    background-color: ${({theme}) => theme.colors.content};
    position: relative;
    z-index: 100;
`

export const SelectOption = styled.li`
    padding: 0.25rem 0.5rem;
    border-radius: 16px;
    margin: 0;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};

    &:hover {
        background-color: ${({ theme }) => theme.buttons.outlinePrimaryHover};
    }
`