import styled from 'styled-components/macro';

export const ETContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const ETBody = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 1rem;
`

export const ETContent = styled.div`
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
`
export const ETSectionForm = styled.div`
    width: calc(100% - 18px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1.5rem 1rem 0;

`

export const ETSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-bottom: 20px;

    &:first-child ${ETSectionForm} {
        border-right: 4px solid #F5FBFF;
    }
`

export const ETSectionTitle = styled.h3`
    width: calc(100% - 40px);
    background-color: #F5FBFF;
    color: ${({theme}) => theme.colors.darkText};
    font-size: 1rem;
    font-weight: bold;
    padding: 0 0 0.5rem 0.5rem;
    border-bottom: 1px solid ${({theme}) => theme.colors.primary};
    margin-bottom: 1rem;
`


export const ETRadiosContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex: 1;
`

export const FileFormWrapper = styled.div`
    display: flex;
    align-items: flex-end;
`

export const FileName = styled.p`
    color: ${({theme}) => theme.colors.secondary};
    max-width: 100px;
    font-weight: bold;
    padding: 0;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0.5rem;
`

export const ETImgContainer = styled.div`
    width: 100%;
    height: 100%;
    max-height: 182px;
    padding: 1rem;
    border: 1px solid ${({theme}) => theme.colors.primary};
    border-radius: 5px;
    margin-bottom: 1rem;

    img {
        width: 100%;
        max-height: 150px;
        object-fit: contain;
    }
`

export const FormTextEditorWrapper = styled.div`
    width: 100%;
    height: 350px;
    max-height: 450px;
    border: 1px solid ${({theme}) => theme.colors.primary};
    border-radius: 5px;
    padding: 0.5rem;
    margin-top: 0.5rem;
    /* overflow: hidden; */
`

export const FormInputError = styled.p`
    position: relative;
    top: -1rem;
    color: ${({theme}) => theme.colors.danger};
    font-size: 0.7rem;
    margin: 0;
    padding: 0;
`;