import styled from 'styled-components';

export const SIContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const SIBody = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 1rem;
`

export const SITableContainer = styled.div`
    width: 100%;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
`
