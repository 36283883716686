import React from 'react';
import styled from 'styled-components/macro';

const UploadButton = styled.button`
    color: ${props => props.theme.colors.primary};
    border: 1px solid ${props => props.theme.colors.primary};
    padding: 0.125rem 1rem;
    border-radius: 5px;
    background-color: white;
    font-size: 0.9rem;
    display: flex;
    font-weight: bold;
    height: 38px;
    margin-top: 10px;
    align-items: center;
    
    &:hover {
        background-color: ${props => props.theme.colors.primary};
        color: #ffffff;
    }
`;

const FileUploaderBtn = ({btnLabel, name, handleChange, icon }) => {
  const hiddenFileInput = React.useRef(null);
  
  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  return (
    <>
      <UploadButton onClick={handleClick}>
        <span>
            {btnLabel || "Télécharger"}
        </span>
        {icon || null}
      </UploadButton>
      <input 
        type="file"
        ref={hiddenFileInput}
        onChange={(e) => handleChange(e)}
        style={{display:'none'}} 
        name={name}
      /> 
    </>
  );
};
export default FileUploaderBtn;