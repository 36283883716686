import styled from 'styled-components';

export const DocsTabContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

export const Notyfs = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 1rem 0rem;
`;

export const SuccessNotyf = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0.5rem;
    border-radius: 18px;
    background-color: ${({theme}) => theme.colors.greenLight};
    color: ${({theme}) => theme.colors.success};
    margin-right: 0.5rem;
    font-size: 0.7rem;

    & span {
        margin-left: 0.25rem;
        font-weight: 500;
    }
`;

export const WarningNotyf = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0.5rem;
    border-radius: 18px;
    background-color: ${({theme}) => theme.colors.warning};
    color: black;
    margin-right: 0.5rem;
    font-size: 0.7rem;
   
    & span {
        margin-left: 0.25rem;
        font-weight: 500;
    }
`;
export const DangerNotyf = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0.5rem;
    border-radius: 18px;
    background-color: ${({theme}) => theme.colors.redLight};
    color: ${({theme}) => theme.colors.danger};
    margin-right: 0.5rem;
    font-size: 0.7rem;

    & span {
        margin-left: 0.25rem;
        font-weight: 500;
    }
`;

export const DocsTable = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`






