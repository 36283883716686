import styled from 'styled-components';

export const IPSContainer = styled.div`
    width : ${({isSupplier}) => isSupplier ? '39%' : '49%'};
    /* width: $ 39%; */
    /* height: 400px; */
    border: 1px solid #D9E1E7CC;
    border-radius: 16px;
    padding: 0.5rem;
    margin-bottom: 0.5rem;

    & canvas {
        max-width: 100% !important;
        max-height: 80% !important;
    }

    @media (min-width: ${({theme}) => theme.size.tablet}) and (max-width: ${({theme}) => theme.size.laptop}) {
        width: 100%;
    }
`

export const IPSTitle = styled.div`
    width: 100%;
    /* height: 10%; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    /* padding: 0.5rem 0 1rem 0; */
    border-bottom: 1px solid #D9E1E7CC;
    color: ${({theme}) => theme.colors.secondary};

    & span {
        font-size: 1.3rem;
        font-weight: bold;
        padding-bottom: 0.5rem;
    }
`