import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { SelectWrap, SelectContainer, SelectValue, SelectIcon, SelectOptions, SelectOption } from './Select.styled';

function Select({value, onChange, options}) {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = (newValue) => {
        setIsOpen(false);
        onChange(newValue);
    }

  return (
      <SelectWrap>
          <SelectContainer onClick={() => setIsOpen(!isOpen)}>
              <SelectValue>
                    {value}
              </SelectValue>
              <SelectIcon>
                  {
                        isOpen ? <KeyboardArrowUpIcon  /> 
                        : <KeyboardArrowDownIcon  />
                  }
              </SelectIcon>
          </SelectContainer>
          {
                isOpen && (
                    <SelectOptions>
                            {
                                options.map((item, index) => (
                                    <SelectOption
                                        key={index}
                                        onClick={() => handleClick(item.value)}
                                    >
                                        {item.label}
                                    </SelectOption>
                                )
                            )}
                    </SelectOptions>
                )
          }
      </SelectWrap>
  );
}

export default Select;
