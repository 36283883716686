import React, { useEffect, useMemo, useState } from 'react'
import { Pie } from 'react-chartjs-2'
import { useTheme } from 'styled-components';
import Axios from '../../../axios-proas';
import { getNotyfObject, getStatusWithKey } from '../../../shared/utility';
import { IPCContainer, IPCTitle } from './InvoicesPerCanal.styled';

function InvoicesPerCanal({period, userType}) {
    const [data, setData] = useState([])
    const [labels, setLabels] = useState([])
    const notyf = getNotyfObject()
    const readableStatus = {PORTAL : "Portail", PAPER: "Papier", EDI: "EDI", EMAIL : 'Email'}

    useEffect ( () =>{
        Axios.get("/invoice//supplierInvoicesPerChannel", {
            params: {
                period : period
            }
        }).then((response) => {
            setData(response.data.map(d => d.counter));
            setLabels(response.data.map(d => readableStatus[d.channel]));
        }).catch(res => {
            notyf.error("Une erreur s'est produite!");
        })

    }, [period])

   
    const chartData ={
        labels: labels,
        datasets: [
            {    
                backgroundColor: [
                    "#CDE371",
                    "#61CFE9",
                    "#4CC85E",//"#CDE371",
                    "#FF5E6D",
                    "#82E0AA"
                ],
                data: data
            }
            ,{
                backgroundColor:["yellow"]
            }
        ],
        series:[{
            data: data
        }]
        // These labels appear in the legend and in the tooltips when hovering different arcs
        
    }

    const options = {
        //maintainAspectRatio: false,
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }
        },
        title: {
            display: false,
            text: 'Factures par canal',
            fontSize: 35,
            fontColor: "#566573",
            padding:30
        },
        legend: {
            position: "right",
            labels: {
                fontColor: '#566573',
                fontSize: 15,
                padding: 10
            }
        },
        tooltips: {
            backgroundColor: "#5a6e7f"
        }
    }

  return (
    <IPCContainer isSupplier={userType === 'supplier'}>
        <IPCTitle><span>Factures par canal</span></IPCTitle>
        <Pie data={chartData} options={options} datasetKeyProvider={() => btoa(Math.random()).substring(0,12)} />
    </IPCContainer>
  )
}

export default InvoicesPerCanal