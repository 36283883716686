import React, {useState, useRef, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import './EntitiesDropDown.css';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Axios from '../../../axios-proas'
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

function EntitiesDropDown({addEntity,setSelectedEntities , selectedEntities,deleteOwnerclickHandler}) {
    const {t} = useTranslation();
    const dropdownRef = useRef();
    const [showDropdown, setShowDropdown] = useState(false);
    const [entities , setEntities] = useState([]);
    const [searchQuery , setSearchQuery] = useState(null);


    useEffect(() => {
        let filter = {
            searchQuery: searchQuery,
            type : "owner"
        }
        Axios.get('/client//modalEntities', {
            params: filter
        })
        .then(res => {
            setEntities(res.data?.result)
        })
    }, [searchQuery])



    const clickOutSideHandler = e => {
        if(dropdownRef.current.contains(e.target)) {
            // inside the div
            return
        }
        // outside click
        closeEntitesDropDown(e)
    }
   const closeEntitesDropDown = e=>{
    addEntity(selectedEntities)
    setShowDropdown(false)

   }
const deleteEntity=(entity)=>{
    let selectedEntitiesTemp=[...selectedEntities]
    let entityIndex=selectedEntitiesTemp.findIndex(ent => ent.uid === entity.uid);
    if(entityIndex >=0 ){
        selectedEntitiesTemp.splice(entityIndex, 1)
    }
    setSelectedEntities(selectedEntitiesTemp)
    deleteOwnerclickHandler(entity)
}
  
    // useEffect(() => {
    //     document.addEventListener("mousedown", clickOutSideHandler);

    //     return () => {
    //         document.removeEventListener("mousedown", clickOutSideHandler);
    //     }
    // }, [])

    const SelectEntity = (entity) => {
        console.log(selectedEntities)
        let entityIndex = selectedEntities.findIndex(row => row.uid === entity.uid);
        let selectedTemp = [...selectedEntities];
        if(entityIndex >= 0)
            selectedTemp.splice(entityIndex,1)
        else 
            selectedTemp = [...selectedEntities, entity];
        setSelectedEntities(selectedTemp);
       
    }

  return <>
    <div className="entitiesDropDown__container">


        <label className="entitiesDropDown__label">
            {t("entitiesDropDown:IcdEntities", "Entités ICD")}    
        </label>
        <div className="accountRequest__entitiesList">
                    {
                            selectedEntities.length  > 0 ? selectedEntities?.map(entity => (
                        <div key={entity.id} className="accountRequest__entitiesListItem">
                            <div className="accountRequest__entitiesListItem_label">
                            {entity.name}
                            </div>
                            <div className="accountRequest__entitiesListItem_icon">
                                <CloseIcon onClick={() => deleteEntity(entity)}
                                    style={{fontSize: "0.7rem"}}
                                />
                            </div>
                        </div>
                       
                            )):null
                    }
                     
                      
                      
            
                    </div> 
        <div 
            className="entitiesDropDown__addBtn"
            onClick={() => setShowDropdown(true)}   
            style={{display: showDropdown ? "none" : "flex"}} 
        >
            <div className="entitiesDropDown__addBtn__label">
                {t("entitiesDropDown:addEntity", "Ajouter une entité")}
            </div>
            <div className="entitiesDropDown__addBtn__icon">
                <AddIcon 
                    style={{fontSize: "0.7rem"}}
                />
            </div>
        </div>

        <div 
            ref={dropdownRef} 
            className="entitiesDropDown__wrap" 
            style={{display: showDropdown ? "block" : "none"}}
        >
            <div className="entitiesDropDown__search">
                <input
                    className="entitiesDropDown__searchInput"
                    type="text"
                    name="adminContact"
                    placeholder={t("default:search", "Rechercher")}
                    onChange={(e) => setSearchQuery(e.target.value)} 
                />
                <div className="entitiesDropDown__search__closeIcon">
                    <CloseIcon  onClick={() => closeEntitesDropDown()}
                        style={{fontSize: "0.8rem"}  }
                    />
                </div>
            </div>
            <div className="entitiesDropDown__content">
            <table className="table entityUserModal__table">
            <thead className="thead-light" >
                                <tr>
                                <th scope="col" style={{width: "50px"}} > </th>
                                <th scope="col" className="entitiesDropDown__content__item__code">Raison sociale </th>
                                <th scope="col" className="entitiesDropDown__content__item__code">code</th>
                               
                                </tr>
                            </thead>
                            <tbody>
                       
                        {
                            entities?.length > 0 ? entities.map(entity => (
                                <tr>
                                <td style={{width: "50px"}}>
                                    <div className="productsModal__itemIcon" onClick={() => SelectEntity({uid : entity.uid, name : entity.name, new : true})}>
                                    {
                                        selectedEntities.some(row => row.uid === entity.uid) ? (
                                            <ThumbUpIcon style={{cursor: "pointer", color: "green"}} />
                                            ) : <AddIcon style={{cursor: "pointer", color: "blue"}} />
                                    }
                                    </div>
                                </td>
                          
                                <td>
                                        {entity.name}
                                   

                                </td>
                                
                                <td>
                                    <div className="entitiesDropDown__content__item__code">
                                    {entity.code}
                                    </div>
                                </td>
                                
                           
                            </tr>
                            )):null
                          }
                         </tbody>
                        
            </table>
        </div>
     </div>
        
    </div>
  </>;
}

export default EntitiesDropDown;
