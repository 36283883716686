import React, { useMemo,useState } from 'react';
import { formatDate, getNotyfObject } from '../../../shared/utility';
import DateProgressLine from '../DateProgressLine/DateProgressLine';
import StatusProgressLine from '../StatusProgressLine/StatusProgressLine';
import { CIActions, CIContainer, CIContent, CIData, CIDeleteBtn, CITitle, CIUsersLink } from './CampaignItem.styled';
import GroupIcon from '@mui/icons-material/Group';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ConfirmationModal from '../../UI/ConfirmationModal/ConfirmationModal'
import Axios from '../../../axios-proas'
import { BtnAction, BtnOutlineLink, BtnSmOutlineAction, BtnSmOutlineLink } from '../../../styles/Common';
import { useTheme } from 'styled-components';
import { t } from 'i18next';
function CampaignItem({campaign,getCampaigns}) {
    const monthList = useMemo(() => ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"], [])
     const [confirmationModalShow, setConfirmationModalShow] = useState(false);
     const notyf = getNotyfObject();
     const theme = useTheme();

     const deleteCompagneClickHandler = (Uid) => {
        
        Axios.delete(`campaign/${Uid}/deleteCompagne`).then(response => {
            getCampaigns()
           console.log(Uid)
           setConfirmationModalShow(false)
            notyf.success("La compagne a bien été supprimé")
        }).catch(response => {
           
            notyf.error("generatedErrorMessage")
        })
    }
    return (
        <>
        <CIContainer>
            <CITitle>
                {campaign?.title}
            </CITitle>
            <CIContent>
                <CIData>
                        <DateProgressLine
                            creationDate={+campaign?.creationDate}
                            campaignDate={+campaign?.campaignDate}
                            endDate={+campaign?.endDate}
                        />
                        <StatusProgressLine
                            status={campaign?.status}
                        />
                </CIData>
                <CIActions>
                    <CIUsersLink 
                        to={`#`}  
                    >
                            {campaign?.recipients?.split(',').length} 
                        <GroupIcon style={{fontSize: "1.2rem", marginLeft: "2px"}} />
                    </CIUsersLink>

                    <BtnSmOutlineAction 
                        color={theme.colors.danger}  
                        hover={theme.colors.redLight}
                        onClick={() => setConfirmationModalShow(true)}
                    >
                       {t("btn:Supprimer")} 
                    </BtnSmOutlineAction>

                    <BtnSmOutlineLink
                        to={"/newCampaign/"+campaign?.uid}
                    >
                       {t("btn:Voir")}  <ArrowRightIcon/>
                    </BtnSmOutlineLink>
                    
                   
                      
                </CIActions>
            </CIContent>
           
        </CIContainer>
         <ConfirmationModal 
         show={confirmationModalShow}
         modalClosed={() => setConfirmationModalShow(false)}
         confirm={()=>deleteCompagneClickHandler(campaign?.uid)}
         cancel={() => setConfirmationModalShow(false)}
         title={"Confirmation de suppression"}
         message={`Êtes-vous sûr de vouloir supprimer cette compagne ?`}
/>
</>
    );
}

export default CampaignItem;
