import React from 'react'
import StatHeader from '../../components/ClientHome/StatHeader/StatHeader';
import {HomeContainer, HomeHeader, HeaderInfos, HeaderLocation, HeaderTitle, HeaderActions, HeaderAction, SearchInputContainer, SearchInput, ActionWrapper, ActionLabel, HomeBody, HomeCards, HomeStats, HomeInvoices} from '../Home/Home.styled'
import BarChart from '../../components/ClientHome/StatBody/Charts/BarChart'
import { connect } from 'react-redux';
import Events from '../../components/Home/Events/Events';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { useTranslation } from 'react-i18next';


function ClientHome({userType}) {

    const {t} = useTranslation();

    return (
        <>
        <HomeContainer>
            <TitleHelmet
                title={
                "Streamline for Invoices | " + t('navbar:Pilotage')
                }
            />
            <HomeHeader>
                <HeaderInfos>
                    <HeaderLocation>
                        {userType === 'owner' ? t('navbar:Pilotage') : t('menu:home')  }
                    </HeaderLocation>
                    {/* <HeaderTitle>
                        {userType === 'owner' ? '' : 'Portail'}
                    </HeaderTitle> */}
                </HeaderInfos>
                <HeaderActions>
                    <HeaderAction>
                        {/* <Select 
                            options={options} 
                            value={options.find(option => option.value === value)?.label} 
                            onChange={selectChange} 
                        /> */}
                    </HeaderAction>
                </HeaderActions>
            </HomeHeader>
            <HomeBody>
                <HomeCards>
                      <StatHeader userType={userType} />
                </HomeCards>
                <HomeStats style={{height:"380px"}}>
                     <BarChart userType={userType}/>
                     {
                         userType === 'client' && 
                            <Events />
                     }
                </HomeStats>

            
            </HomeBody>
        </HomeContainer>
    </>
    )
}

const mapStateToProps = (state) => ({
    userType : state.auth.userType,
    login : state.auth.login
})

const mapDispatchToProps = dispatch => ({
})
export default connect(mapStateToProps, mapDispatchToProps) (ClientHome)
