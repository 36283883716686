import React, { useEffect } from 'react';
import { HeaderContainer, HeaderLinks, HLang, Hlink, HTheme,Helement } from './HeaderNavbarSmall.styled';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import LanguageList from '../LanguageList/LanguageList';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { useTheme } from 'styled-components';
import { CTooltip } from '../UI/CTooltip/CTooltip'

function HeaderNavbarSmall(props) {
    const {logout, login, setAppTheme, currentTheme} = props;
    const {t} = useTranslation();
    const theme = useTheme();

    const toggleTheme = () => {
        if(currentTheme === 'light') {
            setAppTheme('dark');
        } else {
            setAppTheme('light');
        }
    }

  return (
    <>
        <HeaderContainer>
         {/* <HLang>
                <LanguageList />
        </HLang> */}
            <HeaderLinks>
                <CTooltip title={t("global:changePwd")} placement="right">
                    <Helement>
                        <Hlink to="/editPassword" >
                            <LockOutlinedIcon style={{fontSize: "1.8rem"}} />
                            {/* <span>{t("global:changePwd")}</span> */}
                        </Hlink>
                    </Helement>
                </CTooltip>
                <CTooltip title={t("global:logout")} placement="right"> 
                    <Helement>   
                        <Hlink 
                            to={{pathname: "/"}}
                            // lcolor={theme.colors.danger}
                            onClick={() => logout()}
                        >
                            <LogoutIcon style={{fontSize: "1.8rem"}}/>
                            {/* <span>{t("global:logout")}</span> */}
                        </Hlink>
                    </Helement>
                </CTooltip>
                  
                <div style={{  borderTop: "1px solid #E3E6E6",width: "100%", marginTop: "10px"}}>
                    <CTooltip title={login} placement="right"> 
                        <Helement>
                            <Hlink to="#" >
                                <PersonOutlineOutlinedIcon style={{fontSize: "1.8rem"}} />
                                {/* <span>{login}</span> */}
                            </Hlink>
                        </Helement>
                    </CTooltip>
                </div>
            </HeaderLinks>
            {/* <HTheme onClick={() => toggleTheme()} isActive={currentTheme === "dark"} >
                {
                    currentTheme === "dark" ?
                        <ToggleOnIcon />
                    :
                        <ToggleOffIcon />
                }
                <span>Mode nuit</span>
            </HTheme> */}
          
        </HeaderContainer>
    </>
  );
}

const mapStateToProps = state => {
    return {
        currentTheme: state.app.theme,
        isAuthenticated : state.auth.token !== null,
        login : state.auth.login
    }
} 

const mapDispatchToProps = dispatch => {
    return {
        setAppTheme: (theme) => dispatch(actions.appSetTheme(theme)),
        onAuth : (email, password) => dispatch(actions.auth(email, password)),
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/')),
        logout: () => dispatch(actions.logout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNavbarSmall);
