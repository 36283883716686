import styled from 'styled-components/macro';
import { Link} from 'react-router-dom';

export const Table = styled.table`
    width: 100%;
    /* height: 100%; */
    table-layout: fixed;

    @media (min-width: ${({theme}) => theme.size.tablet}) and (max-width: ${({theme}) => theme.size.laptop}) {
        width: auto;
    }
`;

export const TD = styled.td`
    padding: 0.25rem;

    & > span {
        display: block;
        width: 100%;
        overflow: hidden; 
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`

export const TBody = styled.tbody`
    width: 100%;
    font-size: 0.9rem;
    color: ${({theme}) => theme.colors.text};

    tr {
        border-bottom: 0.0rem solid white;
    }

    & ${TD} {
        width: 100%;
        vertical-align: middle;
        height: ${({height}) => height ? height : '100%'};
    }
`;

export const SpinnerContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
`;

export const NoContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: ${({theme}) => theme.colors.secondary};
    margin: 1rem 0;
`

export const TAContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
`;

export const TableAction = styled.div`
    display: flex;
    justify-content: space-between ;
    align-items: center;
    /* height: 50px; */
    margin-left: 1rem;
    padding: 10px 30px;
    border: 2px solid ${({color, theme}) => color ? color : theme.colors.primary};
    border-radius: 30px;
    color: ${({color, theme}) => color ? color : theme.colors.primary};
    cursor: pointer;
    font-size: 14px;
    background-color: ${({active, theme}) => active ? theme.colors.outlinePrimaryHover : '#FFFFFF'};

    & span {
        color: ${({color, theme}) => color ? color : theme.colors.primary};
        /* margin: 0 0.5rem 0 0; */
    }

    &:hover {
        background-color: ${({theme, hover}) => hover ? hover : theme.buttons.outlinePrimaryHover};
    }
`;

export const TableActionLink = styled(Link)`
    display: flex;
    justify-content: space-between ;
    align-items: center;
    /* height: 50px; */
    margin-left: 1rem;
    padding: 10px 30px;
    border: 2px solid ${({color, theme}) => color ? color : theme.colors.primary};
    text-decoration: none;
    border-radius: 30px;
    color: ${({color, theme}) => color || "#FFFFFF"};
    cursor: pointer;
    font-size: 14px;
    background-color: ${({bg, theme}) => bg ? bg : theme.colors.primary};

    & span {
        color: ${({color, theme}) => color || "#FFFFFF"};
        /* margin: 0 0.5rem 0 0; */
    }

    & svg {
        color: ${({color, theme}) => color || "#FFFFFF"};
    }

    &:hover {
        background-color: ${({theme}) => theme.buttons.primaryHover};
    }
`;

export const THead = styled.thead`
    width: 100%;
    color: ${({theme}) => theme.colors.secondary};
    font-size: 0.9rem;
    background-color: #FFFFFF;
`

export const TH = styled.th`
    width: ${({width}) => width || 'auto'};
    padding-bottom: 0 0.25rem 1rem 0.25rem;
`

export const THContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-weight: bold;

    & > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: middle;
    }
` 

export const FilterContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    padding-right: 0.5rem;
`


export const SelectFilter = styled.select`
    width: 100%;
    height: 100%;
    border: none;
    border-bottom: 2px solid ${({theme}) => theme.colors.secondary};
    outline: none;
    color: black;
    font-size: 0.9rem;
    background-color: #FFFFFF;
    cursor: pointer;

    &:hover {
        border-bottom: 2px solid ${({theme}) => theme.colors.primary};
    }
`

export const Option = styled.option`
    width: 100%;
`

export const InputFilter = styled.input`
    width: 100%;
    height: 100%;
    border: none;
    border-bottom: 2px solid ${({theme}) => theme.colors.secondary};
    outline: none;
    color: black;
    font-size: 0.9rem;
    background-color: #FFFFFF;

    &:hover {
        border-bottom: 2px solid ${({theme}) => theme.colors.primary};
    }

    &:focus {
        border-bottom: 2px solid ${({theme}) => theme.colors.primary};
    }
`

export const InvoiceLink = styled(Link)`
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background-color: ${({status, theme}) => theme.status.background[status] || '#FFFFFF'};
    color: ${({status, theme}) => theme.status.text[status] || 'black'};
    border-radius: 8px;

    & > span {
        width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`

export const SelectStatus = styled.select`
    width: 90%;
    height: 80%;
    border: none;
    background-color: ${({status, theme}) => theme.status.background[status] || '#FFFFFF'};
    color: ${({status, theme}) => theme.status.text[status] || 'black'};
    border-radius: 8px;
    cursor: pointer;
    outline: none;
`
export const StatusOption = styled.option`
    background-color: #FFFFFF;
    color: ${({value, theme}) => theme.status.text[value] || 'black'};
`

export const SearchInputContainer = styled.div`
    display: flex;
    align-items: center;
    /* height: 50px; */
    padding: 10px 30px;
    border: 2px solid ${({color, theme}) => color ? color : theme.colors.primary};
    border-radius: 30px;
    color: ${({color, theme}) => color ? color : theme.colors.primary};
    margin-right: 1rem;

    &:focus-within {
        border: 2px solid ${({theme}) => theme.buttons.primaryHover};
        color: ${({ theme}) => theme.buttons.primaryHover};
    }
`

export const SearchInput = styled.input`
    border: none;
    outline: none;
    color: ${({theme}) => theme.colors.text};
    background-color: ${({theme}) => theme.colors.content};
    font-size: 14px;
    padding: 0;

    &:focus{
        outline: none;
    }

    &::placeholder {
        color: ${({theme}) => theme.colors.text};
    }
`

export const ELDItemsContainer = styled.div`
    /* visibility: hidden; */
    width: 100%;
    display: none;
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    z-index: 1000;
    background-color: white;
    max-height: 150px;
    /* overflow: auto; */
    border: 2px solid #D9E1E7;
    border-radius: 8px;
    padding: 0.25rem;

    &:before {
        content: "";
        position: absolute;
        top: -16px;
        left: 10px;
        transform: translateX(-50%);
        border: 7px solid;
        /* border-color: transparent transparent #706E6B transparent; */
        border-color: transparent transparent #D9E1E7 transparent;
    }
`

export const ELDItemsListContainer = styled.div`
    width: 100%;
    max-height: 140px;
    overflow: auto;
`

export const ELDItems = styled.ul`
    width: 100%;
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    padding: 0;
    margin: 0;
    list-style: none;
    /* max-height: 140px; */
    overflow: auto;
`

export const EntitiesListDetail = styled.div`
    position: relative;
    width: 100%;

    &:hover ${ELDItemsContainer} {
        display: block;
    }
`

export const ELDValue = styled.div`
    width: 100%;
    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap;
`

export const ELDVal = styled.li`
    width: 100%;
    padding: 0.25rem 0.5rem;
    font-size: 0.9rem;
    background-color: #FFFFFF;
    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap;
`
