import React, { useCallback, useEffect, useState } from 'react'
import './RepertoireModal.css'
import SearchIcon from '@mui/icons-material/Search';
import Axios from '../../../axios-proas'
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { useTranslation } from 'react-i18next';
import EntitiesTable from './EntitiesTable/EntitiesTable';
import { BtnAction, BtnOutlineAction } from '../../../styles/Common';
import { useTheme } from 'styled-components';

function RepertoireModal({handleClose, show, addRecipientsHandler}) {
    const [entities , setEntities] = useState([]);
    const [entitiesType, setEntitiesType] = useState('client');
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [searchQuery, setSearchQuery] = useState(null)
    const [allClients, setAllClients] = useState(false);
    const [allSuppliers, setAllSuppliers] = useState(false);
    const {t} = useTranslation();
    const theme = useTheme();

    const getEntitiesList = useCallback(() => {
        let filter = {
            searchQuery: searchQuery,
            type : entitiesType
        }
        Axios.get('/client//modalEntities', {
            params: filter
        })
        .then(res => {
            setEntities(res.data?.result)
        })
    }, [searchQuery, entitiesType])

    useEffect(() => {
        getEntitiesList()
    }, [searchQuery, entitiesType])
    

    const selectEntity = (entity) => {
        let entityIndex = selectedEntities.findIndex(row => row.uid === entity.uid);
        let selectedTemp = [...selectedEntities];
        if(entityIndex >= 0)
            selectedTemp.splice(entityIndex,1)
        else 
            selectedTemp = [...selectedEntities, entity];
        setSelectedEntities(selectedTemp);
    }

    const selectAllClients = (selected) => {
        let selectedEntitiesTemp = [...selectedEntities];
        if(selected){
            let selectAllClients = {
                name: "Tous les clients",
                uid: "allClients",
                type: "allClients"
            }
            selectedEntitiesTemp.push(selectAllClients);
            setAllClients(true)
        }else {
            let allClientsIndex = selectedEntitiesTemp.findIndex(row => row.uid === "allClients")
            if(allClientsIndex >= 0)
                selectedEntitiesTemp.splice(allClientsIndex, 1)
            setAllClients(false)
        }
        setSelectedEntities(selectedEntitiesTemp);
    }

    const selectAllSuppliers = (selected) => {
        let selectedEntitiesTemp = [...selectedEntities];
        if(selected){
            let selectAllSuppliers = {
                name: "Tous les fournisseurs",
                uid: "allSuppliers",
                type: "allSuppliers"
            }
            selectedEntitiesTemp.push(selectAllSuppliers);
            setAllSuppliers(true)
        }else {
            let allSuppliersIndex = selectedEntitiesTemp.findIndex(row => row.uid === "allSuppliers")
            if(allSuppliersIndex >= 0)
                selectedEntitiesTemp.splice(allSuppliersIndex, 1)
            setAllSuppliers(false)
        }
        setSelectedEntities(selectedEntitiesTemp);
    }

    // const selectAllEntities = (e) => {
    //     let selectedTemp = []
    //     if (e.target.checked) {
    //         selectedTemp = selectedEntities.map(entity => ({uid : entity.uid, commercial_name: entity.commercial_name, new: true}))
    //     }
    //     setSelectedEntities(selectedTemp)
    // }

    const addClickHandler = () => {
        addRecipientsHandler(selectedEntities)
        handleClose()
    }
    return (
        <>
            <Modal show={show} onHide={handleClose} dialogClassName="modal_w_mc" contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>Ajouter des destinataires </Modal.Title>
                </Modal.Header>
                <Modal.Body className="repertoireModal__body" >
                    <div className="repertoireModal__entitiesHeader_container">
                        {/* <div className="repertoireModal__entityType">
                            <label for="entityType">Type : </label>
                            <select 
                                className="form-control form-select" 
                                value={entitiesType}
                                onChange={(e) => setEntitiesType(e.target.value)}
                            >
                                <option value="client">Client</option>
                                <option value="supplier">Fournisseur</option>
                            </select>
                        </div> */}
                        {
                            entitiesType === 'client' ? (
                                <div className="repertoireModal__selection_container">
                                    <div className="form-check form-check-inline">
                                        <input 
                                            className="form-check-input" 
                                            type="radio" 
                                            name="clientSelection"
                                            id="clientSelection"
                                            checked = {!allClients}
                                            onChange={(e) => selectAllClients(false)}
                                        />
                                        <label className="form-check-label" for="clientSelection">Sélection</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input 
                                            className="form-check-input" 
                                            type="radio" 
                                            name="allClients"
                                            id="allClients" 
                                            checked = {allClients}
                                            onChange={(e) => selectAllClients(true)}
                                        />
                                        <label className="form-check-label" for="allClients"> 
                                            Tous
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                <div className="repertoireModal__selection_container">
                                    <div className="form-check form-check-inline">
                                        <input 
                                            className="form-check-input" 
                                            type="radio" 
                                            name="supplierSelection"
                                            id="supplierSelection"
                                            checked = {!allSuppliers}
                                            onChange={(e) => selectAllSuppliers(false)}
                                        />
                                        <label className="form-check-label" for="supplierSelection">Sélection</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input 
                                            className="form-check-input" 
                                            type="radio" 
                                            name="allSuppliers"
                                            id="allSuppliers" 
                                            checked = {allSuppliers}
                                            onChange={(e) => selectAllSuppliers(true)}
                                        />
                                        <label className="form-check-label" for="allSuppliers"> 
                                            Tous
                                        </label>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className="repertoireModal__entities_container">
                        <div className="repertoireModal__entities_header">
                            <div class=" input-group input-group-sm">
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    placeholder="Rechercher"  
                                    onChange={(e) => setSearchQuery(e.target.value)} 
                                    value={searchQuery || ''}
                                />
                                <div class="input-group-append" >
                                    <span class="input-group-text" id="basic-addon2"><SearchIcon/> </span>
                                </div>
                            </div>
                            {/* <div className="d-flex justify-content-between align-items-center">
                                <p className="productsModal_totalItems">{selectedEntities?.length} entreprise(s) séléctionnée(s)</p>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="selectAllRegies" onChange={(e) => selectAllEntities(e)} />
                                    <label class="form-check-label" for="selectAllRegies" >
                                        Selectionner tout
                                    </label>
                                </div>
                            </div> */}   
                        </div>
                        <div className="repertoireModal__entities_list">
                            {
                                (entitiesType === 'client' && !allClients ) ?
                                    <EntitiesTable
                                        entities={entities}
                                        selectedEntities={selectedEntities}
                                        selectEntity={selectEntity}
                                        type="client"
                                    />
                                : null
                            }
                            {
                                (entitiesType === 'supplier' && !allSuppliers ) ?
                                    <EntitiesTable
                                        entities={entities}
                                        selectedEntities={selectedEntities}
                                        selectEntity={selectEntity}
                                        type="supplier"
                                    />
                                : null
                            }
                        </div>
                    </div>
                    
                </Modal.Body>
                <Modal.Footer>
                    <BtnOutlineAction  
                        color={theme.colors.danger}
                        hover={theme.colors.redLight}
                        onClick={() => handleClose()}>
                        {t('default:cancel', "Annuler")} 
                    </BtnOutlineAction>
                    <BtnAction 
                        onClick={() => addClickHandler()}
                    >
                        {t('default:associate', "Sauvegarder")}
                    </BtnAction>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export default RepertoireModal
