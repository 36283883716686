import React, { useEffect, useState,useCallback, useMemo } from 'react'
import { connect } from 'react-redux';
import { Notyf } from 'notyf';
import { getNotyfObject } from '../../../shared/utility';
import _ from 'lodash';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import StatusModal from './StatusModal/StatusModal';
import { IDProgressLineContainer, IDProgressLineItem, IDProgressLineLabel, PLAction } from './IDProgressLine.styled';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTheme } from 'styled-components';

function IDProgressLine(props) { 
    const {
        userType,
        entityFilter,
        status,
        editStatus,
        accessToken
    } = props
    const {t} = useTranslation();
    let notyf = getNotyfObject();
    const theme = useTheme()
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [statusList, setStatusList] = useState([]);
    const [statusAvailable, setStatusAvailable] = useState([
        {label: 'EMPTY', value: ""},
        {label: 'LITIGATION', value: "LITIGATION"},
        {label: 'TO_BE_PAID', value: "TO_BE_PAID"},
        {label: 'PAID', value: "PAID"}
    ]);
    // const statusList = useMemo(() => [
    //     {label: status === 'ERROR' ? 'Erreur' : "Importée", value: status === 'ERROR' ? 'ERROR' : "IMPORTED"},
    //     {label: "Controlée", value: "CONTROLLED"},
    //     {label: "Intégrée", value: "INTEGRATED"},
    //     {label: "A payer", value: "TO_BE_PAID"},
    //     {label: "Payée", value: "PAID"}
    // ], [status])
    const dangerStatus = useMemo(() => [ 'ERROR', 'REJECTED', 'REFUSED', 'LITIGATION'], [])

    useEffect(() => {
        let statusListTmp = [];
        if(userType === 'client'){

            switch (status) {
                case 'ERROR':
                    statusListTmp = [
                        {label: 'ERROR', value: 'ERROR'},
                    ];
                    break;
                case 'REJECTED':
                    statusListTmp = [
                        {label: 'REJECTED', value: "REJECTED"}
                    ]
                    break;
                case 'REFUSED':
                    statusListTmp = [
                        {label: 'REFUSED', value: "REFUSED"}
                    ]
                    break;
                case 'LITIGATION':
                    statusListTmp = [
                        {label: 'INTEGRATED_CLIENT', value : "INTEGRATED"},
                        {label: 'LITIGATION', value: "LITIGATION"}
                    ]
                    break;
                default:
                    statusListTmp = [
                        {label: 'INTEGRATED_CLIENT', value : "INTEGRATED"},
                        //{label: 'LITIGATION', value: "LITIGATION"},
                        {label: 'TO_BE_PAID', value: "TO_BE_PAID"},
                        {label: 'PAID', value: "PAID"}
                    ];
                    break;
            }
        }else{
            switch (status) {
                case 'ERROR':
                    statusListTmp = [
                        {label: 'ERROR', value: 'ERROR'},
                    ];
                    break;
                case 'REJECTED':
                    statusListTmp = [
                        {label: 'IMPORTED', value: "IMPORTED"},
                        {label: 'REJECTED', value: "REJECTED"}
                    ]
                    break;
                case 'REFUSED':
                    statusListTmp = [
                        {label: 'IMPORTED', value : "IMPORTED"},
                        {label: 'CONTROLLED', value: "CONTROLLED"},
                        {label: 'REFUSED', value: "REFUSED"}
                    ]
                    break;
                case 'LITIGATION':
                    statusListTmp = [
                        {label: 'IMPORTED', value : "IMPORTED"},
                        {label: 'CONTROLLED', value: "CONTROLLED"},
                        {label: 'INTEGRATED', value: "INTEGRATED"},
                        {label: 'LITIGATION', value: "LITIGATION"}
                    ]
                    break;
                default:
                    statusListTmp = [
                        {label: 'IMPORTED', value : "IMPORTED"},
                        {label: 'CONTROLLED', value: "CONTROLLED"},
                        {label: 'INTEGRATED', value: "INTEGRATED"},
                        {label: 'TO_BE_PAID', value: "TO_BE_PAID"},
                        {label: 'PAID', value: "PAID"}
                    ];
                    break;
            }
        }
        setStatusList(statusListTmp)
    }, [status])
    let actionButton = null;

    const getStatusColors = (value, index) => {
        const statusIndex = statusList.findIndex(item => item.value === status)
        if(dangerStatus.includes(status)) {
            if(value === status) 
                return [theme.colors.danger, theme.colors.danger]
            if(index < statusIndex)
                return [theme.colors.primary, theme.colors.primary]
            if(index === (statusIndex + 1))
                return [theme.colors.seconday, theme.colors.seconday]
        }
            
        if(value === status) 
            // return [theme.colors.seconday, theme.colors.warning]
            return [theme.colors.primary, theme.colors.primary]
        if(index < statusIndex)
            return [theme.colors.primary, theme.colors.primary]
        if(index === (statusIndex + 1))
            return [theme.colors.seconday, theme.colors.seconday]
        return [null, null]
    }

    if((userType === "client") && !accessToken && !['ERROR', 'REJECTED', 'REFUSED'].includes(status) ) {
        actionButton = (
            <PLAction onClick={() => setShowStatusModal(true)} >
                <span>
                    {t("documentDetails:modifyStatus")}
                </span>
                <EditIcon style={{fontSize: "1rem"}}/>
            </PLAction>
        )
    }  

    return ( 
        <IDProgressLineContainer>
            {
                statusList.map((item, index) => (
                    <IDProgressLineItem 
                        color={getStatusColors(item.value, index)[0]} 
                        border={getStatusColors(item.value, index)[1]} 
                    >
                          {/* t("invoiceStatut:Importée"), `Voulez-vous relancer ${signUser?.first_name} ? Un emaidat.`, {firstName: signUser?.first_name + " " + signUser?.last_name}) */}
                        <IDProgressLineLabel>{t(`invoiceStatut:${item.label}`)}</IDProgressLineLabel>
                        <CheckCircleOutlineIcon style={{fontSize: "1.25rem"}} />
                    </IDProgressLineItem>
                ))
            } 
            {actionButton}
            {
                showStatusModal ? (
                    <StatusModal
                        show={showStatusModal}
                        modalClosed={() => setShowStatusModal(false)}
                        statusVal={status}
                        statusList={statusAvailable}
                        editStatus={editStatus}
                    />
                ) : null
            }
        </IDProgressLineContainer>
        )      
    }
    
const mapStateToProps = (state) => ({
    userType: state.auth.userType,
    // isCreator : state.auth?.userId === state.mandat?.admData?.creatorUid,
    // mandatUid: state.mandat.admData.uid,
    // status : state.mandat?.admData?.status,
    // connectedUser : state.mandat?.admData?.connectedUser,
    // pdfFile: state.mandat.admData?.pdfFile || null,
    // admData: state.mandat.admData,
    // admUpdate: state.mandat.admUpdate
})
const mapDispatchToProps = dispatch => ({
    // getMandatData: (mandatUid) => dispatch(actions.getMandatData(mandatUid)),
    // updateMandatStatus: (mandatUid, status) => dispatch(actions.updateMandatStatus(mandatUid, status)),
    // createMandatTimelineEvent: (mandatUid, eventData) => dispatch(actions.createMandatTimelineEvent(mandatUid, eventData)),
    // setTabSideActive: (tabSideActive) => dispatch(actions.setTabSideActive(tabSideActive))
})
export default connect(mapStateToProps, mapDispatchToProps)(IDProgressLine)
