import React ,{useMemo}from 'react'
import { Navigate, Route, useLocation, Routes, useNavigate } from 'react-router-dom';
import Header from '../../../components/Header/Header';
import { AppBody, AppContainer, AppContent, AppWrapper } from '../../../styles/App.styled';
import BadgeIcon from '@mui/icons-material/Badge';
import ArticleIcon from '@mui/icons-material/Article';
import { connect } from 'react-redux';
import DescriptionIcon from '@mui/icons-material/Description';
import Menu from '../../Menu/Menu'
import Entity from '../../Entity/Entity';
import UserForm from '../../UserForm/UserForm';
import SuiviInvoices from '../../SuiviInvoices/SuiviInvoices';
import Documentation from '../../Documentation/Documentation';
import Home from '../../Home/Home';
import ClientHome from '../../ClientHome/ClientHome'
//import HomeIcon from '@mui/icons-material/Home';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import { useTranslation } from 'react-i18next';
import BusinessIcon from '@mui/icons-material/Business';
import InvoiceDetail from '../../InvoiceDetail/InvoiceDetail';
import EditPassword from '../../EditPassword/EditPassword';
import ReferentielClient from '../../ReferentielClient/ReferentielClient';
import {ReactComponent as HomeIcon}  from '../../../assets/icons/home.svg';
import {ReactComponent as ArrowDownToSquare}  from '../../../assets/icons/arrowDownToSquare.svg';
import {ReactComponent as ChartSimple}  from '../../../assets/icons/chartSimple.svg';
import {ReactComponent as Comment}  from '../../../assets/icons/comment.svg';
import {ReactComponent as FloppyDisk}  from '../../../assets/icons/floppyDisk.svg';
import {ReactComponent as IdCardClip}  from '../../../assets/icons/idCardClip.svg';
import {ReactComponent as Memo}  from '../../../assets/icons/memo.svg';
import {ReactComponent as Shield}  from '../../../assets/icons/shield.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {faFileLines} from '@fortawesome/free-regular-svg-icons'
function ClientRoot({uidEntity}) {
  const {t} = useTranslation();
console.log(uidEntity)
  const menuItems = useMemo(() => ([
      {isLink: true, label: t('navbar:Accueil'), pathname: '/', icon: <HomeIcon />},
      {isLink: true, label: t('navbar:ficheClient'), pathname: `/referentiel`, icon: <IdCardClip />},
      {isLink: true, label: t('navbar:invoiceTracking', 'Suivi des factures'), pathname: '/invoices', icon: <FontAwesomeIcon className="fa-light fa-file-lines" icon={faFileLines} />},
      {isLink: true, label: 'Documentation', pathname: '/documentation', icon: <Memo />}
    
  ]), [t])
  return (
  <>
     <AppContainer>
       
            
       <Menu items={menuItems} />
       <AppBody style={{flexDirection:"column",margin:""}} >
       <Header />
       <AppWrapper>
          <AppContent>
            <Routes>
              <Route path="/invoiceDetail/:uid" element={<InvoiceDetail />} />
              <Route path="/invoiceDetail/:uid/comments" element={<InvoiceDetail />} />
              <Route path="/referentiel" element={ <ReferentielClient entityFilter={"client"} />}/>
              <Route path="/entity/:uid" element={<Entity />} />
              <Route path="/userForm/:uid" element={<UserForm />} />
              <Route path="/userForm" element={<UserForm />} />
              <Route path="/invoices" element={ <SuiviInvoices entityFilter={"client"} />}/>
              <Route path="/documentation" element={<Documentation/>} />
              <Route path="/editPassword" element={<EditPassword />}/>
               <Route path="/" element={<ClientHome />} />
            </Routes>
          </AppContent>
        </AppWrapper>
        </AppBody>
      </AppContainer>
  </>
  )
}


const mapStateToProps = (state) => ({
  userType : state.auth.userType,
  login : state.auth.login,
  uidEntity : state.auth.entityFirst,
  userUid : state.auth.userUid
})

const mapDispatchToProps = dispatch => ({
})
export default connect(mapStateToProps, mapDispatchToProps) (ClientRoot)