import React, { useCallback, useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import Axios from '../../../axios-proas';
import { Button, Modal } from 'react-bootstrap';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { useTranslation } from 'react-i18next';
import {Content,DivLabel,DivGlp,CBtn,Container,Input} from '../EntitiesComponent/EntitiesComponent.Styled'

function EntityUserModal({handleClose, show, addEntitiesUser, userEntities, entitiesType, userRole, role, authUserId }) {
    const [entities , setEntities] = useState([]);
    const [selectedEntities, setSelectedEntities] = useState(userEntities ? userEntities : []);
    const [searchQuery, setSearchQuery] = useState(null)
    const [selectAll, setSelectAll] = useState(false)
    const {t} = useTranslation();

    const getEntitiesList = useCallback(() => {
        let filter = {
            searchQuery: searchQuery,
            type : entitiesType
        }
        Axios.get('/client//modalEntities', {
            params: filter
        })
        .then(res => {
            setEntities(res.data?.result)
        })
    }, [searchQuery])

    useEffect(() => {
        getEntitiesList()
    }, [searchQuery])
    

    const SelectEntity = (entity) => {
        let entityIndex = selectedEntities.findIndex(row => row.uid === entity.uid);
        let selectedTemp = [...selectedEntities];
        if(entityIndex >= 0)
            selectedTemp.splice(entityIndex,1)
        else 
            selectedTemp = [...selectedEntities, entity];
        setSelectedEntities(selectedTemp);
    }

    const selectAllEntities = () => {
        let selectedTemp = []
        if (selectAll)
            selectedTemp = entities.map(e => e)
        setSelectedEntities(selectedTemp)
    }

    useEffect(() => {
        selectAllEntities()
    }, [selectAll])

    const addClickHandler = () => {
        console.log(selectedEntities)
        addEntitiesUser(selectedEntities)
        handleClose()
    }
    return (
        <>
            <Modal show={show} onHide={handleClose}  >
                <Modal.Header closeButton>
                <Modal.Title>{ entitiesType === "supplier" ? t("documentation:selectSupplier") : t("documentation:selectClient")} </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                   
                        <div style={{display:"flex"}}>
                            <Input 
                                type="text" 
                               
                                placeholder={t('tableAction:Rechercher',"Rechercher")}  
                                 
                                onChange={(e) => setSearchQuery(e.target.value)} 
                                value={searchQuery || ''}
                            />
                            <div >
                                <span ><SearchIcon/> </span>
                            </div>
                        </div>
                   
                    <div className="entityUserModal_items bg-white">
                        <table class="table entityUserModal__table">
                            <thead class="thead-light">
                                <tr>
                                <th scope="col" style={{width: "50px"}}>
                                    <div className="columnHead__container" style={{justifyContent: "center"}}>
                                        <div className="columnHead__title">
                                            <CheckIcon onClick={() => setSelectAll(!selectAll)} />
                                        </div>
                                    </div>
                                </th>
                                <th scope="col">{t('client:name', "Raison sociale")} </th>
                                <th scope="col">Code</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                {
                                    entities?.length > 0 ? entities.map(entity => (
                                        <tr>
                                            <td>
                                                <div className="productsModal__itemIcon" onClick={() => SelectEntity({uid : entity.uid, name : entity.name, new : true})}>
                                                    {
                                                        selectedEntities.some(row => row.uid === entity.uid) ? (
                                                            <ThumbUpIcon style={{cursor: "pointer", color: "green"}} />
                                                        ) : <AddIcon style={{cursor: "pointer", color: "blue"}} />
                                                    }
                                                </div>
                                            </td>
                                            <td>{entity.name} </td>
                                            <td>{entity.code} </td>
                                        </tr>
                                    )) : <div style={{whiteSpace: "nowrap"}}>
                                                {t('mandat:noAgencies', "Aucune entreprise à afficher")}  
                                        </div>
                                }
                            </tbody>
                        </table>
                    </div>
                    
                </Modal.Body>
                <Modal.Footer>
                <CBtn color="red" onClick={handleClose}>
                    {t('btn:cancel', "Annuler")} 
                </CBtn>
                <CBtn  color='#2174B980' onClick={() => addClickHandler()}>
                    {t('btn:select', "Associer")} 
                </CBtn>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => ({ 
})
const mapDispatchToProps = dispatch => ({
})  
export default connect(mapStateToProps, mapDispatchToProps)(EntityUserModal)
