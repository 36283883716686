import React, { useEffect, useMemo, useState } from 'react'
import { Pie } from 'react-chartjs-2'
import { useTheme } from 'styled-components';
import Axios from '../../../axios-proas';
import { getNotyfObject, getStatusWithKey } from '../../../shared/utility';
import { IPSContainer, IPSTitle } from './InvoicesPerStatus.styled';

function InvoicesPerStatus({period, userType}) {
    const [data, setData] = useState([])
    const [labels, setLabels] = useState([])
    const [dataColors, setDataColors] = useState([])
    const theme = useTheme();
    const notyf = getNotyfObject()

    useEffect ( () =>{
        Axios.get("/invoice//supplierInvoicesPerStatus", {
            params: {
                period : period
            },
        }).then((response) => {
            const invoiceData = response?.data
            let labelsTemp = [];
            let dataTemp = [];
            let colorsTemp = []
            if(invoiceData && invoiceData?.length > 0){
                invoiceData?.map(d => {
                    labelsTemp = [...labelsTemp, getStatusWithKey(d.status)]
                    dataTemp = [...dataTemp, d.currentInvoices]
                    // colorsTemp = [...colorsTemp, colors.filter(c => c.key === d.status)[0].value]
                    colorsTemp = [...colorsTemp, theme.status.text[d.status]]
                })
                setLabels(labelsTemp)
                setData(dataTemp)
                setDataColors(colorsTemp)
            }
        }).catch(res => {
            notyf.error("Une erreur s'est produite!");
        })  
    }, [period])

   
    const chartData ={
        labels: labels,
        datasets: [
            {    
                backgroundColor: dataColors,
                data: data
            }
            ,{
                backgroundColor:["yellow"]
            }
        ],
        series:[{
            data: data
        }]
        // These labels appear in the legend and in the tooltips when hovering different arcs
        
    }

    const options = {
        //maintainAspectRatio: false,
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }
        },
        title: {
            display: false,
            text: 'Factures par statut',
            fontSize: 25,
            fontColor: "#566573",
            padding:30
        },
        legend: {
            position: "right",
            labels: {
                fontColor: '#566573',
                fontSize: 15,
                padding: 10
            }
        },
        tooltips: {
            backgroundColor: "#5a6e7f"
        }
    }

  return (
    <IPSContainer isSupplier={userType === 'supplier'}>
        <IPSTitle><span>Factures par statut</span></IPSTitle>
        <Pie data={chartData} options={options} datasetKeyProvider={() => btoa(Math.random()).substring(0,12)} />
    </IPSContainer>
  )
}

export default InvoicesPerStatus