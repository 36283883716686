import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const RFContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const RFBody = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 1rem;
`

export const RFTableContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const CustomerLink = styled(Link)`
    color: ${({theme}) => theme.colors.primary};
    text-decoration: none;
`

// export const SupplierUsersLink = styled(Link)`
//     background-color: ${({theme}) => theme.colors.primary};
//     color: #FFFFFF;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     font-size: 0.9rem;
//     font-weight: 500;
//     padding: 0.25rem 1rem;
//     text-decoration: none;
//     border-radius: 8px;
//     width: fit-content;
// `