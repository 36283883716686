import styled from 'styled-components';

export const EPContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const EPBody = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 3rem;
`

export const EPFormWrapper = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
export const BtnAction = styled.button`  
    display: flex;
    justify-content: center ;
    align-items: center;
    /* height: 50px; */
    margin-left: ${({noMargin}) => noMargin ? '0' : '1rem'};
    padding: 10px 30px;
    border: 2px solid ${({bg, theme}) => bg ? bg : theme.colors.primary};
    border-radius: 30px;
    color: ${({color, theme}) => color || "#FFFFFF"};
    cursor: pointer;
    font-size: 14px;
    background-color: ${({bg, theme}) => bg ? bg : theme.colors.primary};

    & span {
        color: ${({color}) => color || '#FFFFFF'};
    }

    &:hover {
        background-color: ${({theme}) => theme.buttons.primaryHover};
    }

    &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
`;