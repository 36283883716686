import React, { useState, useEffect } from "react";
import Axios from "../../axios-proas";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import fr from "react-phone-input-2/lang/fr.json";
import { useTranslation } from "react-i18next";
import { checkValidity, getNotyfObject } from "../../shared/utility";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
    BtnAction,
  BtnOutlineAction,
  FormGroup,
  FormInput,
  FormLabel,
  FormSelect,
  Header,
  HeaderActions,
  HeaderInfos,
  HeaderLocation,
  HeaderTitle,
} from "../../styles/Common";
import { useTheme } from "styled-components";
import { TelInput, UFBody, UFContainer } from "./UserForm.styled";
import {
  EIFormCol,
  EIFormContainer,
  EntFormItem,
  EntFormList,
  EntFormSection,
} from "../Entity/Entity.styled";
import EntitiesDropDown from "../../components/Entity/EntitiesDropDown/EntitiesDropDown";
import CloseIcon from "@mui/icons-material/Close";
// import EntitiesComponent from '../../component/UserForm/EntitiesComponent/EntitiesComponent';

function UserForm() {
  const [user, setUser] = useState({
    type: "owner",
    level: "standard",
  });
  const [entity, setEntity] = useState(null);
  const [showEntityModal, setShowEntityModal] = useState(false);
  const [errors, setErrors] = useState(null);
  const { t } = useTranslation();
  let notyf = getNotyfObject();
  let location = useLocation();
  let params = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    if (params?.uid) {
      const userUid = params?.uid;
      Axios.get("/user/" + userUid + "/user")
        .then((response) => {
          setUser(response.data?.user);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (location?.state?.entityUid) {
      const entityUid = location.state.entityUid;
      Axios.get("/entity/" + entityUid + "/object")
        .then((response) => {
          setEntity(response.data?.entity);
          let newUser = {};
          if (response.data?.entity?.type == "owner")
            newUser.entities = [
              {
                name: response.data?.entity?.name,
                uid: response.data?.entity?.uid,
              },
            ];
          else if (response.data?.entity?.type == "supplier")
            newUser.suppliers = [
              {
                name: response.data?.entity?.name,
                uid: response.data?.entity?.uid,
              },
            ];
          else if (response.data?.entity?.type == "client")
            newUser.clients = [
              {
                name: response.data?.entity?.name,
                uid: response.data?.entity?.uid,
              },
            ];
          newUser.type = response.data?.entity?.type;
          newUser.level = "standard";
          setUser(newUser);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [location, params]);

  const inputChangeHandler = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    let userTemp = {
      ...user,
      [name]: value,
    };
    setUser(userTemp);
  };

  const phoneInputChangeHandler = (value) => {
    let userTemp = {
      ...user,
      telephone_number: value,
    };
    setUser(userTemp);
  };

  const entitiesRadioChangeHandler = (name, value) => {
    let userTemp = {
      ...user,
      [name]: +value,
    };
    setUser(userTemp);
  };

  const entitiesChangeHandler = (entitiesList) => {
    let userTemp = {
      ...user,
      entities: entitiesList,
    };
    setUser(userTemp);
  };

  const clientsChangeHandler = (clientsList) => {
    let userTemp = {
      ...user,
      clients: clientsList,
    };
    setUser(userTemp);
  };

  const suppliersChangeHandler = (suppliersList) => {
    let userTemp = {
      ...user,
      suppliers: suppliersList,
    };
    setUser(userTemp);
  };

  const deleteEntityclickHandler = (entity) => {
    let userTemp = { ...user };
    if (user?.id && !entity?.new) {
      userTemp = {
        ...userTemp,
        entitiesToDelete: userTemp?.entitiesToDelete
          ? [...userTemp?.entitiesToDelete, entity.uid]
          : [entity.uid],
      };
    }
    let userEntities = [...user?.entities];
    let entityIndex = user?.entities.findIndex((ent) => ent.uid === entity.uid);
    if (entityIndex >= 0) {
      userEntities.splice(entityIndex, 1);
    }
    setUser({
      ...userTemp,
      entities: userEntities,
    });
  };

  const deleteClientClickHandler = (client) => {
    let userTemp = { ...user };
    if (user?.id && !client?.new) {
      userTemp = {
        ...userTemp,
        clientsToDelete: userTemp?.clientsToDelete
          ? [...userTemp?.clientsToDelete, client.uid]
          : [client.uid],
      };
    }
    let userClients = [...user?.clients];
    let clientIndex = user?.clients.findIndex((ent) => ent.uid === client.uid);
    if (clientIndex >= 0) {
      userClients.splice(clientIndex, 1);
    }
    setUser({
      ...userTemp,
      clients: userClients,
    });
  };

  const deleteSupplierClickHandler = (supplier) => {
    let userTemp = { ...user };
    if (user?.id && !supplier?.new) {
      userTemp = {
        ...userTemp,
        suppliersToDelete: userTemp?.suppliersToDelete
          ? [...userTemp?.suppliersToDelete, supplier.uid]
          : [supplier.uid],
      };
    }
    let userSuppliers = [...user?.suppliers];
    let supplierIndex = user?.suppliers.findIndex(
      (ent) => ent.uid === supplier.uid
    );
    if (supplierIndex >= 0) {
      userSuppliers.splice(supplierIndex, 1);
    }
    setUser({
      ...userTemp,
      suppliers: userSuppliers,
    });
  };

  const checkUserFormValidity = (user) => {
    let errors = [];
    if (
      !user.hasOwnProperty("type") ||
      !checkValidity(user?.type, { required: true })
    ) {
      errors.push(
        t(
          "masterData:error_userType",
          "Veuillez sélectionner un type pour l'utilisateur"
        )
      );
    }
    if (
      !user.hasOwnProperty("level") ||
      !checkValidity(user?.level, { required: true })
    ) {
      errors.push(
        t(
          "masterData:error_userRole",
          "Veuillez sélectionner un role pour l'utilisateur"
        )
      );
    }
    if (
      !user.hasOwnProperty("login") ||
      !checkValidity(user?.login, { required: true, isEmail: true })
    ) {
      errors.push(
        t(
          "masterData:error_userEmail",
          "Le champ 'Email' est vide ou non valide"
        )
      );
    }
    // if (
    //   showEntitiesListComponent() &&
    //   +user?.accessOwner !== 1 &&
    //   (!user.hasOwnProperty("entities") || user?.entities.length == 0)
    // ) {
    //   errors.push(
    //     t(
    //       "masterData:error_userEntity",
    //       "Aucune entité interne n'a été sélectionée ! "
    //     )
    //   );
    // }



    /*
        if(showClientsListComponent() && +user?.accessClient !== 1 && (!user.hasOwnProperty('clients') || user?.clients.length == 0)){
            errors.push(t("masterData:error_userClients", "Aucun client n'a été sélectioné ! ")) ;
        }
        */
       
    // if (
    //   showSuppliersListComponent() &&
    //   +user?.accessSupplier !== 1 &&
    //   (!user.hasOwnProperty("suppliers") || user?.suppliers.length == 0)
    // ) {
    //   errors.push(
    //     t(
    //       "masterData:error_userSupplier",
    //       "Aucun fournisseur n'a été sélectioné ! "
    //     )
    //   );
    // }
    return errors;
  };

  const addUserclickHandler = () => {
    console.log(user)
    let checkErrors = checkUserFormValidity(user);
    if (checkErrors && checkErrors.length > 0) {
      setErrors(checkErrors);
    } else {
      setErrors(null);
      Axios.post("user", user)
        .then((response) => {
          if (response.data.hasOwnProperty("error")) {
            notyf.error(
              t("default:emailAlreadyExist", "Cet email existe déja")
            );
          } else if (response.data.hasOwnProperty("success")) {
            notyf.success(
              t("default:userAdded", "L'utilisateur a bien été ajouté")
            );
            setUser(null);
            localStorage.setItem("supplierActiveTab", "contacts");
            navigate(-1);
          }
        })
        .catch((response) => {
          notyf.error(
            t("mandat:generatedErrorMessage", "Une erreur s'est produite")
          );
        });
    }
  };

  const editUserclickHandler = () => {
    let checkErrors = checkUserFormValidity(user);
    if (checkErrors && checkErrors.length > 0) {
      setErrors(checkErrors);
    } else {
      setErrors(null);
      Axios.put(`user/${user?.uid}/user`, user)
        .then((response) => {
          if (response.data.hasOwnProperty("success")) {
            notyf.success(
              t("default:userAdded", "L'utilisateur a bien été modifié")
            );
            setUser(null);
            localStorage.setItem("supplierActiveTab", "contacts");
            navigate(-1);
          }
        })
        .catch((response) => {
          notyf.error(
            t("mandat:generatedErrorMessage", "Une erreur s'est produite")
          );
        });
    }
  };

  const saveClickHandler = () => {

    if (user?.id) {
      editUserclickHandler();
    } else {
      addUserclickHandler();
    }
  };

  const showEntitiesListComponent = () => {
    return user?.type === "owner";
  };

  const showClientsListComponent = () => {
    return (
      (user?.type === "owner" && user?.level === "standard") ||
      user?.type === "client"
    );
  };

  const showSuppliersListComponent = () => {
    return (
      (user?.type === "owner" && user?.level === "standard") ||
      user?.type === "supplier"
    );
  };
//marzouk pour pointer vers contacte 
  const returnContact=()=>{
    localStorage.setItem("supplierActiveTab", "contacts");
    navigate(-1)
  }
 
  return (
    <>
      <UFContainer>
        <Header>
          <HeaderInfos>
            <HeaderLocation>
              {params?.uid?  t("global:ficheUser", "Fiche Utilisateur : ")+ user?.last_name:t("global:ficheUser", "Nouvel Utilisateur : ")}
            </HeaderLocation>
            {/* <HeaderTitle>Informations administrative</HeaderTitle> */}
          </HeaderInfos>
          <HeaderActions>
            <BtnOutlineAction
            
              onClick={returnContact}
            >
              {t("btn:Retour")}
            </BtnOutlineAction>
            <BtnAction
              onClick={() => saveClickHandler()}
            >
              {t("btn:Enregistrer")}
            </BtnAction>
          </HeaderActions>
        </Header>

        <UFBody>
          {errors && errors.length > 0 ? (
            <div className="alert alert-danger mt-2" role="alert">
              <ul>
                {errors.map((error) => (
                  <li key={error}>{error}</li>
                ))}
              </ul>
            </div>
          ) : null}
          <EntFormSection>

          <FormGroup>
                <p htmlFor="first_name">Entité</p>
                          
                  <p style={{}}>{user?.entities ? user?.entities[0]?.name:"" }</p>
               
              </FormGroup>
            {/* <EntitiesDropDown
              entitiesType={"supplier"}
              userEntities={user?.suppliers}
              addEntitiesUser={suppliersChangeHandler}
            />
            <EntFormList>
              {user?.suppliers?.length > 0
                ? user?.suppliers.map((entity) => (
                    <EntFormItem key={entity.uid}>
                      <span>{entity.name}</span>
                      <CloseIcon
                        style={{ fontSize: "0.7rem", cursor: "pointer" }}
                        onClick={() => deleteSupplierClickHandler(entity)}
                      />
                    </EntFormItem>
                  ))
                : null}
            </EntFormList> */}
          </EntFormSection>
          <EIFormContainer>
            <EIFormCol>
              <FormGroup>
                <FormLabel htmlFor="first_name">Prénom</FormLabel>
                <FormInput
                  id="first_name"
                  type="text"
                  name="first_name"
                  placeholder="Prénom"
                  value={user?.first_name || ""}
                  onChange={(e) => inputChangeHandler(e)}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel htmlFor="last_name">Nom</FormLabel>
                <FormInput
                  id="last_name"
                  type="text"
                  name="last_name"
                  placeholder="Nom"
                  value={user?.last_name || ""}
                  onChange={(e) => inputChangeHandler(e)}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel htmlFor="login">Email</FormLabel>
                <FormInput
                  id="login"
                  type="text"
                  name="login"
                  placeholder="Email"
                  value={user?.login || ""}
                  onChange={(e) => inputChangeHandler(e)}
                />
              </FormGroup>
            </EIFormCol>
            <EIFormCol>
              <FormGroup>
                <FormLabel htmlFor="telephone_number">
                  Numéro de téléphone
                </FormLabel>
                <TelInput
                  localization={fr}
                  country={"fr"}
                  dropdownStyle={{ width: "auto" }}
                  preferredCountries={["fr", "us"]}
                  enableSearch={false}
                  inputProps={{ name: "telephone_number" }}
                  placeholder={"33 0 00 00 00 00"}
                  value={user?.telephone_number?.substr(2) || ""}
                  onChange={(phone) => phoneInputChangeHandler("00" + phone)}
                />
              </FormGroup>
              {/* <FormGroup>
                <FormLabel htmlFor="type">Type</FormLabel>
                <FormSelect
                  id="type"
                  name="type"
                  value={user?.type || ""}
                  onChange={(e) => inputChangeHandler(e)}
                  disabled={user?.id || entity}
                >
                  <option value="owner">Interne</option>
                  <option value="client">Client</option>
                  <option value="supplier">Fournisseur</option>
                </FormSelect>
              </FormGroup> */}
              <FormGroup>
                <FormLabel htmlFor="level">Rôle</FormLabel>
                <FormSelect
                  id="level"
                  name="level"
                  value={user?.level || ""}
                  onChange={(e) => inputChangeHandler(e)}
                >
                  <option value="admin">Admin</option>
                  <option value="standard">Standard</option>
                </FormSelect>
              </FormGroup>
            </EIFormCol>
          </EIFormContainer>
        </UFBody>
      </UFContainer>
    </>
  );
}

export default UserForm;
