import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { useTheme } from 'styled-components';
import Axios from '../../../../axios-proas';
import { BCContainer } from './BarChart.styled';
import { useTranslation } from "react-i18next";


function BarChart({userType}) {
    const theme = useTheme()
    const [labels, setLabels] = useState([]);
    const [dataAmounts, setDataAmounts] = useState([]);
    const { t } = useTranslation();
    const monthList = useMemo(() => [t("dashboard:Janvier"), t("dashboard:Février"), t("dashboard:Mars"), t("dashboard:Avril"), t("dashboard:Mai"), t("dashboard:Juin"), t("dashboard:Juillet"), t("dashboard:Août"), t("dashboard:Septembre"), t("dashboard:Octobre"), t("dashboard:Novembre"), t("dashboard:Décembre")], [t])

    useEffect(() => {
        const date = new Date();
        const currentMonth = new Date().getMonth();
        const firstMonth = new Date(date.getFullYear() - 1, currentMonth + 1).getTime();
        const lastMonth = new Date().getTime();
        const dateInterval = firstMonth + ":" + lastMonth;
        Axios.get('/invoice//spentByMonth?caMonthInterval='+dateInterval).then((response) => {
            let newLabels = [];
            let amounts = [0,0,0,0,0,0,0,0,0,0,0,0]
            const statData = response.data.data;
            statData.map(row => {
                for (const key in row) {
                    let temp = +row['monthTotal'].substr(5,2) - 1;
                   
                    let pos = temp - (currentMonth + 1) >= 0 ? temp - (currentMonth + 1) : (temp - (currentMonth + 1)) + 12
                    if (key == 'monthTotal') {
                        //temp = +row[key].substr(5,2) - 1;
                        newLabels.push(monthList[temp]);
                    }
                    if (key == 'totalAmountBT') {
                        //console.log(pos)
                        amounts[pos] = row[key]
                        //amounts.push(row[key])
                    }
                    
                }
            })
            //setLabels(newLabels);
            let monthLabels = monthList.slice(currentMonth + 1, 12).concat(monthList.slice(0,currentMonth + 1))  
            setLabels(monthLabels);
            
            setDataAmounts(amounts);
            
        }).catch(error => {
            console.log(error)
        })

    }, [])

    
    const data ={
        labels: labels,
        datasets: [
            {
                label: t(userType === 'client' ? 'barchart:CAClient' : 'barchart:CA'),
                yAxisID: 'A',
                data: dataAmounts,
                borderColor: 'rgba(255, 166, 24, 1)',
                backgroundColor: '#2196F3',
                barPercentage: 0.4   
            }
        ]
    }

    const options = {
        maintainAspectRatio: false,
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }
        },
        title: {
            display: true,
            fontSize: 16,
            position: 'top',
            align: "start",
            fontColor: theme.colors.text,
            text: t(userType === 'client' ? 'barchart:barchartLegendClient' : 'barchart:barchartLegend'),
            // padding: 20
        },
        
        scales: {
            yAxes: [
                {
                    id: 'A',
                    position: 'left',
                    gridLines: {
                        color: "rgba(200,200,200,0.5)"
                    },
                    ticks: {
                        fontColor: theme.colors.text,
                        // min: 0,
                        // max: 6,
                        // stepSize: 10000
                    },
                }
             ]
            ,
            xAxes: [{
                gridLines: {
                    color: "rgba(200,200,200,0.5)"
                },
                ticks: {
                    fontColor: theme.colors.text,
                    fontSize: 14,
                },
                position: "relative"

            }]
        },
        legend: {
            position: "bottom",
            labels: {
                fontColor: theme.colors.text,
                fontSize: 15,
                padding: 10
            }
        }
    }
    return (
        <BCContainer isClient={userType === 'client'}>
            <Bar style={{height:"350px"}} data={data} options={options} />
        </BCContainer>
        
    )
}

export default BarChart
