import React, { useMemo, useState } from "react";
import DateProgressLine from "../DateProgressLine/DateProgressLine";
import {
  CIActions,
  CIContainer,
  CIContent,
  CIData,
  CIDeleteBtn,
  CITitle,
  CIUsersLink,
} from "./ExtractionItem.styled";
import GroupIcon from "@mui/icons-material/Group";
import { formatDate } from "../../../shared/utility";
//en premiere
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

//en derniere
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  BtnIcon,
  BtnOutlineAction,
  BtnOutlineLink,
  BtnSmOutlineAction,
  BtnSmOutlineLink,
} from "../../../styles/Common";
import ConfirmDelete from "../ExtractionModal/ConfirmDelete";
import Axios from "../../../axios-proas";
import { useTheme } from "styled-components";
import { t } from "i18next";

function ExtractionItem({ data ,deleteExtraction}) {
    const theme = useTheme()
    const [showModal, setShowModal] = useState(false);
    const statutExtraction=useMemo(()=>[
                            "ERROR",
                            "PROGRESS",
                            "WAITING"
    ],[]);
    const monthList = useMemo(
        () => [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
        ],
        []
    );
  const formatCombinedDateStart = (date) => {
    if (date) {
      const [start, end] = date.split(":");
      let startDate = +start;

      return startDate;
    } else return "";
  };
  const formatCombinedDateEnd = (date) => {
    if (date) {
      const [start, end] = date.split(":");
      // let startDate = formatDate(+start)
      let endDate = +end;
      return endDate;
    } else return "";
  };

  const Download =() => {
    if (data.uid != null) {
      Axios.get("extraction/" + data.uid + "/DownloadextractionByUid",{
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'multipart/form-data',
        }
      })
        .then((response) => {
          if(response.status === 200){
            let blob = new Blob([response.data], { type: 'application/zip' })
            const downloadUrl = URL.createObjectURL(blob)
            let a = document.createElement("a");
            a.href = downloadUrl;
            a.download = "extraction.zip";
            document.body.appendChild(a);
            a.click();
        }else {
            console.log("Archive vide ou non valide");
        }
        })
        .catch((response) => {
          console.log("Une erreur s'est produite");
        });
    }






// ///////
// Axios.get('/invoice//compressedDocument?selection='+selectedIds, {
//   responseType: 'arraybuffer',
//   headers: {
//       'Content-Type': 'multipart/form-data',
//   }
// }).then((response) => {
//   if(response.status === 200){
//       let blob = new Blob([response.data], { type: 'application/zip' })
//       const downloadUrl = URL.createObjectURL(blob)
//       let a = document.createElement("a");
//       a.href = downloadUrl;
//       a.download = "compressed.zip";
//       document.body.appendChild(a);
//       a.click();
//   }else {
//       notyf.error("Archive vide ou non valide");
//   }
// }).catch(err => {
//   notyf.error('Une erreur s\'est produite !')
// })







  }

  return (
    <CIContainer>
      <div style={{display:"flex"}}>
      <CITitle> {data?.titel}</CITitle>

      </div>


      <CIContent>
        <CIData>
          <DateProgressLine
            statut={data?.status}
            titel={data?.titel}
            creationDate={+data?.creationDate}
            campaignDate={formatCombinedDateStart(data?.invoiceCreationDate)}
            endDate={formatCombinedDateEnd(data?.invoiceCreationDate)}
          />
        </CIData>
        <CIActions>
          {/* <CIUsersLink
                        to={`#`}
                    >
                            {data?.recipients?.split(',').length}
                        <GroupIcon style={{fontSize: "1.2rem", marginLeft: "2px"}} />
                    </CIUsersLink> */}

            <BtnSmOutlineAction
                  // disabled={data?.hiddenDelete=="1"}
                    color={theme.colors.danger}
                    hover={theme.colors.redLight}
                    onClick={() => setShowModal(true)}
                  >
                  {t("btn:Supprimer")}
            </BtnSmOutlineAction>

          <BtnSmOutlineAction
                  disabled={statutExtraction.includes(data.status)}
                  onClick={() => Download()}>
                  {t("btn:Télecharger")}
          </BtnSmOutlineAction>
            <BtnSmOutlineLink
                to={"/extractionForm/" + data.uid}
                state={{ extractionData: data }}>
                {t("btn:Voir")}   <ArrowRightIcon/>
            </BtnSmOutlineLink>
          {showModal ? (
            <ConfirmDelete
              handleClose={() => setShowModal(false)}
              show={showModal}
              item={data?.uid}
              deleteExtraction={deleteExtraction}
            />
          ) : null}
        </CIActions>
      </CIContent>
    </CIContainer>
  );
}

export default ExtractionItem;
