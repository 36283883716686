import React from 'react'
import './InfoModal.css'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function InfoModal({handleClose, show, title, content }) {
    const {t} = useTranslation();

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal_w_mc" contentClassName="modal_w_mc" >
            {title && <Modal.Header closeButton>
                <Modal.Title>{title} </Modal.Title>
            </Modal.Header>}
            {content && <Modal.Body style={{minWidth: "40rem"}}>{content}</Modal.Body>}
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    {t('default:close', "OK")} 
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default InfoModal
