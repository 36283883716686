import React from 'react'
import GroupIcon from '@mui/icons-material/Group';
import { TD } from '../../../styles/Table.styled';
import { SupplierLink, SupplierUsersLink } from '../../../containers/ReferentielFournisseur/ReferentielFournisseur.Styled';
import { getEreportingTypeeWithKey,showColumn } from '../../../shared/utility';
import ArticleIcon from '@mui/icons-material/Article';
function TableBody(props) {
    const {ReportingData, formatDate, entityFilter,setEntitydetail,columns} = props;
 // exclusiveTaxAmountTotal invoicingEndDate invoicingStartDate  type issuingDate'
console.log(columns[1].show)
const formatNumber = (number) => {
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number).replaceAll('.',' ')
}
    return (
        <>   

            {
                ReportingData ? ReportingData.map(row => (
                    <tr key={row.id} >
                        <TD style={{display:  !showColumn(columns,"type") && 'none'}}>
                            {getEreportingTypeeWithKey(row.type)}
                        </TD>
                        <TD style={{display:  !showColumn(columns,"issuingDate") && 'none'}}>
                        {formatDate(+row.issuingDate)}
                            {/* <div className="tableBody__companyName">
                                <SupplierLink 
                                    to={`/entity/${row.uid}`}
                                    state={{entityType: 'supplier'}}
                                >
                                    {row.name}
                                </SupplierLink>
                            </div> */}
                        </TD>
                        <TD style={{display:  !showColumn(columns,"invoicingStartDate")&& 'none'}}> 
                           {formatDate(+row?.invoicingStartDate)} 
                        </TD>
                        <TD style={{display:  !showColumn(columns,"invoicingEndDate") && 'none'}}>
                            { formatDate(+row.invoicingEndDate)}
                        </TD>
                        <TD style={{display:  !showColumn(columns,"exclusiveTaxAmountTotal") && 'none'}}>
                           { formatNumber(+row.exclusiveTaxAmountTotal) }
                        </TD>
                        <TD >
                            <SupplierUsersLink 
                               to={`/eReportingInvoices/${row.uid}`}
                               //to={`/eReportingInvoices/8b6d759c-288a-4144-b2bd-e35fba8d8c33`}
                               state={row.type=="RECEIVED"?{entityFilter: 'supplier'}:{entityFilter: 'client'}}
                               // to={`/eReportingInvoices/${row.uid}`}
                               //state={{tab: 'contacts', entityType: 'supplier'}}    
                            >
                                {+row.totalDocs}
                                <ArticleIcon style={{fontSize: "1rem", marginLeft: "2px"}} />
                            </SupplierUsersLink>
                        </TD>
                    </tr>
                )) : null
            }
        </>
    )
}

export default TableBody
