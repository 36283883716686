import React, { useEffect, useState } from "react";
import Axios from "../../axios-proas";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import {
  HomeContainer,
  HomeHeader,
  HeaderInfos,
  HeaderLocation,
  HeaderTitle,
  HeaderActions,
  HeaderAction,
  SearchInputContainer,
  SearchInput,
  ActionWrapper,
  ActionLabel,
  HomeBody,
  HomeCards,
  HomeStats,
  HomeInvoices,
} from "./Home.styled";
import Select from "../../components/UI/Select/Select";
import Card from "../../components/Home/Card/Card";
import LineChart from "../../components/Home/LineChart/LineChart";
import Events from "../../components/Home/Events/Events";
import LastInvoices from "./LastInvoices/LastInvoices";
import { useTheme } from "styled-components";
import { getNotyfObject } from "../../shared/utility";
import { Link } from "react-router-dom";
import {
  SupplierLink,
  SupplierUsersLink,
} from "../ReferentielFournisseur/ReferentielFournisseur.Styled";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import { formatDateEur } from "../../shared/utility";
import { BtnAction } from "../../styles/Common";
import LastInvoicesClient from "./LastInvoicesClient/LastInvoicesClient";
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import { useTranslation } from 'react-i18next';
function Home() {
  const {t} = useTranslation();
  const theme = useTheme();
  const notyf = getNotyfObject();
  const [value, setValue] = useState("annee");
  const [activeSuppliers, setActiveSuppliers] = useState(0);
  const [activeClients, setActiveClients] = useState(0);
  const [inProgressSuppliers, setInProgressSuppliers] = useState(0);
  const [notPaidInvoices, setNotPaidInvoices] = useState(0);
  const [paidInvoices, setPaidInvoices] = useState(0);
  const [litigationInvoices, setLitigationInvoices] = useState(0);
  const defaultTime = new Date();
  defaultTime.setFullYear(defaultTime.getFullYear() - 1);
  const [startDate, setStartDate] = useState(
    formatDateEur(defaultTime.getTime())
  );
  const [endDate, setEndDate] = useState(formatDateEur(new Date().getTime()));
  const [eventData, setEventData] = useState();
  const [reload, setReload] = useState(false);

  const options = [
    { label: t("dashboard:annee"), value: "annee" },
    { label: t("dashboard:mois"), value: "mois" },
  ];

  const interval = (date) => {
    return formatDateEur(date);
  };

  const selectChange = (value) => {
    const Landing = new Date();
    const dateNow = Landing.getTime();

    setValue(value);
    switch (value) {
      case "annee":
        Landing.setFullYear(Landing.getFullYear() - 1);
        setStartDate(interval(Landing.getTime()));
        setEndDate(interval(dateNow));
        break;
      case "mois":
        Landing.setDate(Landing.getDate() - 30);
        setStartDate(interval(Landing.getTime()));
        setEndDate(interval(dateNow));
        break;
      case "jour":
        Landing.setDate(Landing.getDate() - 1);
        setStartDate(interval(Landing.getTime()));
        setEndDate(interval(dateNow));
        break;
      default:
        break;
    }
  };

  const getSuppliersCount = (status) => {
    Axios.get("/entity//suppliersCount", {
      params: {
        status: status,
        period: value,
        endDate: endDate,
        startDate: startDate,
      },
    })
      .then((res) => {
        if (res?.data?.suppliersCount) {
          switch (status) {
            case "active":
              setActiveSuppliers(res.data.suppliersCount);
              break;
            case "inProgress":
              setInProgressSuppliers(res.data.suppliersCount);
              break;
            default:
              break;
          }
        }
      })
      .catch((res) => {
        notyf.error("Une erreur s'est produite!");
      });
  };

  const getClientsCount = (status) => {
    Axios.get("/entity//clientsCount", {
      params: {
        status: status,
        period: value,
        endDate: endDate,
        startDate: startDate,
      },
    })
      .then((res) => {
        if (res?.data?.clientsCount) {
          switch (status) {
            case "active":
              setActiveClients(res.data.clientsCount);
              break;
            default:
              break;
          }
        }
      })
      .catch((res) => {
        notyf.error("Une erreur s'est produite!");
      });
  };
  const getPaidInvoices = () => {
    Axios.get("/invoice//paidInvoices", {
      params: {
        period: value,
        endDate: endDate,
        startDate: startDate,
      },
    })
      .then((res) => {
        if (res?.data?.count) {
          setPaidInvoices(res.data.count);
        }
      })
      .catch((res) => {
        notyf.error("Une erreur s'est produite!");
      });
  };
  const getNotPaidInvoices = () => {
    Axios.get("/invoice//notPaidInvoices", {
      params: {
        period: value,
        endDate: endDate,
        startDate: startDate,
      },
    })
      .then((res) => {
        if (res?.data?.count) {
          setNotPaidInvoices(res.data.count);
        }
      })
      .catch((res) => {
        notyf.error("Une erreur s'est produite!");
      });
  };

  const getLitigationInvoices = () => {
    Axios.get("/invoice//litigationInvoices", {
      params: {
        period: value,
        endDate: endDate,
        startDate: startDate,
      },
    })
      .then((res) => {
        if (res?.data?.count) {
          setLitigationInvoices(res.data.count);
        }
      })
      .catch((res) => {
        notyf.error("Une erreur s'est produite!");
      });
  };
  const getEventEntity = () => {
    Axios.get("/entityevent/")
      .then((res) => {
        setEventData(res.data.events);
      })
      .catch((res) => {
        notyf.error("Une erreur s'est produite!");
      });
  };

  const insertFakeInvoice = () => {
    Axios.post("/invoice//fakeInvoice")
      .then((res) => {
        if (res?.data?.success) notyf.success("Facture créée!");
      })
      .catch((res) => {
        notyf.error("Une erreur s'est produite!");
      });
  };

  const resetDB = () => {
    Axios.get("/settings//resetDB")
      .then((res) => {
        if(res?.data?.result===0) {
          notyf.success("BDD réinitialisé!");
          setReload(true);
        } else {
          notyf.error("Une erreur s'est produite!");
        }
      }
      )
      .catch((res)=>{
        notyf.error("Une erreur s'est produite!");
      })
  }

  const lunchOcr = () => {
    Axios.post("/invoice//lunchOcr")
      .then((res) => {
        if (res?.data?.success) notyf.success("succés!");
      })
      .catch((res) => {
        notyf.error("Une erreur s'est produite!");
      });
  };

  useEffect(() => {
    getPaidInvoices();
    getEventEntity();
    getSuppliersCount("active");
    getClientsCount("active");
    // getSuppliersCount("inProgress");
    getNotPaidInvoices();
    getLitigationInvoices();
    setReload(false);
  }, [value, reload]);

  return (
    <>
      <HomeContainer>
        <HomeHeader>
          <HeaderInfos>
            <HeaderLocation>{t("dashboard:Accueil")}</HeaderLocation>
            <HeaderTitle>   {t("dashboard:portail")}</HeaderTitle>
          </HeaderInfos>
          <HeaderActions>
            <HeaderAction>
              <Select
                options={options}
                value={options.find((option) => option.value === value)?.label}
                onChange={selectChange}
              />
            </HeaderAction>
            <HeaderAction>
              <BtnAction
                onClick={resetDB}
              >
                Reset DB
              </BtnAction>
            </HeaderAction>
          </HeaderActions>
        </HomeHeader>
        <HomeBody>
          <HomeCards>
            {/* <Card
              icon={<ShoppingCartIcon style={{ color: "#0090FF" }} />}
              title="Nombre de fournisseurs actifs"
              value={
                <SupplierLink
                  to="/referentielFournisseur"
                  state={{
                    filtersProps: {
                      status: "ACTIVE",
                      type: "supplier",
                    },
                  }}
                >
                  {activeSuppliers}
                </SupplierLink>
              }
            /> */}
            <Card
              icon={<CreditCardIcon style={{ color: "#3A36DB" }} />}
              title={t("dashboard:NombreClientsActifs")}
              value={
                <SupplierLink
                  to="/referentielClient"
                  state={{
                    filtersProps: {
                      status: "ACTIVE",
                      type: "client",
                    },
                  }}
                >
                  {activeClients}
                </SupplierLink>
              }
            />
            <Card
              icon={<ReportProblemIcon style={{ color: "#F9B959" }} />}
              title={t("dashboard:FacturesNonPayes")}
              value={notPaidInvoices}
            />
             <Card
              icon={<CreditCardOutlinedIcon style={{ color: "#3A36DB" }} />}
              title={t("dashboard:FacturesPayes")}
              value={paidInvoices}
            />
            <Card
              icon={<ReportProblemIcon style={{ color: "#F9B959" }} />}
              title={t("dashboard:Anomalies")}
              value={litigationInvoices}
            />
          </HomeCards>
          <HomeStats>
            <LineChart period={value} />
            <Events events={eventData} />
          </HomeStats>

          {/* <HomeInvoices>
            <LastInvoices entityFilter={"supplier"} />
          </HomeInvoices> */}
          <HomeInvoices style={{ marginTop: "10px" }}>
            <LastInvoicesClient entityFilter={"client"} />
          </HomeInvoices>
        </HomeBody>
      </HomeContainer>
    </>
  );
}

export default Home;
