import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { BtnAction, BtnOutlineAction } from "../../../styles/Common";
import {
  CIActions,
  CIContainer,
  CIContent,
  CIData,
  CIDeleteBtn,
  CITitle,
  CIUsersLink,
} from "../../Extraction/ExtractionItem/ExtractionItem.styled";

function ConfirmationModal({
  show,
  modalClosed,
  title,
  message,
  confirm,
  cancel,
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Modal show={show} onHide={modalClosed} contentClassName="modal_w_mc">
        <Modal.Header closeButton>
          <Modal.Title> {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="ADMCreationModal__body"
          style={{ width: "650px" }}
        >
          <p>{message}</p>
        </Modal.Body>
        <Modal.Footer>
          <BtnOutlineAction
            color={theme.colors.danger}
            hover={theme.colors.redLight}
            onClick={() => cancel()}
          >
            {t("default:cancel", "Annuler")}
          </BtnOutlineAction>
          <BtnAction
            onClick={() => confirm()}
          >
            {t("default:confirm", "Confirmer")}
          </BtnAction>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmationModal;
