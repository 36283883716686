import React, {useState, useRef, useEffect} from 'react'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import { TEContainer } from './EmailEditor.styled';
import { convertFileToBase64 } from '../../../shared/utility';
import { useTranslation } from 'react-i18next';
function EmailEditor({addTextHandler, content, editorStyle}) {
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const { t } = useTranslation();
    const language = localStorage.getItem('i18nextLng');
    console.log(t)
    function convertContentToDraft(html) {
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            return editorState;
        } else {
            return EditorState.createEmpty();
        }
    }
   

    useEffect(() => {
        setEditorState(convertContentToDraft(content))
    }, [content])	

    const editorBlurHandler = (event, editorState) => {
        if(editorState.getCurrentContent().hasText())
            addTextHandler(draftToHtml(convertToRaw(editorState.getCurrentContent())))
        else 
            addTextHandler('')
    }

    async function uploadCallback(file) {
        const imgB64 = await convertFileToBase64(file)
        const imageObject = {
            file: file,
            localSrc: URL.createObjectURL(file),
          }
        return new Promise(
          (resolve, reject) => {
            resolve({ data: { link: imgB64 } });
            // resolve({ data: { link: imageObject.localSrc } });
          }
        );
    }

    const onEditorStateChanged = (newEditorState) => {
        console.log("editor state")
        console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())))
        setEditorState(newEditorState)
    }
    const onChangeEditor = (draftContentState) => {
        console.log("draft content state");
        console.log(draftContentState);
    }

	return (
            <TEContainer>
                <Editor
                    editorState={editorState}
                    toolbar={{
                        image: {
                            uploadCallback: uploadCallback ,
                            previewImage: true,
                            // alt: { present: true, mandatory: true }
                        }
                    }}
                    toolbarClassName="textEditor_toolbar"
                    wrapperClassName="textEditor_wrapper"
                    editorClassName="textEditor_editor"
                    editorStyle={editorStyle ? editorStyle : {}}
                    // onEditorStateChange={(newEditorState) => setEditorState(newEditorState)}
                    onEditorStateChange={(newEditorState) => onEditorStateChanged(newEditorState)}
                    onChange={(draftContentState) => onChangeEditor(draftContentState)}
                    localization={{
                        locale: language ,
                    }}
                    onBlur={(event, editorState) => editorBlurHandler(event, editorState)}
                />
            </TEContainer>
        );
}

export default EmailEditor
