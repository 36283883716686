import React, { useEffect, useMemo, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {  EntInfosContainer} from './Infos.styled'
import { EIFormCol, EIFormContainer, EntFormItem, EntFormList, EntFormSection } from '../../../containers/Entity/Entity.styled';
import EntitiesDropDown from '../EntitiesDropDown/EntitiesDropDown';
import { FormECILabel, FormECItem, FormECIValue, FormECLabel, FormECListContainer, FormEntCanal, FormGroup, FormInput, FormLabel, FormSelect } from '../../../styles/Common';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

function Infos({entity, handleInputChange, entitiesType, entities, addEntity, deleteEntity, canalList, canalChangeHandler, supplierCanalChangeHandler, supplierCanalList, loggedUserType}) {
    const theme = useTheme();
    const { t } = useTranslation();

    const showEntitiesDropDown = () => {
        if(entity.hasOwnProperty("type") && entity.type !== "owner")
            return true;
        return false;
    };

    const showInoiceEmail = () => {
        let showCases = ['EMAIL', 'PORTAL'];
        if(showCases.includes(entity.channel))
            return true;
        return false;
    };

  return (
      <>
        <EntInfosContainer>
            {/* {
                showEntitiesDropDown() &&
                    <EntFormSection>
                        <EntitiesDropDown 
                            entitiesType={entitiesType}
                            userEntities={entities}
                            addEntitiesUser={addEntity}
                        />
                        <EntFormList>
                            {
                                entities?.length  > 0 ? entities.map(entity => (
                                    <EntFormItem key={entity.uid} >
                                        <span>{entity.name}</span>
                                        <CloseIcon style={{fontSize: "0.7rem", cursor: "pointer"}} onClick={() => deleteEntity(entity)} />
                                    </EntFormItem>
                                )) : null
                            }
                        </EntFormList>
                    </EntFormSection>
            } */}
            <EIFormContainer>
                <EIFormCol>
                    <FormGroup>
                        
                        <FormLabel htmlFor="name" >{t('client:name')}</FormLabel>
                        <FormInput 
                            id="name"
                            type="text"
                            name="name"
                            placeholder={t('client:name')}
                            value={entity?.name || ""}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="code" >{t('client:code')}</FormLabel>
                        <FormInput 
                            id="code"
                            type="text"
                            name="code"
                            placeholder={t('client:code')}
                            value={entity?.code || ""}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="siret" >{t('client:siret')}</FormLabel>
                        <FormInput 
                            id="siret"
                            type="text"
                            name="siret"
                            placeholder={t('client:siret')}
                            value={entity?.siret || ""}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="siren" >{t('client:siren')}</FormLabel>
                        <FormInput 
                            id="siren"
                            type="text"
                            name="siren"
                            placeholder={t('client:siren')}
                            value={entity?.siren || ""}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="vat" >{t('client:tvaIntra')}</FormLabel>
                        <FormInput 
                            id="vat"
                            type="text"
                            name="vat"
                            placeholder={t('client:tvaIntra')}
                            value={entity?.vat || ""}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="telephone" >{t('client:telephone')}</FormLabel>
                        <FormInput 
                            id="telephone"
                            type="text"
                            name="telephone"
                            placeholder={t('client:telephone')}
                            value={entity?.telephone || ""}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="address" >{t('client:address')}</FormLabel>
                        <FormInput 
                            id="address"
                            type="text"
                            name="address"
                            placeholder={t('client:address')}
                            value={entity?.address || ""}
                            onChange={handleInputChange}
                        />
                    </FormGroup>    
                    {
                        entity?.type === "client" &&
                            <>
                                <FormEntCanal>
                                    <FormECLabel >{t("client:channel", "Canal d'échange")}</FormECLabel>
                                    <FormECListContainer>
                                        {
                                            canalList.map((canal, index) => (
                                                <FormECItem isActive={entity?.channel === canal.value} >
                                                    <FormECILabel>{canal?.label} </FormECILabel>
                                                    <FormECIValue active={entity?.channel === canal.value}>
                                                        {
                                                            loggedUserType === 'owner' ? (
                                                                entity?.channel === canal.value ? (
                                                                    <ToggleOnIcon 
                                                                        style={{marginLeft: '0.25rem', cursor: 'pointer'}} 
                                                                        fontSize="small"
                                                                        name="edi"
                                                                        onClick={() => canalChangeHandler(null)}
                                                                    />
                                                                
                                                                ) : (
                                                                    <ToggleOffIcon 
                                                                        style={{marginLeft: '0.25rem', cursor: 'pointer'}} 
                                                                        fontSize="small"
                                                                        name="edi"
                                                                        onClick={() => canalChangeHandler(canal?.value)}
                                                                    />
                                                                )
                                                            ) : (
                                                                entity?.channel === canal.value ? (
                                                                    <ToggleOnIcon 
                                                                        style={{marginLeft: '0.25rem'}} 
                                                                        fontSize="small"
                                                                        name="edi"
                                                                    />
                                                            
                                                                ) : (
                                                                        <ToggleOffIcon 
                                                                            style={{marginLeft: '0.25rem'}} 
                                                                            fontSize="small"
                                                                            name="edi"
                                                                        />
                                                                )

                                                            )
                                                            
                                                        }
                                                    </FormECIValue>
                                                </FormECItem>
                                            ))
                                        }
                                    </FormECListContainer>
                                </FormEntCanal>
                                {
                                    showInoiceEmail() &&
                                        <FormGroup>
                                            <FormLabel htmlFor="invoiceMail" >{t("client:invoiceEmail")}</FormLabel>
                                            <FormInput 
                                                id="invoiceMail"
                                                type="email"
                                                name="invoiceMail"
                                                placeholder={t("client:invoiceEmail")}
                                                value={entity?.invoiceMail || ""}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                }
                            </>
                    }
                    {
                        entity?.type === "supplier" &&
                            <>
                                <FormEntCanal>
                                    <FormECLabel >{t("client:channel", "Canal d'échange")}</FormECLabel>
                                    <FormECListContainer>
                                        {
                                            supplierCanalList.map((canal, index) => (
                                                <FormECItem isActive={entity?.channel === canal.value}>
                                                    <FormECILabel>{canal?.label} </FormECILabel>

                                                    <FormECIValue 

                                                        >
                                                        {
                                                            entity?.channel === canal.value ? (
                                                                    <ToggleOnIcon 
                                                                        onClick={() => canalChangeHandler(null)}
                                                                        
                                                                    />
                                                            
                                                            ) : 
                                                                    <ToggleOffIcon 
                                                                        onClick={() => canalChangeHandler(canal?.value)}
                                                                    />
                                                            
                                                        }
                                                    </FormECIValue>
                                                </FormECItem>
                                            ))
                                        }
                                    </FormECListContainer>
                                </FormEntCanal>
                            </>
                    }
                </EIFormCol>

                <EIFormCol>
                    <FormGroup>
                        <FormLabel htmlFor="addressComplement" >{t("client:address2")}</FormLabel>
                        <FormInput 
                            id="addressComplement"
                            type="text"
                            name="addressComplement"
                            placeholder={t("client:address2")}
                            value={entity?.addressComplement || ""}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="zipCode" >{t("client:cp")}</FormLabel>
                        <FormInput 
                            id="zipCode"
                            type="text"
                            name="zipCode"
                            placeholder={t("client:cp")}
                            value={entity?.zipCode || ""}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="city" >{t("client:city")}</FormLabel>
                        <FormInput 
                            id="city"
                            type="text"
                            name="city"
                            placeholder={t("client:city")}
                            value={entity?.city || ""}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="countryCode" >{t("client:country")}</FormLabel>
                        <FormSelect 
                            id="countryCode"
                            name="citcountryCodey"
                            value={entity?.countryCode || ""}
                            onChange={handleInputChange}
                        >
                            <option value="fr">FRANCE</option>
                        </FormSelect>
                    </FormGroup>
                    {
                        entity?.type !== "owner" && (
                            <>
                                <FormGroup>
                                    <FormLabel htmlFor="family" >{t("client:family")}</FormLabel>
                                    <FormSelect 
                                        id="family"
                                        name="family"
                                        value={entity?.family || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="ADMINISTRATION">Administration</option>
                                        <option value="INDUSTRIE">Industrie</option>
                                        <option value="SERVICE">Service</option>
                                    </FormSelect>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="lang" >{t("client:language")}</FormLabel>
                                    <FormSelect 
                                        id="lang"
                                        name="lang"
                                        value={entity?.lang || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="fr">Français</option>
                                        <option value="en">English</option>
                                    </FormSelect>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="entityAdmin" >{t("client:admin")}</FormLabel>
                                    {
                                        entity?.id ? (
                                            <FormSelect 
                                                id="adminContact"
                                                name="adminContact"
                                                value={entity?.adminContact || ""}
                                                onChange={handleInputChange}
                                            >
                                                {
                                                    entity?.admins && entity?.admins.map(admin => (
                                                        <option key={admin.id} value={admin.uid}>{admin.login}</option>
                                                    ))
                                                }
                                            </FormSelect>
                                        ) : (
                                            <FormInput 
                                                id="entityAdmin"
                                                type="text"
                                                name="entityAdmin"
                                                placeholder={t("client:admin")}
                                                value={entity?.entityAdmin || ""}
                                                onChange={handleInputChange}
                                            />
                                        )
                                    }
                                </FormGroup>
                            </>
                        )
                    }

                </EIFormCol>
            </EIFormContainer>
        </EntInfosContainer>
      </>
  );
}

export default Infos;
