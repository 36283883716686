import styled from 'styled-components';

export const TEContainer = styled.div`
    width: 100%;
    height: 100%;

    & .textEditor_wrapper {
        width: 100%;
        height: 100%;
    }

    & .textEditor_editor {
        width: 100%;
        height: 80%;
    }
`