import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

export const LogoContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    align-self: center;
    /* justify-content: center; */
    width: 100%;
    height: 100%;
    margin: 0.5rem;
`;

export const SendButton = styled.button`
    background-color: #ffffff;
    color: ${({theme}) => theme.colors.sendBtn};
    padding: 1rem;
    border: 2px solid ${({theme}) => theme.colors.sendBtn};
    cursor: pointer;
    border-radius: 16px;
    margin-left: 2rem;
    width: 152px;
`;

export const Header = styled.div`
    width: 100%;
    /* height: 100%; */
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 1rem;
    border-bottom: 3px solid #F5FBFF;
`

export const HeaderInfos = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
`

export const HeaderLocation = styled.h3`
    font-size: ${({theme}) => theme.fontSize.location};
    font-weight: bold;
    color: ${({theme}) => theme.colors.location};
    margin-bottom: 0rem;
`

export const HeaderTitle = styled.h4`
    display: flex;
    justify-content: flex-start;
    font-size: ${({theme}) => theme.fontSize.title};
    margin: 0;
    color: ${({theme}) => theme.colors.title};
`

export const HeaderActions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex: 1;

    @media ${({theme}) => theme.device.laptop} {
        /* flex-direction: column; */
    }
`

export const HeaderAction = styled.div`
    /* width: 100%; */
    height: 50px;
    margin-left: 1rem;
`;

export const SearchInputContainer = styled.div`
    display: flex;
    align-items: center;
    /* height: 50px; */
    padding: 10px 30px;
    font-size: 14px;
    border: 2px solid ${({color, theme}) => color ? color : theme.colors.primary};
    border-radius: 30px;
    color: ${({color, theme}) => color ? color : theme.colors.primary};
    margin-right: 1rem;

    &:focus-within {
        border: 2px solid ${({theme}) => theme.buttons.primaryHover};
        color: ${({ theme}) => theme.buttons.primaryHover};
    }
`

export const SearchInput = styled.input`
    border: none;
    outline: none;
    color: ${({theme}) => theme.colors.text};
    font-size: 14px;
    padding: 0;

    &:focus{
        outline: none;
    }

    &::placeholder {
        color: ${({theme}) => theme.colors.text};
    }
`
export const TextLink = styled(Link)`
    display: flex;
    justify-content: center ;
    align-items: center;
    color: ${({color, theme}) => color || theme.colors.textLink};
    cursor: pointer;
    font-size: 0.9rem;

    &:hover {
        color: ${({theme}) => theme.colors.textLinkHover};
    }
`;

export const BtnAction = styled.button`  
    display: flex;
    justify-content: center ;
    align-items: center;
    /* height: 50px; */
    margin-left: ${({noMargin}) => noMargin ? '0' : '1rem'};
    padding: 10px 30px;
    border: 2px solid ${({bg, theme}) => bg ? bg : theme.colors.primary};
    border-radius: 30px;
    color: ${({color, theme}) => color || "#FFFFFF"};
    cursor: pointer;
    font-size: 14px;
    background-color: ${({bg, theme}) => bg ? bg : theme.colors.primary};

    & span {
        color: ${({color}) => color || '#FFFFFF'};
    }

    &:hover {
        background-color: ${({theme}) => theme.buttons.primaryHover};
    }

    &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
`;

export const BtnIcon = styled.div`
    display: flex;
    justify-content: center ;
    align-items: center;
    margin-left: 0.5rem;
`

export const BtnOutlineAction = styled.button`
    display: flex;
    height: fit-content;
    justify-content: center ;
    align-items: center;
    margin-left: ${({noMargin}) => noMargin ? '0' : '1rem'};
    padding: 10px 30px;
    border: 2px solid ${({color, theme}) => color ? color : theme.colors.primary};
    border-radius: 30px;
    color: ${({color, theme}) => color ? color : theme.colors.primary};
    cursor: pointer;
    font-size: 14px;
    background-color: #FFFFFF;

    &:hover {
        background-color: ${({theme, hover}) => hover ? hover : theme.buttons.outlinePrimaryHover};
    }

    &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
`;

export const BtnSmOutlineAction = styled.button`
    display: flex;
    justify-content: center ;
    align-items: center;
    margin-left: 1rem;
    padding: 1px 0.5rem;
    border: 2px solid ${({color, theme}) => color ? color : theme.colors.primary};
    border-radius: 20px;
    color: ${({color, theme}) => color ? color : theme.colors.primary};
    cursor: pointer;
    font-size: 0.9rem;
    background-color: #FFFFFF;

    & span {
        color: ${({color, theme}) => color || theme.colors.primary};
    }

    &:hover {
        background-color: ${({hover, theme}) => hover || theme.buttons.outlinePrimaryHover};
    }
    &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
`;

export const BtnOutlineLink = styled(Link)`
    display: flex;
    justify-content: center ;
    align-items: center;
    /* height: 50px; */
    margin-left: 1rem;
    padding: 10px 30px;
    border: 2px solid ${({color, theme}) => color ? color : theme.colors.primary};
    border-radius: 30px;
    color: ${({color, theme}) => color ? color : theme.colors.primary};
    cursor: pointer;
    font-size: 14px;
    background-color: #FFFFFF;
    text-decoration: none;

    & span {
        color: ${({color, theme}) => color ? color : theme.colors.primary};
    }

    & svg {
        color: ${({color, theme}) => color ? color : theme.colors.primary};
    }

    &:hover {
        background-color: ${({theme, hover}) => hover ? hover : theme.buttons.outlinePrimaryHover};
    }
`;

export const BtnSmOutlineLink = styled(Link)`
    display: flex;
    justify-content: center ;
    align-items: center;
    /* height: 50px; */
    margin-left: 1rem;
    padding: 0 0.5rem;
    border: 2px solid ${({color, theme}) => color ? color : theme.colors.primary};
    border-radius: 20px;
    color: ${({color, theme}) => color ? color : theme.colors.primary};
    cursor: pointer;
    font-size: 0.9rem;
    background-color: #FFFFFF;
    text-decoration: none;

    & span {
        color: ${({color, theme}) => color ? color : theme.colors.primary};
    }

    & svg {
        color: ${({color, theme}) => color ? color : theme.colors.primary};
    }

    &:hover {
        background-color: ${({theme, hover}) => hover ? hover : theme.buttons.outlinePrimaryHover};
    }
`;

export const FormGroup = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 0.25rem;
    border-bottom: 2px solid #F5FBFF;

    &:not(:last-child) {
        margin-bottom: 1rem;
    }

    &.fadeOut {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 300ms, opacity 300ms;
    }

    &.fadeIn {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 0s, opacity 300ms;
    }

`

export const FormLabel = styled.label`
    cursor: pointer;
    display: flex;
    margin: 0 0 2rem 0;
    font-size: 1.3rem;
    padding: 0;
    white-space: nowrap;
    color: ${({theme}) => theme.colors.secondary};
    border-bottom: ${({border, theme}) => border?" 1.7px solid #56566A" : "0px solid #2196f3"};
    font-size: ${({border, theme}) => border? "1.3rem;" : "1rem;"};
    margin: ${({border, theme}) => border? "0 0 2rem 0" : "0 0 0.07rem 0"};
`;

export const FormInput = styled.input`
    /* flex: 1; */
    border: none;
    outline: none;
    flex: 1;
    /* width: 170px; */
    text-align: right;
    margin-left: 7px;
`;

export const FormTextarea = styled.textarea`
    border: 1px solid #2196F3;
    border-radius: 2px;
    outline: none;
    flex: 1;
    /* width: 170px; */
    text-align: right;
`;

export const FormRadioWrapper = styled.div`
    display: flex;
    align-items: baseline;
    position: relative;
    border-radius: 2px;
`

export const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: white;
  border: 1px solid #ccc;
`;

export const FormRadio = styled.input`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-right: 10px;
  &:hover ~ ${RadioButtonLabel} {
    background: #1E85D9;
    border: 1px solid #2196F3;
    &::after {
        content: '';
       display: flex;
       width: 10px;
      height: 10px;
      margin: 3px;
       -moz-border-radius: 7.5px;
       -webkit-border-radius: 7.5px;
       border-radius: 7.5px;
       background-color: #fff;
    }
  }
  &:checked + ${RadioButtonLabel} {
    background: #2196F3;
    border: 1px solid #2196F3;
    &::after {
        content: '';
       display: flex;
       width: 10px;
      height: 10px;
      margin: 3px;
       -moz-border-radius: 7.5px;
       -webkit-border-radius: 7.5px;
       border-radius: 7.5px;
       background-color: #fff;
    }
  }
`;

export const FormSelect = styled.select`
    border: none;
    outline: none;
    /* min-width: 170px; */
    flex: 1;
    text-align: right;
`;

export const FormInterval = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 0.25rem;

    &:not(:last-child) {
        margin-bottom: 1rem;
    }
`

export const FormIntervalLabel = styled.label`
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;
    border-bottom: 2px solid #F5FBFF;
    color: ${({theme}) => theme.colors.secondary};
    margin-bottom: 1rem;
`;

export const FormIntervalValues = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`

export const FormIntervalStart = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 0.5rem;
`

export const FormIntervalEnd = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 0.5rem;
`

export const FormEntCanal = styled.div`
    width: 100%;
    border-bottom: 1px solid #F5FBFF;
    padding-bottom: 1rem;
`

export const FormECLabel = styled.label`
    color: ${({theme}) => theme.colors.secondary};
    padding-left: 0;
    margin: 0.5rem 0;
    white-space: nowrap;
`
export const FormECListContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    border: 1px solid #809FB866;
    border-radius: 18px;
    padding: 0.5rem;
    margin: 0.5rem 0;
`

export const FormECItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    /* width: 15%; */
    color: ${({theme, isActive}) => isActive ? theme.colors.primary : theme.colors.text};
`

export const FormECILabel = styled.label`
    margin: 0;
    white-space: nowrap;
`

export const FormECIValue = styled.div`
    margin: 0;
    color: ${({active, theme}) => active ? theme.colors.primary : theme.colors.text};
`

