import styled from 'styled-components/macro';

export const SDListWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0;
`;

export const SDItems = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0;
    list-style: none;
    max-height: 300px;
    overflow-y: auto;
`;

export const SDItem = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    list-style: none;
    margin-bottom: 0.5rem;
`;

export const SDItemTitle = styled.div`
    color: ${({theme}) => theme.colors.secondary};
    max-width: 102%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ESForm = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 0.9rem;
    margin-bottom: 2rem;
`

export const ESCol = styled.div`
    width: 99%;
    display: flex;
    flex-direction: column;
    padding-right: 20%;
    margin-bottom: 0.5rem;

    @media ${({theme}) => theme.device.laptop} {
        padding-right: 5%;
    }
`

export const TypeLabel = styled.div`
    width: 100%;
    display: flex;
 
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    BACKGROUND-COLOR: #bad7ef;
`