import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import TitleHelmet from "../../components/UI/TitleHelmet/TitleHelmet";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import {
  BtnIcon,
  BtnOutlineAction,
  BtnOutlineLink,
  HeaderLocation,
  SearchInput,
} from "../../styles/Common";
import {
  CampBody,
  CampContainer,
  CampHeader,
  CampHeaderActions,
  CampHeaderTitle,
  CampHeaderWrapper,
  CampListWrapper,
} from "./Campaigns.styled";
import { NavTabsLi, NavTabsLink, NavTabsUl } from "../Entity/Entity.styled";
import CampaignItem from "../../components/Campaigns/CampaignItem/CampaignItem";
import { Spinner } from "react-bootstrap";
import { NoContent, SearchInputContainer, SpinnerContainer } from "../../styles/Table.styled";
import TablePagination from "../../components/UI/TablePagination/TablePagination";
import DensiteAndColonnes from "../../components/UI/TablePagination/DensiteAndColonnes";

import SearchIcon from "@mui/icons-material/Search";

function Campaigns(props) {
  const {
    loading,
    campaignsData,
    count,
    currentPage,
    pageSize,
    setCampaignsPageSize,
    getCampaignsPage,
    setCampaignsPage,
    sortQuery,
    reverse,
    filtersQuery,
    showFilters,
    userRole,
    updateShowFilters,
    setCampaignsFilters,
    setSortQuery,
    setReverse,
  } = props;
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("all");

  const tabsList = useMemo(  
    () => [
      { title: t("campaignList:all"), value: "all" },
      { title: t("campaignList:scheduled"), value: "scheduled" },
      { title: t("campaignList:inProgress"), value: "inProgress" },
      { title: t("campaignList:completed", "Terminés"), value: "completed" },
    ],
    [t]
  );

  useEffect(() => {
    getCampaigns();
    //getCampaignsPage(currentPage, filtersQuery, sortQuery, reverse, pageSize)
  }, [currentPage, pageSize]);

  const getCampaigns = () => {
    getCampaignsPage(currentPage, filtersQuery, sortQuery, reverse, pageSize);
  };
  const handlePageChange = useCallback(
    (newPage) => {
      setCampaignsPage(newPage);
    },
    [currentPage]
  );

  const tabChangeHandler = (newValue) => {
    setActiveTab(newValue);
    let newFilters = {
      ...filtersQuery,
      status: newValue !== "all" ? newValue : null,
    };
    setCampaignsFilters(newFilters);
    handleApplyFiltering(newFilters);
  };

  const inputChangeHandler = (e) => {
    let key = e.target.name;
    let newFilters = {
      ...filtersQuery,
      [key]: e.target.value,
    };
    setCampaignsFilters(newFilters);
    handleApplyFiltering(newFilters);
  };
  const handleApplyFiltering = useCallback(
    (filters) => {
      getCampaignsPage(1, filters, sortQuery, reverse, pageSize);
    },
    [currentPage, filtersQuery, reverse, sortQuery, pageSize]
  );

  let tBody = null;
  let tSpinner = loading ? (
    <SpinnerContainer>
      <Spinner />
    </SpinnerContainer>
  ) : (
    <NoContent>{t("campaignList:noCampaign")}</NoContent>
  );

  if (!loading && campaignsData?.length > 0) {
    tSpinner = null;
    tBody = (
      <CampListWrapper>
        {campaignsData?.length > 0
          ? campaignsData.map((campaign, index) => (
              <CampaignItem campaign={campaign} getCampaigns={getCampaigns} />
            ))
          : null}
      </CampListWrapper>
    );
  }
  return (
    <>
      <CampContainer>
        <TitleHelmet title={"Streamline for Invoices | " + t("menu:campaigns")} />
        <CampHeaderWrapper>
          <CampHeader>
            <HeaderLocation>{t("menu:campaigns")}</HeaderLocation>
            <CampHeaderActions>
              <SearchInputContainer>
                <SearchInput
                  type="text"
                  placeholder={t("tableAction:Rechercher")}
                  autoComplete={"off"}
                  name="title"
                  value={filtersQuery?.title || ""}
                  onChange={(e) => inputChangeHandler(e)}
                />
                {/* <SearchIcon /> */}
              </SearchInputContainer>

              <BtnOutlineLink  to="/newCampaign">
                <span>{t("tableAction:NouvelleCampagne")}</span>
                {/* <BtnIcon>
                  <AddIcon fontSize="small" />
                </BtnIcon> */}
              </BtnOutlineLink>

              {/* <BtnOutlineAction
                                color="black"
                            >
                                <span>Filtrer</span> 
                                <BtnIcon>
                                    <FilterListIcon fontSize="small" />
                                </BtnIcon>
                            </BtnOutlineAction> */}
            </CampHeaderActions>
          </CampHeader>

          <NavTabsUl>
            {tabsList.map((tab, index) => {
              return (
                <NavTabsLi key={index}>
                  <NavTabsLink
                    onClick={() => tabChangeHandler(tab.value)}
                    active={tab.value === activeTab}
                    to={`#${tab.value}`}
                  >
                    {tab.title}
                  </NavTabsLink>
                </NavTabsLi>
              );
            })}
          </NavTabsUl>
        </CampHeaderWrapper>

        <CampBody>
        {campaignsData?.length > 0 &&
          <DensiteAndColonnes
            currentPage={currentPage}
            pageChange={handlePageChange}
            totalElement={count}
            perPage={pageSize}
            perPageChange={setCampaignsPageSize}
          />
        }
          {tBody}
          {tSpinner}
          {campaignsData?.length > 0 &&
            <TablePagination
              currentPage={currentPage}
              pageChange={handlePageChange}
              totalElement={count}
              perPage={pageSize}
              perPageChange={setCampaignsPageSize}
            />
         }
        </CampBody>
      </CampContainer>
    </>
  );
}

const mapStateToProps = (state) => ({
  loading: state.campaigns.loading,
  campaignsData: state.campaigns.data,
  count: state.campaigns.count,
  currentPage: state.campaigns.currentPage,
  pageSize: state.campaigns.pageSize,
  sortQuery: state.campaigns.sortQuery,
  reverse: state.campaigns.reverse,
  filtersQuery: state.campaigns.filtersQuery,
  showFilters: state.campaigns.showFilters,
  userRole: state.auth.role,
});
const mapDispatchToProps = (dispatch) => ({
  getCampaignsPage: (page, filters, sort, reverse, pageSize) =>
    dispatch(actions.campaignsGetPage(page, filters, sort, reverse, pageSize)),
  setCampaignsPage: (page) => dispatch(actions.campaignsSetPage(page)),
  setCampaignsPageSize: (pageSize) =>
    dispatch(actions.campaignsSetPageSize(pageSize)),
  setSortQuery: (sortQ) => dispatch(actions.campaignsSetSortQuery(sortQ)),
  setReverse: (reverseS) => dispatch(actions.campaignsSetReverseSort(reverseS)),
  setCampaignsFilters: (filters) =>
    dispatch(actions.campaignsSetFilterQuery(filters)),
  updateShowFilters: (show) =>
    dispatch(actions.campaignsUpdateShowFilters(show)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Campaigns);
