import React, { useEffect, useState } from "react";
import { Notyf } from "notyf";
import Axios from "../../axios-proas";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { formatDate, formatNumber, getNotyfObject } from "../../shared/utility";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import { useTheme } from "styled-components";
import { BtnOutlineAction } from "../../styles/Common";
import {
  IDAmoutDateContainer,
  IDBody,
  IDContainer,
  IDHeader,
  IDHeaderActions,
  IDHeaderContainer,
  IDHeaderTitle,
  IDHeaderWrapper,
  IDInvoiceDate,
  IDInvoiceDateLabel,
  IDInvoiceDateValue,
  IDProgressLineWrapper,
} from "./InvoiceDetail.styled";
import IDProgressLine from "../../components/InvoiceDetail/IDProgressLine/IDProgressLine";
import InvoiceView from "../../components/InvoiceDetail/InvoiceBody/InvoiceMain/InvoiceView/InvoiceView";
import InvoiceSide from "../../components/InvoiceDetail/InvoiceBody/InvoiceSide/InvoiceSide";
import { IDAmount } from "../../components/InvoiceDetail/IDProgressLine/IDProgressLine.styled";

function InvoiceDetail(props) {
  const {
    loadingData,
    getInvoiceDataByToken,
    invoiceData,
    invoiceError,
    getInvoiceData,
    resetAdmToInitialState,
  } = props;
  const theme = useTheme();
  let { uid, token } = useParams();
  const notyf = getNotyfObject();
  const { t } = useTranslation();
  let location = useLocation();
  let navigate = useNavigate();

  const [navTabsList, setNavTabsList] = useState([]);

  const typeInvoiceToNavListe = (xml, edi, pdf, chorus) => {
    var list = [];
    if (pdf !== null) list = [...list, { label: "PDF", value: "pdf" }];
    if (xml !== null) list = [...list, { label: "XML", value: "xml" }];
    if (edi !== null) list = [...list, { label: "EDI", value: "edi" }];
    if (chorus !== null) list = [...list, { label: "CHORUS", value: "chorus" }];

    setNavTabsList(list);
  };

  useEffect(() => {
    typeInvoiceToNavListe(
      invoiceData?.xmlFile,
      invoiceData?.ediFile,
      invoiceData?.docFile,
      invoiceData?.chorusFile
    );
  }, [
    invoiceData?.xmlFile,
    invoiceData?.ediFile,
    invoiceData?.docFile,
    invoiceData?.chorusFile,
  ]);

  useEffect(() => {
    if (token) {
      getInvoiceDataByToken(token);
    } else {
      getInvoiceData(uid);
    }
    console.log(invoiceData);
    return () => {
      resetAdmToInitialState();
    };
  }, [uid, token]);

  const editStatus = (status, comment = null, reason = null) => {
    Axios.put("/invoice//updateStatus", {
      InvoiceId: uid,
      status: status,
      reason,
      comment,
    })
      .then((response) => {
        getInvoiceData(uid);
        notyf.success("Statut modifié");
      })
      .catch((err) => {
        notyf.error("Une erreur s'est produite");
      });
  };

  let mandatJsx = null;

  // if(error) {
  //     notyf.error(t("mandat:generatedErrorMessage"));
  // }

  if (loadingData) mandatJsx = <Spinner />;
  else if (invoiceData)
    mandatJsx = (
      <>
        <IDHeaderWrapper>
          <IDHeaderContainer>
            <IDHeader>
              <IDHeaderTitle>
                {t("invoiceList:invoice")} : {invoiceData?.number} ({invoiceData?.supplierName} -{" "}
                {invoiceData?.clientName})
              </IDHeaderTitle>
              {/* {invoiceData?.status !== "ERROR" && (
                <IDAmount>
                  {formatNumber(invoiceData?.inclusiveTaxAmount)} TTC
                </IDAmount>
              )} */}
            </IDHeader>
            <IDProgressLineWrapper>
              <IDProgressLine
                status={invoiceData?.status}
                editStatus={editStatus}
                entityFilter={location?.state?.entityFilter}
                accessToken={token}
              />
              {invoiceData?.status !== "ERROR" && (
                <IDAmoutDateContainer>
                  <IDAmount>
                    {formatNumber(invoiceData?.inclusiveTaxAmount)} TTC
                  </IDAmount>
                  <IDInvoiceDate>
                    <IDInvoiceDateLabel>
                    {t("invoiceList:creationDate")}:{" "}
                    </IDInvoiceDateLabel>
                    <IDInvoiceDateValue>
                      {formatDate(+invoiceData?.issuingDate)}
                    </IDInvoiceDateValue>
                  </IDInvoiceDate>
                </IDAmoutDateContainer>
              )}
            </IDProgressLineWrapper>
          </IDHeaderContainer>
          {!token && (
            <IDHeaderActions>
              <BtnOutlineAction
                onClick={() => navigate(-1)}
              >
                {t("btn:Retour")}
              </BtnOutlineAction>
            </IDHeaderActions>
          )}
        </IDHeaderWrapper>

        <IDBody accessToken={token}>
          <InvoiceView invoiceData={invoiceData} navTabsList={navTabsList} />
          {!token ? <InvoiceSide /> : null}
        </IDBody>
      </>
    );

  return (
    <>
      <IDContainer>{mandatJsx}</IDContainer>
    </>
  );
}
const mapStateToProps = (state) => ({
  loadingData: state.invoiceDetail.loadingData,
  invoiceData: state.invoiceDetail.invoiceData,
  invoiceError: state.invoiceDetail.invoiceError,
  // role: state.auth.role,
  // isCreator : state.auth?.userId === state.mandat?.admData?.creatorUid,
  // creatorName : state.mandat?.admData?.creatorName
});
const mapDispatchToProps = (dispatch) => ({
  getInvoiceData: (invoiceUid) => dispatch(actions.getInvoiceData(invoiceUid)),
  getInvoiceDataByToken: (invoiceToken) =>
    dispatch(actions.getInvoiceDataByToken(invoiceToken)),
  // setTabSideActive: (tabSideActive) => dispatch(actions.setTabSideActive(tabSideActive)),
  // updateMandatStatus: (mandatUid, status) => dispatch(actions.updateMandatStatus(mandatUid, status)),
  resetAdmToInitialState: () => dispatch(actions.resetAdmToInitialState()),
});
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetail);
