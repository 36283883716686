import React, { useState } from 'react'
import { connect } from 'react-redux';
import './Auth.css';
import * as actions from '../../store/actions';
import Signin from '../../components/Signin/Signin';
import SigninAcm from '../../components/Signin/SigninAcme';
import Spinner from '../../components/UI/Spinner/Spinner';
import { Navigate, Route, useLocation, Routes, useNavigate } from 'react-router-dom';

const Auth = props => {
    const {error} = props;
    const [authForm, setAuthForm] = useState({
        email: '',
        password: '' 
    })
    let location = useLocation();

console.log(window.location.href)
    const inputChangeHandler = (e) => {
        setAuthForm({...authForm,[e.target.name]: e.target.value})
    }

    const submitHandler = e => {
        e.preventDefault();
        props.onAuth(authForm.email, authForm.password)
    }

  const getLoginPage=()=>{
  
   switch (window.location.href) {
      
    case process.env.REACT_APP_URL_CLIENT:
        return (
            <>
                <SigninAcm
                    changed={(e) => inputChangeHandler(e)}
                    submited={(e) => submitHandler(e)}
                    signinError={error}
                     />
            </>
        );
    case process.env.REACT_APP_URL_OWNER:
        return (
            <>
                 <Signin
                    changed={(e) => inputChangeHandler(e)}
                    submited={(e) => submitHandler(e)}
                    signinError={error}
                     />
            </>
        );

    default:
        return (
            <>
                <SigninAcm
                    changed={(e) => inputChangeHandler(e)}
                    submited={(e) => submitHandler(e)}
                    signinError={error}
                     />
            </>
        );
}

     

  }

    return (
        <>
            {props.loading ? 
                <Spinner /> : 
                getLoginPage()
                }
        </>
    )
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated : state.auth.token !== null,
        authRedirectPath: state.auth.authRedirectPath
    }
} 

const mapDispatchToProps = dispatch => {
    return {
        onAuth : (email, password) => dispatch(actions.auth(email, password)),
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
