import styled from 'styled-components/macro';
import bgImage from '../../assets/images/Acme_reduced.jpg'
export const Container = styled.div`
   
    background-size: cover;
    height: 100vh;
    font-size: 0.9rem;
     background-image: url(${({img}) => img ? img :"../../assets/images/login_background.jpg"}); 
     background-position: center;

    @media (max-width: 768px) {
        background-image: url(${bgImage}); 
    }
`
export const ContainerImg= styled.div`
width: 25%;

/* background-image: url(${({img}) => img ? img :"../../assets/images/login_background.jpg"}); */

`