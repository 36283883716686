import React,{useState} from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoIcon from '@mui/icons-material/Info';
import { DangerNotyf, DocsTabContainer, DocsTable, Notyfs, SuccessNotyf, WarningNotyf } from './Documents.styled';
import { Table, TBody, TD, TH, THead } from '../../../styles/Table.styled';
import Download from '../../Download/Download';
import { formatDate ,getNotyfObject} from '../../../shared/utility';
import CloseIcon from '@mui/icons-material/Close';
import {EIFormGroup,EIFormLabel,Input,EIFormGroupChecked,ToggleDiv,InputCheckbox,EntitiesContainer}from '../../../containers/NewDocumentation/NewDocumentation.styled'
import ConfirmationModal from '../../UI/ConfirmationModal/ConfirmationModal';
import Axios from "../../../axios-proas";
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
function Documents({documents,Admin, docTypes,type, setDownloadDate,setUpdate}) {
    console.log(documents)
    console.log(type)
    const notyf = getNotyfObject();
    const docType = {KBIS :"KBIS", IBAN:"RIB", URSSAF: "Attestation URSSAF", INSURANCE: "Attestation d'assurance", FOREIGN: "Travailleurs étrangers"}
    const [document, setDocument] = useState()
    const [selectedOption, setSelectedOption] = useState()
    const [confirmationModalShow, setConfirmationModalShow] = useState(false);
    const inputChangeHandler2 = (e,uid) => {   
        let value =  e.target.value;
        setSelectedOption(e.target.value)
        console.log(e.target.value)
        console.log(uid)
        switch (value) {
            case "valider": 
                let docTemp1 = {
                   admin:{...Admin[0]},
                    uid:uid,
                    valider: 1,
                    refuser : 0,  
                }
                setDocument(docTemp1)
            break;
            case "refuser":
                let docTemp = {
                    admin:{...Admin[0]},
                    uid:uid,
                    valider: 0,
                    refuser : 1    
                }
                setDocument(docTemp)
            break;
           
            default:
                break;
        }
        setConfirmationModalShow(true)
    }
    const updateStatusDocument=(data)=>{
        console.log(data)
      
        Axios.put("supplierdocument//updateStatusDocumentFile", data,).then(response => {
            notyf.success("document modifié");
            setUpdate(true)
            setConfirmationModalShow(false)
            localStorage.setItem("show", "document");
        }).catch(err => {
            notyf.error("err lors de la modification de statut de fichier");
        })
    }
    console.log(docTypes)
  return (
    <DocsTabContainer>
        <Notyfs>
            {docTypes?.map(e=>(
                e.attent ?
                <WarningNotyf title="Ce document n'est pas encore validé ">
                    <HourglassBottomIcon style={{fontSize: "1rem"}} />
                    <span>{e.label}</span>
               </WarningNotyf>
            : e.alert ?
                <WarningNotyf title="Ce document expirera bientôt ">
                    <InfoIcon style={{fontSize: "1rem"}} />
                    <span>{e.label}</span>
                </WarningNotyf>
            :!e.valide?
                <DangerNotyf title="Document expiré ou non disponible">
                    <CloseIcon style={{fontSize: "1rem"}} />
                    <span>{e.label}</span>
                </DangerNotyf>
            : null


            ))
        }
         

    
        
          </Notyfs>

          <DocsTable>
            <Table>
                <THead>
                    <tr>
                        <TH scope="col" >Document</TH>
                        <TH scope="col" >Type</TH>
                        <TH scope="col" >Date d'expiration</TH>
                        <TH scope="col" >Date de téléchargement</TH>
                        <TH scope="col" >{type=="supplier"?"statut":"Action"}</TH> 
                    </tr>
                </THead>
                <TBody>
                    {documents?.length > 0 ? documents.map(d => <tr key={d.uid}>
                        <TD><Download fileName={d.document} url={"/supplierdocument/" + d.uid + "/download"} clicked={() => setDownloadDate(d)}/></TD>
                        <TD>{docType[d.type]}</TD>
                        <TD>{d.expirationDate ? formatDate(Number(d.expirationDate)) : ""}</TD>
                        <TD>{d.downloadDate ? formatDate(Number(d.downloadDate)) : ""}</TD>
                        <TD>
                        {type=="owner" ?
                         d.invalidate==null && d.valid==null&& 
                        <EIFormGroup >                          
                                <EIFormGroupChecked key={d.uid}>
                                    <InputCheckbox  type="radio" style={{marginRight:'10px'}}
                                        id="tous" 
                                        value="refuser" 
                                        name={d.uid}
                                        // disabled={type=="supplier"}
                                        onChange={(e)=>type!=="supplier" ? inputChangeHandler2(e,d.uid):null}
                                        checked={d.invalidate=="1"}/>
                                    <EIFormLabel for="tous" class="form-check-label" >Refuser</EIFormLabel>
                                </EIFormGroupChecked>            
                                <EIFormGroupChecked >
                                        <InputCheckbox  type="radio"  style={{marginRight:'10px'}}
                                            id="client"
                                            name={d.uid}
                                            value="valider"
                                            // disabled={type=="supplier"}
                                           onChange={(e)=> type!=="supplier"&& inputChangeHandler2(e,d.uid)}
                                            checked={ d.valid=="1"}/>
                                        <EIFormLabel class="form-check-label" for="client">Valider</EIFormLabel>
                                </EIFormGroupChecked>    
                        </EIFormGroup >:
                         <EIFormGroup >                          
                         <EIFormGroupChecked key={d.uid}>
                             <InputCheckbox  type="radio" style={{marginRight:'10px'}}
                                 id="tous" 
                                 value="refuser" 
                                 name={d.uid}
                                 // disabled={type=="supplier"}
                                 onChange={(e)=>type!=="supplier" ? inputChangeHandler2(e,d.uid):null}
                                 checked={d.invalidate=="1"}/>
                             <EIFormLabel for="tous" class="form-check-label" >Refuser</EIFormLabel>
                         </EIFormGroupChecked>            
                         <EIFormGroupChecked >
                                 <InputCheckbox  type="radio"  style={{marginRight:'10px'}}
                                     id="client"
                                     name={d.uid}
                                     value="valider"
                                     // disabled={type=="supplier"}
                                    onChange={(e)=> type!=="supplier"&& inputChangeHandler2(e,d.uid)}
                                     checked={ d.valid=="1"}/>
                                 <EIFormLabel class="form-check-label" for="client">Valider</EIFormLabel>
                         </EIFormGroupChecked>    
                 </EIFormGroup >
                            }
                        </TD>
                    </tr>) : null
                    }
                </TBody>
            </Table>
          </DocsTable>
          <ConfirmationModal 
                show={confirmationModalShow}
                modalClosed={() => setConfirmationModalShow(false)}
                confirm={() => updateStatusDocument(document)}
                cancel={() => setConfirmationModalShow(false)}
                title={document?.valider==1?"Confirmation de validation":"Confirmation de désactivation"}
                message={document?.valider==1?"Êtes-vous sûr de vouloir valider ce document":"Êtes-vous sûr de vouloir refuser ce document "}
            />
        </DocsTabContainer>
  );
}

export default Documents;
{/* <TD className="userManag_td">
{
    Number(row.active) === 1 ? (
        <CTooltip title={t('tableBody:disable',"Désactiver" )}  className="tableActions__icon">
            <ToggleOnIcon 
                style={{marginLeft: '0.25rem', color: theme.colors.success, cursor: 'pointer'}} 
                fontSize="small"
                onClick={() => disableUserClickHandler(row.userUid, row.login)}
            />
        </CTooltip>
    ) : 
        <CTooltip title={t('tableBody:enable',"Activer" )}  className="tableActions__icon">
            <ToggleOffIcon 
                style={{marginLeft: '0.25rem', cursor: 'pointer'}} 
                fontSize="small"
                onClick={() => enableUser(row.userUid)}
            />
        </CTooltip>
}
<DeleteIcon 
    style={{marginLeft: '0.25rem', color: theme.colors.danger, cursor: 'pointer'}} 
    fontSize="small" 
    onClick={() => disableUserClickHandler(row?.userUid, row?.login)}
/>
</TD> */}