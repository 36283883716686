import React, { useState, useEffect, useCallback, useRef } from 'react'
import ScrollToBottom from 'react-scroll-to-bottom';
import Axios from '../../../../../axios-proas'
import * as actions from '../../../../../store/actions/index';
import { connect } from 'react-redux';
import { getNotyfObject, isToday, isYesterday } from "../../../../../shared/utility";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { MCContainer, MCMessagesContainer, Message, MessageAvatar, MessageContent, MessageDate, MessagesContainer, MessageValue, MsgInput, MsgInputContainer, MsgSendBtn, ScrollToUpBtn } from './ModificationsChat.styled';
import SendIcon from '@mui/icons-material/Send';
import { Spinner } from 'react-bootstrap';
import { NoContent, SpinnerContainer } from '../../../../../styles/Table.styled';

function ModificationsChat(props) {
    const {
        userUid
    } = props
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);
    const [messagesLoading, setMessagesLoading] = useState(false)
    const {t} = useTranslation();
    let {uid} = useParams();
    const notyf = getNotyfObject();
    const messagesRef = useRef();

    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0){
            const newDate = new Date(date);
            const day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate();
            const month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1);
            const hours = newDate.getHours() < 10 ? '0' + newDate.getHours() : newDate.getHours();
            const minutes = newDate.getMinutes() < 10 ? '0' + newDate.getMinutes() : newDate.getMinutes();
            const seconds = newDate.getSeconds() < 10 ? '0' + newDate.getSeconds() : newDate.getSeconds();
            const time = " " + t("default:at", "à") + " " + hours + ":" + minutes +":" + seconds;
        return (isToday(newDate) ? t("default:today","Aujourd'hui") : (isYesterday(newDate) ? t("default:yesterday","Hier") : day + '/' + month + '/' + newDate.getFullYear())) + time;
        } else return null;    
    }, [])

    const loadMessages = () => {
        setMessagesLoading(true)
        Axios.get("invoiceevent", {
            params: {
                invoice: uid,
                type: "USER_CHAT_EVENT"
            }
        }).then(response => {
            setMessagesLoading(false)
            setMessages(response?.data?.events.reverse())
            messagesRef?.current?.scrollTo({
                top : messagesRef.current.scrollHeight,
                left: 0
            })
        }).catch(response => {
            setMessagesLoading(false)
            notyf.error(t("mandat:generatedErrorMessage", "une erreur s'est produite"));
        })
    }
    useEffect(() => {
       
        if(uid) {
            loadMessages();
            // const interval = setInterval(() => {
            //     loadMessages();
            // }, 60000);
            // return () => clearInterval(interval);
           
        }
    }, [uid])



    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    }

    const generateAvatar = (data) => {
        if(data && data?.first_name && data?.last_name){
            return data?.first_name.charAt(0).toUpperCase() + data?.last_name.charAt(0).toUpperCase()
        }else if(data && data?.login) {
            return data?.login.charAt(0).toUpperCase()
        }else {
            return "?";
        }
    }

    const generateUserName = (data) => {
        if(data && data?.first_name && data?.last_name){
            return data?.first_name + " " + data?.last_name
        }else if(data && data?.login) {
            return data?.login
        }else
            return "Inconnu";
    }

    const postMessage = () => {
        if (message)
            Axios.post("invoiceevent", {
                invoiceUid: uid,
                message: message, 
                type: "USER_CHAT_EVENT"
            }).then(response =>{
                loadMessages();
                setMessage("");
            })
            //createMandatTimelineEvent(document.uid, {documentUid: document.uid, type: "USER_CHAT_EVENT", message: message});
    }

    const messagesScrollUp = () => {
    
        messagesRef.current.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
     
    }
   

    return (
        <MCContainer>
            <MCMessagesContainer ref={messagesRef}>
            {
                messagesLoading ?
                    <SpinnerContainer>
                        <Spinner />
                    </SpinnerContainer>
                :
                    messages.length === 0 ? (
                        <NoContent>
                            <p>{t('mandat:noMessages', "Aucun message à afficher")} </p>
                        </NoContent>
                    )
                    : (
                        <>
                            <ScrollToUpBtn>
                                <ArrowUpwardIcon onClick={messagesScrollUp}/>
                            </ScrollToUpBtn>
                            <MessagesContainer >
                                {
                                    messages?.map(m => (
                                        <Message 
                                            key={m.id} 
                                            isMine={userUid === m?.user?.userUid}
                                        >
                                            {
                                                userUid !== m?.user?.userUid && (
                                                    <MessageAvatar>
                                                        {generateAvatar(m?.user)}
                                                    </MessageAvatar>
                                                )
                                            }
                                            <MessageContent isMine={userUid === m?.user?.userUid}>
                                                <MessageValue>
                                                    {m?.message}
                                                </MessageValue>
                                                <MessageDate isMine={userUid === m?.user?.userUid} >
                                                    {generateUserName(m?.user) + " - " + formatDate(parseInt(m?.creationDate))}
                                                </MessageDate>
                                            </MessageContent>
                                        </Message>
                                    ))
                                }
                            </MessagesContainer>
                        </>
                    )
            }
            </MCMessagesContainer>
            <MsgInputContainer>
                <MsgInput
                    type="text" 
                    placeholder={t('mandat:messagePlaceHolder', "Tapez votre message")} 
                    value={message} 
                    onChange={handleMessageChange} 
                    maxLength="255"
                />
                <MsgSendBtn onClick={() => postMessage()}>
                    <SendIcon />
                </MsgSendBtn>
            </MsgInputContainer>
            
        </MCContainer>
    )
}
    
const mapStateToProps = (state) => ({
    // admStatus: state.mandat.admData?.status,
    userUid : state.auth.userUid
})
const mapDispatchToProps = dispatch => ({
    // getMandatTimelineEvents: (mandatUid, type) => dispatch(actions.getMandatTimelineEvents(mandatUid, type)),
    // createMandatTimelineEvent: (mandatUid, eventData) => dispatch(actions.createMandatTimelineEvent(mandatUid, eventData))
})
export default connect(mapStateToProps, mapDispatchToProps)(ModificationsChat)
