import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Viewer, pdfjs, Worker } from "@react-pdf-viewer/core";
import Axios from "../../axios-proas";
import {Notyf} from "notyf";
import { useNavigate, useLocation } from "react-router-dom";
import DescriptionIcon from "@mui/icons-material/Description";
import {
  NDContainer,
  DocVisu,
  NoContent,
  NDCol6,
  NDrow,
  ISContainer,
  EIFormGroup,
  EIFormGroupClient,
  EIFormLabel,
  Input,
  EIFormGroupChecked,
  ToggleDiv,
  InputCheckbox,
  EntitiesContainer,
  Item,
  RadioButtonLabel,
} from "./NewDocumentation.styled";
import {
  Header,
  HeaderActions,
  HeaderInfos,
  HeaderLocation,
  HeaderTitle,
  BtnOutlineAction,
  BtnAction,
} from "../../styles/Common";
import {
  TableAction,
  TableActionLink,
  TAContainer,
} from "../../styles/Table.styled.js";
import FileUploaderBtn from "../../components/UI/FileUploaderBtn/FileUploaderBtn";
import EntitiesComponent from "../../components/NewDocumentation/EntitiesComponent/EntitiesComponent";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";

import { useTheme } from "styled-components";
import { getNotyfObject } from "../../shared/utility";
function NewDocumentation(){
    const theme = useTheme();

    const {t} = useTranslation();
  const [visible, setVisible] = useState(false);
  const [user, setUser] = useState();
  const [documentation, setDocumentation] = useState();
  const [file, setFile] = useState("");
  const [url, setUrl] = useState("");
  const [ifClient, setIfClient] = useState(false);
  const [ifSupplier, setIfSupplier] = useState(false);
  const [formData, setFormData] = useState();
  const notyf = getNotyfObject();
    
    const uploader = useRef(null);
    let location = useLocation();
    const navigate = useNavigate();
    const entRef = useRef();
  const [selectedOption, setSelectedOption] = useState();
    
    useEffect(() => {
        entRef?.current?.scrollTo({
            bottom : 0,
      left: 0,
    });
  }, []);

    const updateVisible=()=>{
        if(visible){
      setVisible(false);
            let docTempp = {
                ...documentation,
        visible: 0,
      };
      setDocumentation(docTempp);
    } else {
      setVisible(true);
                let docTemp = {
                    ...documentation,
        visible: 1,
      };
      setDocumentation(docTemp);
                }
  };
    
    const handleSelectedClient = (e) => {
    setIfClient(true);
            setIfSupplier(false);
  };
      
    const handleSelectedSupplier = (e) => {
    setIfSupplier(true);
    setIfClient(false);
  };
    const entitiesRadioChangeHandler = (name, value) => {
        let docTemp = {
            ...documentation,
      [name]: +value,
    };
    setDocumentation(docTemp);
  };
    const deleteSupplierClickHandler = (supplier) => {
    let docTemp = { ...documentation };
        if(documentation?.id && !supplier?.new ){
            docTemp = {
                ...docTemp,
        suppliersToDelete: docTemp?.suppliersToDelete
          ? [...docTemp?.suppliersToDelete, supplier.uid]
          : [supplier.uid],
      };
            }
    let docSuppliers = [...documentation?.suppliers];
    let supplierIndex = documentation?.suppliers.findIndex(
      (ent) => ent.uid === supplier.uid
    );
        if(supplierIndex >=0 ){
      docSuppliers.splice(supplierIndex, 1);
        }
        setDocumentation({
            ...docTemp,
      suppliers: docSuppliers,
    });
  };
    
    const handleFileChange = (e) => {
    console.log(e);
    console.log(e?.target.files[0]);
        if(e?.target?.files[0]?.type == "application/pdf"){
            if (e?.target.files && e?.target.files[0]){
        setUrl(URL.createObjectURL(e.target.files[0]));
        setFile(e.target.files[0].name);
            }
      console.log(e?.target.files[0]);
            let tmpDoc = {
                ...documentation,
                formData: e?.target.files[0],
                fileName: e?.target.files[0].name,
        fileType: e?.target.files[0].type,
      };
      setDocumentation(tmpDoc);
      console.log(tmpDoc);
        }else {
      setUrl("");
            notyf.error(t("documentDetails:docFormat"));
        }
  };

    const addDocumentationclickHandler = () => {
        const formData = new FormData();
    formData.append("file", documentation.formData);
    console.log(documentation.formData);
    Axios.post("documentation//saveDocumentationFile", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
            if (response?.data.success){
          console.log("docUid: " + response?.data?.docUid);
                let tmpDoc = {
                    ...documentation,
            docUid: response?.data?.docUid,
          };
          Axios.post("documentation//documentation", tmpDoc).then(
            (response) => {
                    if (response?.data.success == true){
                        notyf.success(t("documentDetails:documentationUpload"));
                    } else {
                        notyf.error(t("documentDetails:documentationUploadFailed"));
                    }
              setUrl("");
              setIfClient(false);
              setIfSupplier(false);
              setVisible(false);
              setSelectedOption(null);
              setDocumentation([]);
            }
          );
        }
                })
      .catch((err) => {
            notyf.error(t("documentDetails:documentationUploadFailed"));
      })
      .finally(() => {
            setFile(null);
            uploader.current.value = "";
      });
  };

    const suppliersChangeHandler = (suppliersList) => {
    console.log(suppliersList);
        let docTemp = {
            ...documentation,
            suppliers : suppliersList,
            clients : null,
    };
    setDocumentation(docTemp);
       
        setIfClient(false); 
  };

    const deleteClientClickHandler = (client) => {
    let docTemp = { ...documentation };
        if(documentation?.id && !client?.new ){
      console.log(docTemp);
            docTemp = {
                ...docTemp,
        clientsToDelete: docTemp?.clientsToDelete
          ? [...docTemp?.clientsToDelete, client.uid]
          : [client.uid],
      };
      console.log(docTemp);
            }
    let docClients = [...documentation?.clients];
    let clientIndex = documentation?.clients.findIndex(
      (ent) => ent.uid === client.uid
    );
        if(clientIndex >=0 ){
      docClients.splice(clientIndex, 1);
        }
        setDocumentation({
            ...docTemp,
      clients: docClients,
    });
  };
    const clientsChangeHandler = (clientsList) => {
        let docTemp = {
            ...documentation,
            clients : clientsList,
      suppliers: null,
    };
    setDocumentation(docTemp);
        setIfSupplier(false);
  };

    const inputChangeHandler = (e) => {
        const name = e.target.name;
        let value =  e.target.value;
        
    console.log(e.target);
        if(name == "visible") {
            if(e.target.checked){
                value=1 ;
                setIfSupplier(false);
                 setIfClient(false); 
      } else value = 0;
            } 
        let docTemp = {
            ...documentation,
      [name]: value,
    };
    setDocumentation(docTemp);
  };

    const inputChangeHandler2 = (e) => {
        let value =  e.target.value;
    setSelectedOption(e.target.value);
        switch (value) {
            case "visible":
                    setIfSupplier(false);
                     setIfClient(false); 
             
                let docTemp = {
                    ...documentation,
                    global: 1,
                    suppliers : null,
          clients: null,
        };
        setDocumentation(docTemp);

                break;
            case "supplier":
                handleSelectedSupplier();
                break;
            case "client":
                handleSelectedClient();
                break;
            case "edi":
                let docTempp = {
                    ...documentation,
          [name]: value,
        };
        setDocumentation(docTempp);
                break;
            default:
                break;
        }
  };

    return(
        <>
                <NDContainer>
                          <Header>
                                <HeaderTitle>{t("documentation:addDoc")}</HeaderTitle>
                                
                                <HeaderActions>
            <BtnOutlineAction
        
              onClick={() => navigate(-1)}
            >
              <span>{t("btn:Retour")}</span>
            </BtnOutlineAction>
              <BtnAction
                        disabled={
                          documentation?.docName &&
                          documentation?.docName != "" &&
                          documentation?.fileName &&
                          documentation?.fileName != ""
                          && documentation?.clients!= null 
                          && documentation?.clients?.length  > 0 
                            ? false
                            : true
                        }
                          onClick={() => addDocumentationclickHandler()}
                        >
                            <span>{t('btn:Add')}</span>
               </BtnAction>
              </HeaderActions>
                </Header>
                    <NDrow>  
                        <NDCol6>
                            {url ? (
                                <DocVisu>
                                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js" />
                                        <Viewer fileUrl={url} />
                                        </DocVisu>
                                ) : (
              <NoContent>{t("global:previewArea")}</NoContent>
                                )}
                        </NDCol6>

                            <NDCol6>
                            <ISContainer>
                                <EIFormGroup>
                                    <EIFormLabel for="first_name">{t('documentation:name')}</EIFormLabel>
                                    <Input 
                  placeHolder={t("documentDetails:name")}
                                        id="first_name"
                                        name="docName" 
                                        type="text"
                                        color="#06152B" 
                                        autoComplete="off"
                                        value={documentation?.docName || ""}
                                        onChange={(e) => inputChangeHandler(e)} 
                                    />
                                </EIFormGroup>
                                <EIFormGroup>
                <EIFormLabel
                  for="visible"
                  className="accountRequest__canalItemLabel"
                >
                  {t('documentation:visible')}
                </EIFormLabel>
                  <ToggleDiv
                         active={visible}
                     >
                        {visible ? (
                              <ToggleOnIcon 
                                name="edi"
                                id="visible"
                                onClick={() => updateVisible()}
                            />
                        ) : (
                                <ToggleOffIcon 
                                  name="edi"
                                  onClick={() => updateVisible()}
                                />
                        )}
                    </ToggleDiv>
                  </EIFormGroup>
                            
                            {/*
                            desactive le fournisseur on aura besoin que le cote client
                            <EIFormGroup >
                                <EIFormGroupChecked >
                                    <Item>
                                        <InputCheckbox  type="radio" style={{marginRight:'10px'}}
                                            id="tous" 
                                            value="visible" 
                                            name="web" 
                                            onChange={inputChangeHandler2}
                                            chacked={selectedOption === "visible"}

                                        />
                                        <RadioButtonLabel />
                                        <EIFormLabel for="tous" class="form-check-label" >Tous</EIFormLabel>
                                    </Item>
                                </EIFormGroupChecked>
                                
                                <EIFormGroupChecked >
                                  <Item>
                                        <InputCheckbox  type="radio"  style={{marginRight:'10px'}}
                                            id="client"
                                            name="web"
                                            value="client"
                                            onChange={inputChangeHandler2}
                                            chacked={selectedOption === "client"}
                                           
                                        />
                                        <RadioButtonLabel />
                                        <EIFormLabel class="form-check-label" for="client">
                                            Client
                                        </EIFormLabel>
                                   </Item>
                                </EIFormGroupChecked>
                    


                                
                                <EIFormGroupChecked >
                                  <Item>
                                    <InputCheckbox  type="radio"  style={{marginRight:'10px'}}
                                            id="fournisseur"
                                            name="web"
                                            value="supplier"
                                            checked={selectedOption === "supplier"}
                                            onChange={inputChangeHandler2}
                                            
                                        />
                                        <RadioButtonLabel />
                                        <EIFormLabel class="form-check-label" for="fournisseur">
                                            Fournisseurs
                                        </EIFormLabel>
                                   </Item>
                                </EIFormGroupChecked>

                                  
                            </EIFormGroup > */}


              {/* {ifClient ? (
                                    <EntitiesContainer ref={entRef} >
                                        <EntitiesComponent
                                            show={true} 
                                            label={"Clients"} 
                                            radioName={"accessClient"} 
                                            radioValue={documentation?.accessClient} 
                                            onChangeRadio={entitiesRadioChangeHandler} 
                                            entities={documentation?.clients} 
                                            deleteEntity={deleteClientClickHandler} 
                                            addEntity={clientsChangeHandler}
                                            entitiesType={"client"}
                                            userType={documentation?.type}
                                            userUid={documentation?.id}
                  />
                </EntitiesContainer>
              ) : null}

              {ifSupplier ? (
                                        <EntitiesContainer ref={entRef}>
                                        <EntitiesComponent
                                           show={true} 
                                           label={"Fournisseurs"} 
                                           radioName={"accessSupplier"} 
                                           radioValue={documentation?.accessSupplier} 
                                           radioGender={"M"} 
                                           onChangeRadio={entitiesRadioChangeHandler} 
                                           entities={documentation?.suppliers} 
                                           deleteEntity={deleteSupplierClickHandler} 
                                           addEntity={suppliersChangeHandler}
                                           entitiesType={"supplier"}
                                           userType={documentation?.type}
                                           userUid={documentation?.id}
                  />{" "}
                </EntitiesContainer>
              ) : null} */}
                                    {/* on affiche seulement les client et on supprime les btn tous et client et fournisseur */}
                                    <EIFormGroupClient >
                                    <EIFormLabel class="form-check-label" for="fournisseur">
                                            Clients
                                        </EIFormLabel>
                                      <EntitiesContainer ref={entRef} >
                                     
                                        <EntitiesComponent
                                            show={true} 
                                            label={"Clients"} 
                                            radioName={"accessClient"} 
                                            radioValue={documentation?.accessClient} 
                                            onChangeRadio={entitiesRadioChangeHandler} 
                                            entities={documentation?.clients} 
                                            deleteEntity={deleteClientClickHandler} 
                                            addEntity={clientsChangeHandler}
                                            entitiesType={"client"}
                                            userType={documentation?.type}
                                            userUid={documentation?.id}
                                             />
                                       </EntitiesContainer>
                                    </EIFormGroupClient >  


              {documentation?.fileName && (
                                        <EIFormGroup>
                  <EIFormLabel for="first_name">{t("Fichier")}</EIFormLabel>
                                                <Input 
                    placeHolder={t("documentDetails:name")}
                                                    name="docName" 
                                                    color="#06152B"
                                                    type="text" 
                                                    autoComplete="off"
                                                    value={documentation?.fileName || ""}
                                                />
                                        </EIFormGroup>
              )}
                                   <EIFormGroup>
                                        <FileUploaderBtn
                                            btnLabel={t('documentation:selectFile')}
                                            handleChange={handleFileChange}
                                            name="filename"
                                            ref={uploader}
                                        />
                                     </EIFormGroup>
                           </ISContainer>
                             </NDCol6> 
                    </NDrow>
                </NDContainer>
        </>
  );
}

export default NewDocumentation;
