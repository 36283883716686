import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

export const HeaderContainer = styled.div`
    
    display: flex;
    justify-content: flex-end;
    align-items: start;
    width: 100%;
    flex-direction: column;
    font-size: 14px;
    padding: 0.25rem 0;
    //ajouter par mùarzouk 
    /* border-top: 1px solid #E3E6E6; */
    padding-bottom:45px;
    /* padding-left: 1.2rem; */
`;

export const HeaderLinks = styled.div`
   
   width: 100%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;

`;
// color: ${({theme}) => theme.colors.secondary};
//     text-decoration: none;
//     padding: 0 30px;

//     & span {
//         margin-left: 1rem;
//         font-weight: 700;
//     }

//     &:hover {
//         background: #D2EAFD 0% 0% no-repeat padding-box;
//         color: #000000;
//         transition: all 0.5s ease-in-out;
//         /* border-right: 2px solid ${({isActive, theme}) => isActive ? theme.colors.primary : '#fff'}; */
   // }
   
   export const Helement = styled.div`
   
   width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

`;

export const Hlink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    color: ${({theme}) => theme.colors.secondary};
    padding: 0.6rem  ;
    margin-right: 0.5rem;
    margin:4px;
    
    & span {
        margin-left: 0.25rem;
        white-space: nowrap;
    }
    &:hover {
        background: #ECF1FE 0% 0% no-repeat padding-box;
        color: ${({theme}) =>  theme.colors.secondary}; 
        box-shadow: 0px 3px 6px #00000029;

   }
`;

export const HLang = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
`;

export const HTheme = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    color: ${({theme}) => theme.colors.darkText};
    /* color: ${({theme, isActive}) => isActive ? theme.colors.primary : theme.colors.secondary}; */
    cursor: pointer;

    & span {
        color: ${({lcolor, theme}) => lcolor || theme.colors.darkText} ;
        margin-left: 0.25rem;
    }
`;

