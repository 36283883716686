import styled from 'styled-components/macro';

export const CFContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const CFHeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    border-bottom: 4px solid #F5FBFF;
`;

export const CFHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

export const CFHeaderTitle = styled.h4`
    font-size: 1.5rem;
    font-weight: bold;
    color: #000000;
`

export const CFHeaderActions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex: 1;
`

export const CFBody = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
`

export const CFSection = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`

export const CFEditorWrapper = styled.div`
    width: 46%;
    display: flex;
    flex-direction: column;
    padding: 2rem 1.5rem;
    border: 1px solid #D9E1E7CC; 
    border-radius: 5px;
    height: 850px;
    max-height: 850px;
`

export const CFTitle = styled.h4`
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
    color: #000000;
    margin-bottom: 2rem;
`

export const CFFormWrapper = styled.div`
    width: 46%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 0.9rem;
    /* align-items: space-between; */
`

export const CFFormSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1.5rem 2rem;
    border: 1px solid #D9E1E7CC; 
    border-radius: 5px;

    &:not(:last-child) {
        margin-bottom: 1rem;
    }
`

export const DPContainer = styled.div`

    margin-bottom: 0.5rem;

    & input {
        padding: 0.5rem !important;
        color: ${({ theme }) => theme.colors.secondary};
    }

    & button {
        color: ${({ theme }) => theme.colors.secondary};
    }

    & fieldset {
        border-color: ${({ theme }) => theme.colors.secondary};
    }

    & label {
        color: ${({ theme }) => theme.colors.secondary};
    }

    &:focus-within {
        & input {
            color: ${({ theme }) => theme.colors.primary};
        }
        & button {
            color: ${({ theme }) => theme.colors.primary};
        }
    }
`

export const ToggleLabel = styled.label`
    margin: 0;
    padding: 0;
    color: ${({active, theme}) => active ? theme.colors.primary : theme.colors.secondary};
`;

export const ToggleIcon = styled.div`

    cursor: pointer;
    color: ${({active, theme}) => active ? theme.colors.primary : theme.colors.text};
`;

export const AttachementsContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
`

export const AddRecipientsBtn = styled.button`
    color: ${props => props.theme.colors.primary};
    border: 1px solid ${props => props.theme.colors.primary};
    padding: 0.125rem 1rem;
    border-radius: 8px;
    background-color: white;
    font-size: 0.8rem;
    display: flex;
    
    &:hover {
        background-color: ${props => props.theme.colors.primary};
        color: #ffffff;
    }
`;

export const CFFormGroup = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 0.25rem;
    margin-bottom: 2rem;
    border-bottom: 2px solid #F5FBFF;
`;