import React, { useCallback, useState } from 'react'
import { formatDate,showColumn } from '../../../shared/utility';
import { FilterContainer, InputFilter, Option, SelectFilter, TD } from '../../../styles/Table.styled';
import { useTranslation } from 'react-i18next';
import DateFilter from '../../UI/DateFilter/DateFilter';

function TableFilters(props) {
    const {t} = useTranslation();
    const {showFilters, suppliersFilters, filtersChange, applyFilters, entityFilter,columns} = props;
    const [show, setShow] = useState(null)
    const [dateField, setDateField] = useState('')
    const [dateInputValue,setDateInputValue] = useState({
        creationDate: suppliersFilters?.creationDate ? convertDate(suppliersFilters.creationDate) : ''
    });

    function convertDate (date){
        let dateArray = date.split(':');
        return [formatDate(+dateArray[0]) , formatDate(+dateArray[1])]
    }

    const closeModalHandler = () => {
        setShow(false);
    }

    const dateChangeHandler = (date) => {
        setDateInputValue({
            ...dateInputValue,
            [dateField]: formatDate(date.startDate) + '-' + formatDate(date.endDate)
        })
        let key = date.field;
        let newFilters = {
            ...suppliersFilters,
            [key]: date.startDate.getTime() + ':' + date.endDate.getTime()
        }
        filtersChange(newFilters)
        applyFilters(newFilters);
    }

    const dateResetHandler = (field) => {
        setDateInputValue({
            ...dateInputValue,
            [field]: ''
        })
        let newFilters = {
            ...suppliersFilters,
            [field]: null
        }
        filtersChange(newFilters)
        applyFilters(newFilters);
    }

    const dateShowHandler = (field) => {
        setShow({
            [field]: true
        })
        setDateField(field);
    }

    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...suppliersFilters,
            [key]: e.target.value
        }
        filtersChange(newFilters);
        applyFilters(newFilters)
    }
    console.log(columns)

    const getCreationdate = () => {
        return suppliersFilters?.creationDate ? convertDate(suppliersFilters.creationDate) : [];
    }

    return (
        <>
            <tr className="tableFilters" style={{display: showFilters ? 'table-row' : 'none'}}>
                
                <TD style={{display: !showColumn(columns,"code") && 'none'}}>
                    <FilterContainer>
                        <InputFilter 
                            placeholder={t("invoiceList:supplierCode", "Code")}
                            onChange={(e) => inputChangeHandler(e)} 
                            value={suppliersFilters?.code || ''} 
                            name="code" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"name") && 'none'}}>
                    <FilterContainer>
                        <InputFilter 
                            placeholder={t("invoiceList:name", "Nom")}
                            onChange={(e) => inputChangeHandler(e)} 
                            value={suppliersFilters?.name || ''} 
                            name="name" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"vat") && 'none'}}>
                    <FilterContainer>
                        <InputFilter 
                            placeholder={t("invoiceList:vat", "TVA")}
                            onChange={(e) => inputChangeHandler(e)} 
                            value={suppliersFilters?.vat || ''} 
                            name="vat" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"status") && 'none'}}>
                    <FilterContainer>
                        <SelectFilter 
                            onChange={(e) => inputChangeHandler(e)} 
                            value={suppliersFilters?.status || ''} 
                            name="status" 
                        >
                            <Option value=""></Option>
                            <Option value="INACTIVE">Inactif</Option>
                            <Option value="INPROGRESS">En cours</Option>
                            <Option value="ARCHIVED">Archivé</Option>
                            <Option value="ACTIVE">Actif</Option>
                        </SelectFilter>
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"channel") && 'none'}}>
                    <FilterContainer>
                        <SelectFilter 
                            onChange={(e) => inputChangeHandler(e)} 
                            value={suppliersFilters?.channel || ''} 
                            name="channel" 
                        >
                            <Option value=""></Option>
                            <Option value="PORTAL">Portail</Option>
                            <Option value="EMAIL">Email</Option>
                            <Option value="PAPER">Papier</Option>
                            <Option value="EDI">EDI</Option>
                            <Option value="PDP">PDP</Option>
                            <Option value="PPF">PPF</Option>
                        </SelectFilter>
                    </FilterContainer>
                </TD>
                <TD style={{display: !showColumn(columns,"creationDate") && 'none'}}>
                    <FilterContainer>
                        <DateFilter
                            modalTitle={t("invoiceList:creationDate", "Date de Création")}
                            from={getCreationdate()[0] || ''}
                            to={getCreationdate()[1] || ''}
                            name="creationDate"
                            activeReset={suppliersFilters?.creationDate ? true : false}
                            resetInterval={dateResetHandler}
                            show={show?.creationDate}
                            showModal={dateShowHandler}
                            closeModal={closeModalHandler}
                            dateChange={(date) =>dateChangeHandler(date)}
                        />
                    </FilterContainer>
                </TD>
                <td></td>
            </tr>
        </>
    )
}

export default TableFilters
