import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const CampContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const CampHeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    border-bottom: 4px solid #F5FBFF;
`;

export const CampHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

export const CampHeaderTitle = styled.h4`
    font-size: 1.5rem;
    font-weight: bold;
    color: #000000;
`

export const CampHeaderActions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex: 1;
`

export const CampBody = styled.div`
    width: 100%;
`;

export const CampListWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

