import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TablePagination from '../../components/UI/TablePagination/TablePagination';
import TableActions from '../../components/EreportingTable/TableActions/TableActions';
import TableHead from '../../components/EreportingTable/TableHead/TableHead';
import TableBody from '../../components/EreportingTable/TableBody/TableBody';
import TableFilters from '../../components/EreportingTable/TableFilters/TableFilters';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { Spinner } from 'react-bootstrap';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { useTranslation } from 'react-i18next';
import { Header, HeaderActions, HeaderInfos, HeaderLocation, HeaderTitle } from '../../styles/Common';
import { NoContent, SpinnerContainer, Table, TBody } from '../../styles/Table.styled';
import { RFContainer, RFBody, RFTableContainer } from './Ereporting.Styled';


import _ from 'lodash';


function Ereporting(props) {
    const {
        userType,
        entityFilter, 
        setEntitydetail, 
        loading, 
        ReportingData, 
        counts, 
        pageSize, 
        reportingSetPageSize,
        getReportingPage,
        amountBTTotal
    } = props;


    const initialFiltersState = {
         page:1
    }
    
    // const reverseTable = {
    //     'type' : false ,'issuingDate': false,'exclusiveTaxAmountTotal': false,
    //     'invoicingEndDate': false,'invoicingStartDate': false
    // };
    const [filters, setFilters] = useState(initialFiltersState);
    const [sortQuery, setSortQuery] = useState("creationDate");
    const [sortOrder, setSortOrder] = useState("DESC");
    const [adms, setAdms] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [count, setCount] = useState(0);
    const [reverse, setReverse] = useState([]);

    const {t} = useTranslation();
    const {state} = useLocation();
    // exclusiveTaxAmountTotal invoicingEndDate invoicingStartDate  type issuingDate'
    const[density,setDensity]=useState("2.25rem")
    const [columns,setColumns]=useState([   {title: t("Type"), field: 'type',show:true},
                                            {title: t("Date de creation"), field: 'issuingDate',show:true}, 
                                            {title: "Debut facturation", field: 'invoicingStartDate',show:true},    
                                            {title: "Fin facturation", field: 'invoicingEndDate',show:true},
                                          
                                            {title: t("Montant "), field: 'exclusiveTaxAmountTotal', show:true},
                                        ])

    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0){
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
        return day + '/' + month + '/' + newDate.getFullYear()
        }else return null;
    }, [])

    useEffect(() => {    
        getReportingPage(filters,pageSize)
      
    }, [filters,pageSize])



    const columnClickHandler = (field, reverse) => {
        setSortQuery(field);
        let reverseValue= reverse[field] ? reverse[field] : false 
        let newReverse = { [field]: !reverseValue }
        setReverse(newReverse); 

        setReverse(newReverse)
        let key = field;
        let newFilters = {
            ...filters,
            orderBy: field,
            reverse: reverse[field],
            
        }
        setFilters(newFilters)

    }

    const handlePageChange = useCallback((newPage) => {
        setCurrentPage(newPage);
        let newFilters = {
            ...filters,
            page: newPage,
           
        }
        setFilters(newFilters)
    }, [currentPage]);

    

    const resetFilters = () => {
        setFilters(initialFiltersState)
        setCurrentPage(1)
        getReportingPage(initialFiltersState,pageSize)
    }

    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...filters,
            [key]: e.target.value,
           
        }
        setFilters(newFilters)
        //getReportingPage(newFilters)
    }

    let tBody = null;
    let tSpinner = loading ? (
        <SpinnerContainer>
            <Spinner animation="border" variant="primary" />
        </SpinnerContainer>
        ) : (
            <NoContent>
                <span>{t("supplier:noSuppliers")}</span>
            </NoContent>
        ) 

    if(!loading && ReportingData?.length > 0){
        tSpinner = null;
        tBody = ( <TableBody 
                        columns={columns}
                        setEntitydetail={setEntitydetail} 
                        ReportingData={ReportingData} 
                        formatDate={formatDate}
                        entityFilter={entityFilter}
                        userType={userType}
                    />)
    }
    
    return (
        <RFContainer>
             
            <TitleHelmet title={"Streamline for Invoices | " + t("Reporting")}  />
            <Header>
          
                <HeaderInfos>
                    <HeaderLocation>{t("E-Reporting")}</HeaderLocation>
                    <HeaderTitle></HeaderTitle>
                </HeaderInfos>
                <HeaderActions>
                
                    
                    <TableActions 
                        resetFilters={resetFilters}
                        showFilters={showFilters}
                        setShowFilters={setShowFilters} 
                        entityFilter={entityFilter} 
                        userType={userType}
                    />
                    
                </HeaderActions>
            </Header>
            <RFBody>
                <RFTableContainer>
                    
                    <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns}
                        currentPage={currentPage} 
                        pageChange={handlePageChange} 
                        totalElement={counts} 
                        perPage={pageSize}
                        perPageChange={reportingSetPageSize}
                    />
                    
                    <Table >
                        <TableHead 
                            columns={columns}
                            reverse={reverse}
                            columnClickHandler={columnClickHandler} 
                            entityFilter={entityFilter}
                            userType={userType}
                        />
                        <TBody height= {density}>
                            <TableFilters 
                                columns={columns}
                                showFilters={showFilters}
                                reportingFilters={filters}
                                filtersChange={setFilters}
                                amountBTTotal={amountBTTotal}
                                //applyFilters={handleApplyFiltering}
                                entityFilter={entityFilter}
                            />
                            {tBody}
                        </TBody>
                    </Table>
                    {tSpinner} 
                    <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns}
                        currentPage={currentPage} 
                        pageChange={handlePageChange} 
                        totalElement={counts} 
                        perPage={pageSize}
                        perPageChange={reportingSetPageSize}
                    />
                </RFTableContainer>
            </RFBody>
        </RFContainer>
    )
}

const mapStateToProps = (state) => ({
    loading: state.reporting.loading,
    ReportingData: state.reporting.data,
    counts: state.reporting.count,
    pageSize: state.reporting.pageSize,
    userType: state.auth.userType,
    amountBTTotal: state.reporting.amountBTTotal,
    
})
const mapDispatchToProps = dispatch => ({
    getReportingPage: (filters,pageSize) => dispatch(actions.reportingGetPage(filters,pageSize)),
    
    reportingSetPageSize: (pageSize) => dispatch(actions.reportingSetPageSize(pageSize)),

})
export default connect(mapStateToProps, mapDispatchToProps) (Ereporting)
