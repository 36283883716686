import React,{useCallback, useEffect, useRef, useState} from 'react'
// import './PdfViewer.css'
// Import the main component
// install also npm install pdfjs-dist@2.6.347
import { Viewer } from '@react-pdf-viewer/core'; // install this library
// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'; // install this library
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
// Worker
import { Worker } from '@react-pdf-viewer/core'; // install this library

//import { LocalizationMap } from '@react-pdf-viewer/core'; // for changing language


import { ReactElement } from 'react';
//import { ToolbarSlot  } from '@react-pdf-viewer/default-layout';
//import { ToolbarSlot } from '@react-pdf-viewer/toolbar';

import { RenderDownloadProps, getFilePlugin } from '@react-pdf-viewer/get-file';

import GetAppIcon from '@mui/icons-material/GetApp';
import { useTranslation } from 'react-i18next';

import { useTheme } from 'styled-components';
import { PVContainer, PVToHideMobile, PVToolbar, PVToolbarElements, PVToolbarElementsActions,NavTabsLi } from './EdiViewer.styled';
    

  
export const ediViewer = ({ediFile, setAdmScrolledEnd, pdfFileName, setAdmPdfPages}) => {
    const theme = useTheme()
    const {t} = useTranslation();
    const [ediView, setEdiView] = useState([]);
    const getFilePluginInstance = getFilePlugin();
    const { Download } = getFilePluginInstance;

  
 

    useEffect(() => {
        if (ediFile){
         
        let words = ediFile.split("'");
           setEdiView(words)
        } else
            setEdiView(null);
        
       
    }, [])
 

    

  return (
    <div className='container'  style={{maxWidth: "100%"}}>

        <PVContainer  >
           
            { ediView?.map(item=>(
             <NavTabsLi>
                 {item}{"'"}
             </NavTabsLi>

            ))
              
            }

          
            {!ediFile&&<>{t("mandat:ediViewerMessage")}</>}
        </PVContainer>

    </div>
  )
}

export default ediViewer
