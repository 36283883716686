import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const DContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const DBody = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 1rem;
`

export const DTableContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const CustomerLink = styled(Link)`
    color: ${({theme}) => theme.colors.primary};
    text-decoration: none;
`
export const DocDawnload = styled.div`
    color: ${({theme}) => theme.colors.primary};
    text-decoration: none;
    cursor: pointer;
`
export const EntityComponent = styled.div`
    position:absolute;
    display:none;
    background-color: #fff;
    z-index: 1000;
     width: 100%;
     text-align:start;
     color: ${({color}) => color || "#809"};
     border: 2px solid #809FB8;
     font-size:0.8rem;
     margin-right: 15px
   
 `;
// export const SupplierUsersLink = styled(Link)`
//     background-color: ${({theme}) => theme.colors.primary};
//     color: #FFFFFF;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     font-size: 0.9rem;
//     font-weight: 500;
//     padding: 0.25rem 1rem;
//     text-decoration: none;
//     border-radius: 8px;
//     width: fit-content;
// `