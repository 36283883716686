import React, { useState } from "react";
import Axios from "../../../axios-proas";
import { Button, Modal } from "react-bootstrap";
import { useTheme } from "styled-components";
import {
  ESForm,
  ESCol,
  TypeLabel,
  EPContainer,
  EPSection,
  EPRadiosContainer,
} from "./EmailParametersModal.styled";
import { getNotyfObject } from "../../../shared/utility";
import { useTranslation } from "react-i18next";
import {
  BtnAction,
  FormGroup,
  FormInput,
  FormLabel,
  FormRadio,
  FormRadioWrapper,
  FormTextarea,
  RadioButtonLabel
} from "../../../styles/Common";
import FileUploaderBtn from "../../../components/UI/FileUploaderBtn/FileUploaderBtn";

function EmailParametersModal({ show, closeModal }) {
  const theme = useTheme();
  const notyf = getNotyfObject();
  const { t } = useTranslation();
  const [suspendedDocs, setSuspendedDocs] = useState();
  const [loading, setLoading] = useState(false);
  const [parametres, setParametres] = useState({ 
    headerImage: "",
    headerImagePosition: "left",
    headerImageWidth: "auto",
    headerImageHeight: "auto",
    footerText: "",
    footerImage: "",
    headerImageHeight: "auto",
    footerImageWidth: "auto",
    footerImagePosition: "left"
  });
  const [errors, setErrors] = useState([]);

  const sendInvoiceByMail = (uid, isLast) => {
    let docs = [...suspendedDocs];
    let index = docs.findIndex((doc) => doc.uid === uid);
    docs[index].isSending = 1;
    setSuspendedDocs(docs);
    Axios.put(`invoice/${uid}/sendInvoiceByMail`)
      .then((response) => {
        let success =
          response?.data.hasOwnProperty("success") && response?.data?.success;
        if (success) {
          docs[index].emailed = 1;
        } else {
          notyf.error(
            t("mandat:generatedErrorMessage", "Une erreur s'est produite")
          );
        }
        docs[index].isSending = 0;
        setSuspendedDocs(docs);
      })
      .catch((response) => {
        notyf.error(
          t("mandat:generatedErrorMessage", "Une erreur s'est produite")
        );
        docs[index].isSending = 0;
        setSuspendedDocs(docs);
      })
      .finally(() => {
        if (isLast) {
          setLoading(false);
        }
      });
  };

  const sendInvoicesClick = () => {
    setLoading(true);
    // let notSentDocs = suspendedDocs?.filter(doc => parseInt(doc.emailed) === 0);
    // notSentDocs.map((doc, index) => {
    //     let isLast = index === notSentDocs.length - 1;
    //     sendInvoiceByMail(doc.uid, isLast);
    // })
  };

  const save = () => {
    const formData = new FormData();
    forEach(parametres, (value, key) => {
        formData.append(key, value);
    });
    Axios.post("documentation//saveParametresEmail", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    }).then((response) => {
        if (response?.data.success) {
          console.log("docUid: ");
        }
      })
      .catch((err) => {
        notyf.error(t("documentDetails:documentationUploadFailed"));
      });
  };

  const handleFileChange = (e) => {
    setErrors([]);
    let file = e.target.files[0];
    // check if the file is an image
    var isImagen = /^image\//;
    // if it's not an image continu
    if (!isImagen.test(file.type)) {
      setErrors([...errors, "Le fichier n'est pas une image"]);
    } else {
        const name = e.target.name;
        setParametres({ ...parametres, [name]: file });
    }
  };

  const inputChangeHandler2 = (e) => {
    let value = e.target.value;
    let docTempp = {
      ...parametres,
      position: value,
    };
    setParametres(docTempp);
  };

  const parametersChangeHandler = (e) => {
    const { name, value } = e.target;

    setParametres((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Paramètres</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EPContainer>
            {errors && errors.length > 0 ? (
              <div
                className="alert alert-danger"
                style={{ fontSize: "0.9rem" }}
                role="alert"
              >
                <ul>
                  {errors.map((error) => (
                    <li key={error}>{error}</li>
                  ))}
                </ul>
              </div>
            ) : null}
          <EPSection>
            <FormGroup>
              <TypeLabel>Entête</TypeLabel>
            </FormGroup>
            {/* <FormGroup>
              <FormLabel htmlFor="titleheader">text</FormLabel>
              <FormInput
                style={{ textAlign: "left" }}
                type="text"
                name="titleheader"
                id="titleheader"
                value={parametres?.titleheader}
                onChange={(e) => emailChangeHandler(e)}
                autoComplete="off"
              />
            </FormGroup> */}
            <FormGroup>
              <FormLabel htmlFor="headerImage">Image :</FormLabel>
              <FileUploaderBtn
                btnLabel="Choisir un fichier"
                handleChange={handleFileChange}
                name="headerImage"
              />
            </FormGroup>
            <FormGroup>
                <FormLabel htmlFor="headerImagePosition">Position : </FormLabel>
                <EPRadiosContainer>
                    <FormRadioWrapper>
                        <FormRadio
                            type="radio"
                            style={{ marginRight: "10px" }}
                            id="left"
                            value="left"
                            name="headerImagePosition"
                            onChange={parametersChangeHandler}
                            checked={parametres?.headerImagePosition === "left"}
                        />
                        <RadioButtonLabel />
                        <FormLabel htmlFor="left">Gauche</FormLabel>
                    </FormRadioWrapper>
                    <FormRadioWrapper>
                        <FormRadio
                            type="radio"
                            style={{ marginRight: "10px" }}
                            id="center"
                            value="center"
                            name="headerImagePosition"
                            onChange={parametersChangeHandler}
                            checked={parametres?.headerImagePosition === "center"}
                        />
                        <RadioButtonLabel />
                        <FormLabel htmlFor="center">Centre</FormLabel>
                    </FormRadioWrapper>
                    <FormRadioWrapper>
                        <FormRadio
                            type="radio"
                            style={{ marginRight: "10px" }}
                            id="right"
                            value="right"
                            name="headerImagePosition"
                            onChange={parametersChangeHandler}
                            checked={parametres?.headerImagePosition === "right"}
                        />
                        <RadioButtonLabel />
                        <FormLabel htmlFor="right">Droite</FormLabel>
                    </FormRadioWrapper>
                </EPRadiosContainer>
            </FormGroup>
            <FormGroup>
                <FormLabel htmlFor="headerImageWidth">Largeur : </FormLabel>
                <FormInput
                    type="number"
                    name="headerImageWidth"
                    id="headerImageWidth"
                    value={parametres?.headerImageWidth}
                    onChange={parametersChangeHandler}
                    autoComplete="off"
                />
            </FormGroup>
            <FormGroup>
                <FormLabel htmlFor="headerImageHeight">Hauteur :</FormLabel>
                <FormInput
                    type="number"
                    name="headerImageHeight"
                    id="headerImageHeight"
                    value={parametres?.headerImageHeight}
                    onChange={parametersChangeHandler}
                    autoComplete="off"
                />
            </FormGroup>
          </EPSection>
          <EPSection>
            <FormGroup>
              <TypeLabel>Bas de page</TypeLabel>
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="footerText">Texte :</FormLabel>
              <FormTextarea
                style={{ textAlign: "left" }}
                type="text"
                name="footerText"
                id="footerText"
                value={parametres?.footerText}
                onChange={(e) => parametersChangeHandler(e)}
                autoComplete="off"
                cols="2"
                />
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="footerImage">Image :</FormLabel>
              <FileUploaderBtn
                btnLabel="Choisir un fichier"
                handleChange={handleFileChange}
                name="footerImage"
              />
            </FormGroup>
            <FormGroup>
                <FormLabel htmlFor="footerImageWidth">Largeur : </FormLabel>
                <FormInput
                    type="number"
                    name="footerImageWidth"
                    id="footerImageWidth"
                    value={parametres?.footerImageWidth}
                    onChange={parametersChangeHandler}
                    autoComplete="off"
                />
            </FormGroup>
            <FormGroup>
                <FormLabel htmlFor="footerImageHeight">Hauteur :</FormLabel>
                <FormInput
                    type="number"
                    name="footerImageHeight"
                    id="footerImageHeight"
                    value={parametres?.footerImageHeight}
                    onChange={parametersChangeHandler}
                    autoComplete="off"
                />
            </FormGroup>
            <FormGroup>
                <FormLabel htmlFor="footerImagePosition">Position :</FormLabel>
                <EPRadiosContainer>
                    <FormRadioWrapper>
                        <FormRadio
                            type="radio"
                            style={{ marginRight: "10px" }}
                            id="left"
                            value="left"
                            name="footerImagePosition"
                            onChange={parametersChangeHandler}
                            checked={parametres?.footerImagePosition === "left"}
                        />
                        <RadioButtonLabel />
                        <FormLabel htmlFor="left">Gauche</FormLabel>
                    </FormRadioWrapper>
                    <FormRadioWrapper>
                        <FormRadio
                            type="radio"
                            style={{ marginRight: "10px" }}
                            id="center"
                            value="center"
                            name="footerImagePosition"
                            onChange={parametersChangeHandler}
                            checked={parametres?.footerImagePosition === "center"}
                        />
                        <RadioButtonLabel />
                        <FormLabel htmlFor="center">Centre</FormLabel>
                    </FormRadioWrapper>
                    <FormRadioWrapper>
                        <FormRadio
                            type="radio"
                            style={{ marginRight: "10px" }}
                            id="right"
                            value="right"
                            name="footerImagePosition"
                            onChange={parametersChangeHandler}
                            checked={parametres?.footerImagePosition === "right"}
                        />
                        <RadioButtonLabel />
                        <FormLabel htmlFor="right">Droite</FormLabel>
                    </FormRadioWrapper>
                </EPRadiosContainer>
            </FormGroup>
          </EPSection>
        </EPContainer>
      </Modal.Body>
      <Modal.Footer>
        <BtnAction
          // bg={theme.colors.greenLight}
          // color={theme.colors.success}
          onClick={() => closeModal()}
        >
          Fermer
        </BtnAction>
        {
          <BtnAction
            // bg={theme.colors.greenLight}
            // color={theme.colors.success}
            onClick={() => save()}
          >
            Sauvegarder
          </BtnAction>
        }
      </Modal.Footer>
    </Modal>
  );
}

export default EmailParametersModal;
